import React from "react";
import Aux from "../hoc/_Aux";
import { withRouter } from "../components/withRouter";
import Menu from "./includes/Navbar";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeadd from "../components/Placeadd";
import Placeaddsm from "../components/Placeaddsm";
import TimerBanner from "../components/timer-banner";
import { GetData, PostData } from "../utils/apiRequestHandler";
import BracketBox from "../components/brackets/BracketBox";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import LeaderBoardMain from "./LeaderBoardMain";
import GroupsIcon from "../assets/images/Images_Assets/icon-group.svg";
import logo from "../assets/images/logo/logo.png";
// const logo =  "https://ncrprodassets.s3.us-west-2.amazonaws.com/src_images/logo/logo.png";

class LeaderBoardShare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      modalIsOpen: false,
      type: "input",
      partiallySaved: false,
      partiallyUpdate: false,
      addClass: false,
      addClass1: false,
      addClass2: false,
      addClass3: false,
      tabNumber: 1,
      mensLeagueTopLeft: [],
      mensLeagueBottomLeft: [],
      mensLeagueTopRight: [],
      mensLeagueBottomRight: [],
      mensLeagueCenter: [],
      mensCenterText: "--",
      mensTopLeftText: "--",
      mensTopRightText: "--",
      mensBottomLeftText: "--",
      mensBottomRightText: "--",
      survivorsCenter: [],
      survivorsLeft: [],
      survivorsRight: [],
      challengeCenter: [],
      challengeLeft: [],
      challengeRight: [],
      championLeft: [],
      championRight: [],
      championCenter: [],
      checkTournamentStarted: false,
      showPremierScroll: true,
    };
    this.showHide = () => this.showHide.bind(this);
    this._userBracketId = "";
  }

  componentDidMount() {
    document.title = "NCR Rugby | Score Women's Bracket";
    this.getBrackets();
  }

  getBrackets = () => {
    this.setState({ loader: true });
    GetData("getGameLists?gender=female").then((response) => {
      //console.log('Response',response)
      this.setState({ loader: false });
      if (response.status === true) {
        this._userBracketId = response.data[0].user_bracket_id;
        const mensLeagueTopLeft = response.data[0].brackets.find(
          (x) => x.bracket_position === "top_left"
        );

        if (mensLeagueTopLeft.games[0].winner_id) {
          this.setState({ checkTournamentStarted: true });
        } else {
          return;
        }
        const mensLeagueTopRight = response.data[0].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const mensLeagueCenter = response.data[0].brackets[2];
        const survivorsLeft = response.data[1].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const survivorsRight = response.data[1].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const survivorsCenter = response.data[1].brackets[2];
        const challengeLeft = response.data[2].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const championLeft = response.data[3].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        console.log("championLeft", championLeft);
        this.setState({
          mensLeagueTopLeft: mensLeagueTopLeft.games,
          mensLeagueTopRight: mensLeagueTopRight.games,
          mensLeagueCenter: mensLeagueCenter.games,
          survivorsLeft: survivorsLeft.games,
          survivorsRight: survivorsRight.games,
          survivorsCenter: survivorsCenter.games,
          challengeLeft: challengeLeft.games,
          championLeft: championLeft.games,
        });
      } else {
        console.log("Error while fetching data");
      }
    });
  };

  submitBracket = () => {
    let stateKeys = [
      "championLeft",
      "challengeLeft",
      "mensLeagueTopLeft",
      "mensLeagueTopRight",
      "mensLeagueCenter",
      "survivorsLeft",
      "survivorsRight",
      "survivorsCenter",
    ];
    let formDataArray = [];
    let error = 0;
    stateKeys.map((key) => {
      let stateData = this.state[key];
      console.log("stateData", stateData);
      stateData.map((data) => {
        if (data.team1.team_id && data.team2.team_id) {
          formDataArray.push({
            user_bracket_id: this._userBracketId,
            game_id: data.game_id,
            team_1_id: data.team1.team_id,
            team_2_id: data.team2.team_id,
            winner_id: data.winner_id ? data.winner_id : data.winner,
            team1Score: null,
            team2Score: null,
          });
        } else {
          error = 1;
        }
      });

      return null;
    });
    console.log("formDataArray", formDataArray);
    if (error) {
      alert("Please fill all details");
    } else {
      let formData = new FormData();
      formData.append("userBracketDetails", JSON.stringify(formDataArray));
      this.setState({ loader: true });
      PostData("manage-user-bracket/updateDetails", formData).then(
        (response) => {
          console.log("response", response);
          this.setState({ loader: false });
          this.getBrackets();
        }
      );
    }
  };

  removeDependencies = (dependents) => {
    dependents.map((d) => {
      const updateState = this.state[d.state];
      let indexKey;
      if (d.position) {
        indexKey = updateState.findIndex(
          (x) => x.round === d.round && x.position === d.position
        );
      } else {
        indexKey = updateState.findIndex((x) => x.round === d.round);
      }
      updateState[indexKey]["winner_id"] = "";
      updateState[indexKey]["winner"] = "";
      updateState[indexKey]["selected"] = "";
      d.teams.map((t) => {
        updateState[indexKey][t] = {};
        updateState[indexKey][t] = {};
        return null;
      });

      return null;
    });
  };

  updateAllStates = (currentState, match) => {
    console.log(currentState, this.state[currentState]);
    switch (currentState) {
      case "mensLeagueTopLeft":
        var dependents = [];
        if (match.round === 1) {
          dependents = [
            {
              state: "mensLeagueTopLeft",
              round: 3,
              teams: match.position > 2 ? ["team2"] : ["team1"],
            },
            { state: "mensLeagueCenter", round: 4, teams: ["team1"] },
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team1"],
            },
            {
              state: "survivorsCenter",
              round: 2,
              position: 1,
              teams: match.position > 2 ? ["team2"] : ["team1"],
            },
            {
              state: "survivorsCenter",
              round: 3,
              position: 1,
              teams: ["team1"],
            },
            {
              state: "survivorsCenter",
              round: 3,
              position: 2,
              teams: ["team1"],
            },
            { state: "challengeLeft", round: 2, position: 1, teams: ["team1"] },
            { state: "challengeLeft", round: 2, position: 2, teams: ["team1"] },
          ];
        }
        if (match.round === 2) {
          dependents = [
            { state: "mensLeagueCenter", round: 4, teams: ["team1"] },
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team1"],
            },
          ];
        }
        if (dependents.length) {
          this.removeDependencies(dependents);
        }
        break;

      case "mensLeagueTopRight":
        var dependents = [];
        if (match.round === 1) {
          dependents = [
            {
              state: "mensLeagueTopRight",
              round: 3,
              teams: match.position > 2 ? ["team2"] : ["team1"],
            },
            { state: "mensLeagueCenter", round: 4, teams: ["team2"] },
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team2"],
            },
            {
              state: "survivorsCenter",
              round: 2,
              position: 2,
              teams: match.position > 2 ? ["team2"] : ["team1"],
            },
            {
              state: "survivorsCenter",
              round: 3,
              position: 1,
              teams: ["team2"],
            },
            {
              state: "survivorsCenter",
              round: 3,
              position: 2,
              teams: ["team2"],
            },
            { state: "challengeLeft", round: 2, position: 1, teams: ["team2"] },
            { state: "challengeLeft", round: 2, position: 2, teams: ["team2"] },
          ];
        }
        if (match.round === 2) {
          dependents = [
            { state: "mensLeagueCenter", round: 4, teams: ["team2"] },
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team2"],
            },
          ];
        }
        if (dependents.length) {
          this.removeDependencies(dependents);
        }
        break;

      case "championRight":
        var dependents = [
          { state: "championCenter", round: 3, teams: ["team2"] },
          { state: "championCenter", round: 3, position: 2, teams: ["team2"] },
        ];
        this.removeDependencies(dependents);
        break;
      default:
        break;
    }
  };
  forceUpdate = () => {
    this.handleClick(
      this._match,
      this._stateKey,
      this._team,
      this._losingTeam,
      this._index,
      true
    );
    this.setState({ modalIsOpen: false });
  };

  handleClick = (match, stateKey, team, losingTeam, cindex, force = false) => {
    return;
  };

  setAddClass = () => {
    this.setState({
      addClass: true,
      addClass1: false,
      addClass2: false,
      tabNumber: 1,
    });
  };
  setAddClass1 = () => {
    this.setState({
      addClass1: true,
      addClass: false,
      addClass2: false,
      tabNumber: 2,
    });
  };
  setAddClass2 = () => {
    this.setState({
      addClass2: true,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };
  setAddClass3 = () => {
    this.setState({
      addClass3: false,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };

  arrowClass = () => {
    if (this.state.tabNumber == 3) {
      this.setAddClass1();
    } else if (this.state.tabNumber == 2) {
      this.setAddClass();
    } else if (this.state.tabNumber == 1) {
      this.setAddClass3();
    } else {
    }
  };

  closelogin = () => {
    this.setState({ importModal: false });
  };

  render() {
    return (
      <Aux>
        <Loader show={this.state.loader} />
        <div className="Maincontent share-leaderboard-wrap">
          <div className="container-fluid">
              <div className="public-groups-wrap createbracket">
                <div className="my-brackets-wrap leader-board-wrap">
                  <Container>
                  <LeaderBoardMain
                      subPage={true}
                  />
                  </Container>
                </div> 
              </div> 
          </div>
        </div>
      </Aux>
    );
  }
}
export default withRouter(LeaderBoardShare);
