import { useNavigate, useParams, useLocation } from 'react-router-dom';

export const withRouter = (Component) => {
    return (props) => {
        const navigate = useNavigate();
        const params = useParams(); // Get route parameters
        const location = useLocation(); // Get the current location

        return (
            <Component 
                {...props} 
                navigate={navigate} 
                params={params} 
                location={location} // Pass location to the wrapped component
            />
        );
    };
};