import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "../screens/includes/Navbar";
import Placeadd from "../components/Placeadd";
import { Row, Col, Button, Form } from "react-bootstrap";
import { Link, NavLink } from "react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeaddsm from "../components/Placeaddsm";
import Input from "../components/Input";
import Radio from "../components/Radio";
import Bluebtn from "../components/Bluebtn";
import { withRouter } from "../components/withRouter";
import { globalVariable } from "../utils/global";
import { getMyIp, normalizeInput, consts } from "../utils/validation";
import calender from "../assets/socilicons/calendaricon.jpg";
import DatePicker from "react-datepicker";
import DatePicker2 from "../components/Datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

const eyeopen = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye.png";
const eyeclose = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye-close.png";
const imgDefault = "https://d7le4ielk3ho1.cloudfront.net/src_images/logo/userdefaultnew.png";

const validateInput = (value) => {
  let error = "";

  if (!value) error = "Required!";
  else if (value.length !== 14)
    error = "Invalid phone format. ex: (555) 555-5555";

  return error;
};
class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: null,
      states: null,
      eyestate: false,
      eyeconfirmstate: false,
      inputpasswordtype: false,
      inputconfirmpasswordtype: false,
      roles: null,
      userName: null,
      usernamevalid: "",
      usernameinvalid: "",
      email: null,
      firstName: null,
      lastName: null,
      password: null,
      errFilesize: "",
      forgoterrorMessage: "",
      forgotsuccesserrorMessage: "",
      confirmPassword: null,
      gender: null,
      selectedFile: null,
      role: null,
      errrole: "",
      date_of_birth: null,
      datePickerIsOpen: false,
      phoneNumber: null,
      stateId: 0,
      showSpinner: false,
      countryId: null,
      isSubscribed: 0,
      accepTermConditions: true,
      erraccepTermConditions: "",
      profile_image: "",
      upload_image: "",
      imagePreviewUrl: "",
      errUserName: "",
      errEmail: "",
      errPassword: "",
      errstateId: "",
      errConfirmPassword: "",
      errPhoneNumber: "",
      redirectTimeout: null,
      countryCode: "",
      errlastName: "",
      errfirstName: "",
      errdate_of_birth: "",
      errgender: "",
      showCalendar: false
    };
    this.onValueChange = this.onValueChange.bind(this);
    this.dateChange = this.dateChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlephonenumber = this.handlephonenumber.bind(this);
    this.passwordshowhide = this.passwordshowhide.bind(this);
    this.confirmpasswordshowhide = this.confirmpasswordshowhide.bind(this);
    // this.openDatepicker = this.openDatepicker.bind(this);
  }

  passwordshowhide() {
    this.setState((prevState) => ({ eyestate: !prevState.eyestate }));
    this.setState((prevState) => ({
      inputpasswordtype: !prevState.inputpasswordtype,
    }));
  }
  confirmpasswordshowhide() {
    this.setState((prevState) => ({
      inputconfirmpasswordtype: !prevState.inputconfirmpasswordtype,
    }));
    this.setState((prevState) => ({
      eyeconfirmstate: !prevState.eyeconfirmstate,
    }));
  }
  handleeyestate() {
    this.setState({ eyeconfirmstate: false, eyeconfirmstate: false });
  }
  handleeyestateA() {
    this.setState({ eyestate: false, eyestate: false });
  }

  handlephonenumber({ target: { value } }) {
    this.setState((prevState) => ({
      phoneNumber: normalizeInput(value, prevState.phone),
    }));
  }

  componentWillUnmount() {
    clearTimeout(this.redirectTimeout);
  }
  handleusernamevalid = (e) => {
    if (e.target.value.length > 4) {
      this.setState({ userName: e.target.value, errUserName: false });
      const uservalue = e.target.value;
      console.warn(uservalue);
      if (!consts.userNameRegex.test(uservalue)) {
        this.setState({ usernamevalid: false });
        this.setState({ usernameinvalid: 'Invalid Username' });
        return false;
      } else {
        this.setState({ usernamevalid: true });
        this.setState({ usernameinvalid: '' });
      }
      let BaseUrl =
        globalVariable.baseURL + "manage-user/validateUserName?userName=";
      fetch(BaseUrl + uservalue)
        .then((data) => data.json())
        .then((resp) => {
          console.log("resp", resp);
          console.warn("resp", resp);
          if (resp.code === 200) {
            // alert(resp.message);
            this.setState({ usernamevalid: resp.message });
            this.setState({ usernameinvalid: false });
            this.setState({ userName: uservalue });
          } else {
            // alert(resp.type);
            this.setState({ usernamevalid: false });
            this.setState({ usernameinvalid: resp.message });
            console.warn({ resp });
          }
          console.warn({ resp });
        });
    } else {
      this.setState({
        errUserName: "Minimum character 5",
        userName: null,
      });
      this.setState({ usernamevalid: false });
      this.setState({ usernameinvalid: false });
    }
  };

  toggle = () => {
    // this.component.setOpen(this.focus);
    // this.focus = !this.focus;
    this.setState({
      showCalendar: !this.state.showCalendar
    })
  };

  componentDidMount() {
    document.title = "NCR Rugby | Sign Up";
    //get countrycode
    getMyIp();
    let callcode = localStorage.getItem("callingCode");
    console.warn(callcode);
    this.setState({
      countryCode: callcode,
    });
    //calling api to fetch countries data
    let BaseUrl = globalVariable.baseURL + "commonData";
    fetch(BaseUrl)
      .then((res) => res.json())
      .then((data) => {
        if (data.code == 200) {
          this.setState({
            countries: data.data.getCountriesData,
            states: data.data.getStatesData,
            roles: data.data.getRolesData,
          });
        }
      })
      .catch((err) => { });
  }

  // dateChange(date) {
  //   console.log(date);
  //   this.setState({
  //     date_of_birth: true,
  //     errdate_of_birth: "",
  //     showCalendar:false
  //   });
  // }

  handleSelect(range, states) {
    // range is a moment-range object
    this.setState({
      value: range,
      states: states,
    });
  }

  onValueChange(changeEvent) {
    this.setState({
      gender: changeEvent.target.value,
      errgender: null,
    });
  }

  validation() {
    const currentDate = moment();
    let formIsValid = true;
    if (this.state.userName == null || this.state.userName == "") {
      this.setState({
        showSpinner: false,
        usernameinvalid: "Please fill username",
      });
      formIsValid = false;
      window.scrollTo(0, 0);
    }
    if (!consts.emailRegex.test(this.state.email) || this.state.email == null) {
      window.scrollTo(0, 0);
      this.setState({
        errEmail: "Please Enter a Valid Email ID",
        showSpinner: false,
      });
      formIsValid = false;
    }
    if (
      this.state.accepTermConditions == null ||
      this.state.accepTermConditions == false
    ) {
      this.setState({
        erraccepTermConditions: "Please Accept Terms And Conditions To Proceed",
        showSpinner: false,
      });
      formIsValid = false;
    }
    if (
      !consts.passwordRegex.test(this.state.password) ||
      this.state.password == null
    ) {
      this.setState({
        showSpinner: false,
        errPassword:
          "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
      });
      formIsValid = false;
      window.scrollTo(0, 0);
    }
    if (
      !consts.passwordRegex.test(this.state.confirmPassword) ||
      this.state.confirmPassword == null
    ) {
      this.setState({
        showSpinner: false,
        errConfirmPassword:
          "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
      });
      formIsValid = false;
      window.scrollTo(0, 0);
    }
    if (this.state.stateId == 0 && this.state.countryId == 224) {
      this.setState({
        showSpinner: false,
        errstateId: "Please Select State",
      });
      formIsValid = false;
    }
    if (this.state.gender == null || this.state.gender == "") {
      this.setState({
        showSpinner: false,
        errgender: "Please Select Gender",
      });
      formIsValid = false;
    }
    if (this.state.firstName == null || this.state.firstName == "") {
      this.setState({
        showSpinner: false,
        errfirstName: "Please Fill First Name",
      });
      formIsValid = false;
      window.scrollTo(0, 0);
    }
    if (this.state.lastName == null || this.state.lastName == "") {
      this.setState({
        showSpinner: false,
        errlastName: "Please Fill Last Name",
      });
      formIsValid = false;
      window.scrollTo(0, 0);
    }
    if (this.state.date_of_birth == null || this.state.date_of_birth == "") {
      this.setState({
        showSpinner: false,
        errdate_of_birth: "Please Fill Date Of Birth",
      });
      formIsValid = false;
    } else {
      const dob = moment(this.state.date_of_birth);
      if (!dob.isValid() || dob.isAfter(currentDate)) {
        this.setState({
          showSpinner: false,
          errdate_of_birth: "Please enter valid Date of birth",
        });
        formIsValid = false;
      }
    }
    if (this.state.role == null || this.state.role == "0") {
      this.setState({
        showSpinner: false,
        errrole: "Please Select Role",
      });
      formIsValid = false;
    }
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        showSpinner: false,
        errConfirmPassword: "Password and Confirm Password must match",
      });
      formIsValid = false;
    }
    if (
      (this.state.userName == null || this.state.userName == "") &&
      (this.state.email == null || this.state.email == "") &&
      (this.state.password == null || this.state.password == "") &&
      (this.state.confirmPassword == null ||
        this.state.confirmPassword == "") &&
      (this.state.firstName == null || this.state.firstName == "") &&
      (this.state.lastName == null || this.state.lastName == "") &&
      (this.state.gender == null || this.state.gender == "") &&
      (this.state.role == null || this.state.role == "0") &&
      (this.state.date_of_birth == null || this.state.date_of_birth == "") &&
      (this.state.phoneNumber == null || this.state.phoneNumber == "") &&
      (this.state.stateId == null ||
        (this.state.stateId == 0 && this.state.countryId == 224))
      // (this.state.countryId == null || this.state.countryId == "")
    ) {
      window.scrollTo(0, 0);
      this.setState({
        usernameinvalid: "Please Fill Username",
        errgender: "Please Fill Gender",
        errEmail: "Please Fill a valid Email ID",
        errPassword: "Please Fill Passsword",
        errstateId: "Please Select State",
        errConfirmPassword: "Please Fill Confirm Passsword",
        errmessage: "Please Fill Message",
        errrole: "Please select Role",
        // errPhoneNumber: "Please Fill Phone Number",
        errlastName: "Please Fill Last Name",
        errfirstName: "Please Fill First Name",
        errdate_of_birth: "Please Fill Date oF Birth",
        succesMessage: false,
        errorMessage: false,
        showSpinner: false,
      });

      formIsValid = false;
    }
    return formIsValid;
  }
  signup() {
    if (this.validation()) {
      console.warn(this.state.accepTermConditions);
      this.setState({
        showSpinner: true,
      });

      const formdata = new FormData();
      formdata.append("user_image", this.state.upload_image);
      this.setState({
        profile_image: formdata,
      });

      console.warn("state", this.state);
      console.log(this.state.selectedFile);
      const formData = new FormData();
      if (this.state.selectedFile == null || this.state.selectedFile == "") {
      } else {
        formData.append(
          "profile_image",
          this.state.selectedFile,
          this.state.selectedFile.name
        );
      }
      formData.append("firstName", this.state.firstName);
      formData.append("lastName", this.state.lastName);
      formData.append("email", this.state.email);
      formData.append("userName", this.state.userName);
      formData.append("phoneNumber", this.state.phoneNumber);
      formData.append("gender", this.state.gender);
      formData.append("date_of_birth", this.state.date_of_birth);
      formData.append("countryId", this.state.countryId);
      if (this.state.stateId == null || this.state.stateId == "") {
        this.setState({ stateId: "0" });
        formData.append("stateId", this.state.stateId);
      } else {
        formData.append("stateId", this.state.stateId);
      }
      formData.append("role", this.state.role);
      formData.append("password", this.state.password);
      formData.append("confirmPassword", this.state.confirmPassword);
      formData.append("isSubscribed", this.state.isSubscribed);
      formData.append("countryCode", this.state.countryCode);

      console.warn(formData);
      console.warn("test send data", formData);

      let BaseUrl = globalVariable.baseURL + "manage-user/signUp";
      fetch(BaseUrl, {
        method: "post",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      })
        .then((data) => data.json())
        .then((resp) => {
          console.log("resp", resp);
          console.warn("resp", resp);
          if (resp.code === 200) {
            const { navigate } = this.props;
            // alert("Registered Successfully!!");
            this.setState({ forgotsuccesserrorMessage: resp.message });
            this.setState({
              forgoterrorMessage: false,
              errgender: false,
              errrole: false,
              errdate_of_birth: false,
            });
            this.redirectTimeout = setTimeout(() => {
              navigate("/home");
            }, 10000);
            this.redirectTimeout = setTimeout(() => {
              this.setState({
                showSpinner: false,
              });
            }, 500);
            // console.warn(this.props.history.push("/"));
          } else {
            // alert(resp.message);
            this.setState({ forgoterrorMessage: resp.message });
            this.setState({
              forgotsuccesserrorMessage: false,
              errrole: false,
              errdate_of_birth: false,
              errgender: false,
            });
            console.warn({ resp });
            this.setState({
              showSpinner: false,
            });
          }
          console.warn({ resp });
        });
    }
  }
  //-------------- Function to handle input change for DOB input--------------
    handleInputChange(event) {
      const inputValue = event.target.value;
      const parts = inputValue.split('/');
      let formattedValue = '';
    
      if (parts.length > 0) {
        let month = parts[0].replace(/\D/g, '');
        let day = parts[1] ? parts[1].replace(/\D/g, '') : '';
        let year = parts[2] ? parts[2].replace(/\D/g, '') : '';
    
        month = month.substring(0, 2);
        day = day.substring(0, 2);
        year = year.substring(0, 4);
    
        if (month) {
          const monthNum = parseInt(month, 10);
          if (monthNum > 12) {
            month = '12';
          }
          formattedValue += month;
          if (month.length === 2 && !isNaN(monthNum)) {
            formattedValue += '/';
          }
        }
    
        if (day) {
          const dayNum = parseInt(day, 10);
          if (dayNum > 31) {
            day = '31';
          }
          formattedValue += day;
          if (day.length === 2 && !isNaN(dayNum)) {
            formattedValue += '/';
          }
        }
    
        if (year) {
          const currentYear = new Date().getFullYear();
          const yearNum = parseInt(year, 10);
          if (yearNum > currentYear) {
            year = currentYear.toString();
          }
          formattedValue += year;
        }
      }
      
      this.setState({ date_of_birth: formattedValue, showCalendar: false });
    }
    
    

  dateChange(dt) {
    console.log(dt);
    let date = moment(dt).format('MM/DD/YYYY');
    this.setState({
      date_of_birth: date,
      errdate_of_birth: null,
      showCalendar: false
    });
  }

  closelogin = () => {
    this.setState({ importModal: false });
  };

  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let selectedFile = e.target.files[0];
    if (selectedFile.size > 10485760) {
      this.setState({ errFilesize: "File size should not be more than 10 MB" });
    } else {
      this.setState({ errFilesize: "" });
      reader.onloadend = () => {
        this.setState({
          selectedFile: selectedFile,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(selectedFile);
    }
  }

  render() {
    let { onBlur, onChange, value, ...props } = this.props;

    if (this.state.countries) {
      this.state.countries.map((cn, i) => {
        if (cn.id == 224 && this.state.countryId == null) {
          this.setState({ countryId: cn.id });
        }
      });
    }
    if (this.state.roles) {
      this.state.roles.map((rs, i) => {
        if (rs.id == 1 && this.state.role == null) {
          this.setState({ role: "0" });
        }
      });
    }
    if (this.state.states) {
      this.state.states.map((s, i) => {
        if (s.id == 1 && this.state.states == null) {
          this.setState({ stateId: "0" });
        }
      });
    }
    let displayCountries = this.state.countries ? (
      this.state.countries.map((c, index) => {
        return (
          <option value={c.id} selected={c.id == 224}>
            {c.name}
          </option>
        );
      })
    ) : (
      <option>Data not fetched</option>
    );
    let { imagePreviewUrl } = this.state;
    let imagePreview = null;
    if (imagePreviewUrl !== "") {
      imagePreview = imagePreviewUrl;
    } else {
      imagePreview = imgDefault;
    }

    return (
      <Aux>
        <Menu />
        <div className="Maincontent">
          <Row>
            <Col md="12">
              <div className="leaderord headerAdvt">
                <Placeadd />
              </div>
            </Col>
          </Row>
          <Row style={{ backgroundColor: "#fafafa" }}>
            <Col>
              <div className="sighnuppad">
                <div className="sighnup-box">
                  <Row>
                    <Col md="5">
                      <div className="sighnup-img">
                        <div className="img"></div>
                      </div>
                    </Col>
                    <Col md="7">
                      <div className="sighnup-form">
                        <div>
                          <Row>
                            <Col md="12">
                              <div className="titele">Create Account</div>
                            </Col>
                          </Row>
                          <Row className="pad-bot">
                            <Col md="4">
                              <div className="profilepic">
                              <a
                                  className="uploadImgLink"
                                  onClick={() =>
                                    document.getElementById("getFile").click()
                                  }
                                >
                                <img className="upimg" src={imagePreview} />
                                <br />
                                  <span>Upload Picture</span>
                                </a>
                                <input
                                  onChange={(e) => this.handleImageChange(e)}
                                  type="file"
                                  id="getFile"
                                  style={{ display: "none" }}
                                  accept="image/x-png,image/gif,image/jpeg"
                                ></input>
                                <p className="err-paragraph">
                                  {this.state.errFilesize}
                                </p>
                              </div>
                            </Col>
                            <Col md="8">
                              <Form.Group>
                                <Input
                                  className="username-label"
                                  // onChange={(event) => {
                                  //   this.setState({
                                  //     userName: event.target.value,
                                  //     errUserName: "",
                                  //   })
                                  // }}
                                  onBlur={this.handleusernamevalid.bind(this)}
                                  label="Username *"
                                  placeholder="xyz1234"
                                  maxlength="50"
                                  minlength="5"
                                />
                                <p className="err-paragraph">
                                  {this.state.usernameinvalid && (
                                    <div
                                      style={{ color: "red" }}
                                      className="error"
                                    >
                                      {this.state.usernameinvalid}
                                    </div>
                                  )}
                                  {this.state.usernamevalid && (
                                    <div
                                      style={{ color: "green" }}
                                      className="error"
                                    >
                                      {this.state.usernamevalid}
                                    </div>
                                  )}
                                </p>
                              </Form.Group>

                              <Form.Group>
                                <Input
                                  onChange={(event) => {
                                    this.setState({
                                      email: event.target.value,
                                      errEmail: "",
                                    });
                                  }}
                                  label="Email Address *"
                                  placeholder="Enter Your Email"
                                />
                                <p className="err-paragraph">
                                  {this.state.errEmail}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ paddingLeft: "0" }}>
                              <Form.Group>
                                <Input
                                  onChange={(event) => {
                                    this.setState({
                                      firstName: event.target.value,
                                      errfirstName: "",
                                    });
                                  }}
                                  label="First Name *"
                                  placeholder="Enter First Name"
                                />
                                <p className="err-paragraph">
                                  {this.state.errfirstName}
                                </p>
                              </Form.Group>
                            </Col>
                            <Col
                              style={{ paddingLeft: "0", paddingRight: "0" }}
                            >
                              <Form.Group>
                                <Input
                                  onChange={(event) => {
                                    this.setState({
                                      lastName: event.target.value,
                                      errlastName: "",
                                    });
                                  }}
                                  label="Last Name *"
                                  placeholder="Enter Last Name"
                                />
                                <p className="err-paragraph">
                                  {this.state.errlastName}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Form.Group style={{ marginBottom: "5px" }}>
                              <div className="password_b">
                                <Input
                                  type="password"
                                  id="txtPassword"
                                  type={
                                    this.state.inputpasswordtype
                                      ? "text"
                                      : "password"
                                  }
                                  onChange={(event) => {
                                    this.setState({
                                      password: event.target.value,
                                      errPassword: "",
                                    });
                                  }}
                                  label="Password *"
                                  placeholder="Enter password"
                                />
                                <span
                                  className="password__show"
                                  onClick={this.passwordshowhide}
                                >
                                  {this.state.eyestate ? (
                                    <img
                                      onBlur={this.handleeyestateA.bind(this)}
                                      src={eyeopen}
                                      default="open"
                                    />
                                  ) : (
                                    <img
                                      onBlur={this.handleeyestateA.bind(this)}
                                      src={eyeclose}
                                      default="close"
                                    />
                                  )}{" "}
                                </span>
                              </div>

                              <p className="err-paragraph">
                                {this.state.errPassword}
                              </p>
                            </Form.Group>
                          </Row>
                          <Row>
                            <Form.Group style={{ marginBottom: "5px" }}>
                              <div className="password_b">
                                <Input
                                  type={
                                    this.state.inputconfirmpasswordtype
                                      ? "text"
                                      : "password"
                                  }
                                  id="txtConfirmPassword"
                                  onChange={(event) => {
                                    this.setState({
                                      confirmPassword: event.target.value,
                                      errConfirmPassword: "",
                                    });
                                  }}
                                  label="Confirm Password *"
                                  placeholder="Confirm password"
                                />
                                <span
                                  className="password__show"
                                  onClick={this.confirmpasswordshowhide}
                                >
                                  {this.state.eyeconfirmstate ? (
                                    <img
                                      onBlur={this.handleeyestate.bind(this)}
                                      src={eyeopen}
                                      default="open"
                                    />
                                  ) : (
                                    <img
                                      onBlur={this.handleeyestate.bind(this)}
                                      src={eyeclose}
                                      default="close"
                                    />
                                  )}{" "}
                                </span>
                              </div>
                              <p className="err-paragraph">
                                {this.state.errConfirmPassword}
                              </p>
                            </Form.Group>
                          </Row>

                          <Row>
                            <Col md="6" style={{ paddingLeft: "0" }}>
                              <label className="form-label">Sex *</label>
                              <div className="mb-3 ml-2">
                                <Radio
                                  value="male"
                                  checked={this.state.gender === "male"}
                                  onChange={this.onValueChange}
                                  name="gender"
                                  label="Male"
                                />
                                <Radio
                                  value="female"
                                  checked={this.state.gender === "female"}
                                  onChange={this.onValueChange}
                                  name="female"
                                  label="Female"
                                />
                              </div>
                              <p className="err-gender">
                                {this.state.errgender}
                              </p>
                            </Col>
                            <Col
                              md="6"
                              style={{ paddingLeft: "0", paddingRight: "0" }}
                            >
                              <Form.Group style={{ marginBottom: "15px" }}>
                                <label className="form-label">
                                  Date Of Birth *
                                </label>
                                <div className="form-dob-icon">
                                  <Input
                                    value={this.state.date_of_birth}
                                    onChange={this.handleInputChange}
                                    // readonly="true"
                                    // placeholder="Date Of Birth"
                                    placeholder="MM/DD/YYYY"
                                  />
                                  {
                                    this.state.showCalendar && <Calendar
                                      ref={(r) => {
                                        this.component = r;
                                      }}
                                      defaultValue={this.state.date_of_birth ? new Date(this.state.date_of_birth) : new Date()}
                                      selected={this.state.date_of_birth}
                                      onChange={this.dateChange}
                                      className="form-control form-dob"
                                      placeholder="Date of Birth"
                                      maxDate={new Date()}
                                      placeholderText="Select Date Of Birth"
                                      showYearDropdown
                                      onClick={this.dateChange}
                                      onClickDay={this.dateChange}
                                      // value={new Date(this.state.date_of_birth)}
                                      calendarType={'US'}
                                    />
                                  }

                                  <span
                                    onClick={() => this.toggle()}
                                    className="claendaricon"
                                  >
                                    <img src={calender} alt="calendar" />
                                  </span>
                                </div>
                                <p
                                  style={{ margin: "0" }}
                                  className="err-paragraph"
                                >
                                  {this.state.errdate_of_birth}
                                </p>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Form.Group>
                              <label className="form-label">Rugby Role *</label>
                              <br></br>
                              <select
                                className="form-control"
                                onChange={(event) => {
                                  this.setState({
                                    role: parseInt(event.target.value),
                                    errrole: null,
                                  });
                                }}
                              >
                                <option value="0">Select role</option>
                                {this.state.roles ? (
                                  this.state.roles.map((r, i) => {
                                    return (
                                      <option value={r.id}>{r.role}</option>
                                    );
                                  })
                                ) : (
                                  <option value={null}>
                                    Roles not fetched
                                  </option>
                                )}
                              </select>
                              <p
                                style={{ margin: "0" }}
                                className="err-paragraph"
                              >
                                {this.state.errrole}
                              </p>
                            </Form.Group>
                          </Row>
                          <Row></Row>
                          <Row>
                            <Form.Group style={{ marginBottom: "0px" }}>
                              <Input
                                onChange={this.handlephonenumber}
                                label="Phone Number"
                                placeholder="Enter Phone Number"
                                pattern="[1-14]{1}[0-14]{14}"
                                type="text"
                                value={this.state.phoneNumber}
                              />
                              <p className="err-paragraph">
                                {this.state.errPhoneNumber}
                              </p>
                            </Form.Group>
                          </Row>
                          <Row>
                            <Col md="6" className="sihnupcounty">
                              <Form.Group>
                                <label className="form-label">Country *</label>
                                <select
                                  className="form-control"
                                  id="country"
                                  onChange={(event) => {
                                    this.setState({
                                      countryId: parseInt(event.target.value),
                                    });
                                  }}
                                >
                                  {displayCountries}
                                </select>
                              </Form.Group>
                            </Col>
                            <Col md="6" className="sihnupstate">
                              <Form.Group>
                                {(this.state.countryId === 224 ||
                                  this.state.countryId === null) && (
                                    <div>
                                      {" "}
                                      <label className="form-label">State *</label>
                                      <select
                                        className="form-control"
                                        id="State"
                                        onChange={(event) => {
                                          this.setState({
                                            stateId: parseInt(event.target.value),
                                            errstateId: "",
                                          });
                                        }}
                                      >
                                        <option value="0">Select state</option>
                                        {this.state.states ? (
                                          this.state.states.map((s, i) => {
                                            return (
                                              <option value={s.id}>
                                                {s.name}
                                              </option>
                                            );
                                          })
                                        ) : (
                                          <option value={null}>
                                            States not fetched
                                          </option>
                                        )}
                                      </select>
                                      <p
                                        style={{ margin: "0" }}
                                        className="err-paragraph"
                                      >
                                        {this.state.errstateId}
                                      </p>
                                    </div>
                                  )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <div className="signupcheckbox">
                              {/* <div className="ncremail termsandcondition">
                                <span>
                                  <input
                                    type="checkbox"
                                    className="chkbox"
                                    onChange={(event) => {
                                      this.setState({
                                        isSubscribed: event.target.value
                                          ? 1
                                          : 0,
                                      });
                                    }}
                                  />
                                </span>
                                <p>
                                  Email me NCR news, scores and partner
                                  promotions.
                                </p>
                              </div> */}
                              <div className="ncremail termsandcondition">
                                <span>
                                  <input
                                    type="checkbox"
                                    checked={this.state.accepTermConditions}
                                    // onChange={(event) => {
                                    //   this.setState({
                                    //     accepTermConditions: event.target.value
                                    //       ? 1
                                    //       : 0,
                                    //   });
                                    // }}
                                    onChange={(event) => {
                                      this.setState({
                                        accepTermConditions:
                                          !this.state.accepTermConditions,
                                        erraccepTermConditions: "",
                                      });
                                    }}
                                  />
                                </span>
                                <p>
                                  I agree to National Collegiate Rugby’s
                                  <Link to="/terms-and-conditions" target="_blank">
                                    &nbsp;Terms of Use
                                  </Link>
                                  &nbsp;{"&"}
                                  <Link to="/private-policy" target="_blank">
                                    &nbsp;Privacy Policy
                                  </Link>
                                  .
                                </p>
                              </div>
                              <p
                                style={{ color: "red" }}
                                className="err-paragraph"
                              >
                                {this.state.erraccepTermConditions}
                              </p>
                            </div>
                          </Row>
                          <Row>
                            <Form.Text
                              style={{ marginBottom: "15px" }}
                              className="text-muted"
                            >
                              {this.state.forgoterrorMessage && (
                                <div style={{ color: "red" }} className="error">
                                  {this.state.forgoterrorMessage}
                                </div>
                              )}
                              {this.state.forgotsuccesserrorMessage && (
                                <div
                                  style={{ color: "green" }}
                                  className="succesmsg"
                                >
                                  {this.state.forgotsuccesserrorMessage}
                                </div>
                              )}
                            </Form.Text>
                          </Row>
                          <Row>
                            <div className="frombtn">
                              <Bluebtn
                                onClick={() => this.signup()}
                                value="Create Account"
                                type="submit"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.login();
                                  }
                                }}
                                spinner={
                                  this.state.showSpinner && (
                                    <i className="fa fa-circle-o-notch fa-spin"></i>
                                  )
                                }
                              />
                            </div>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Placeaddsm />
            </Col>
          </Row>
        </div>
      </Aux>
    );
  }
}

export default withRouter(SignUp);
