import React from "react";
import Aux from "../hoc/_Aux";
import { withRouter } from "../components/withRouter";
import Menu from "./includes/Navbar";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeadd from "../components/Placeadd";
import Placeaddsm from "../components/Placeaddsm";
import TimerBanner from "../components/timer-banner";
import { GetData, PostData } from "../utils/apiRequestHandler";
import BracketBox from "../components/brackets/BracketBox";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import GroupsIcon from "../assets/images/Images_Assets/icon-group.svg";
import logo from "../assets/images/logo/logo.png";
// const logo =  "https://ncrprodassets.s3.us-west-2.amazonaws.com/src_images/logo/logo.png";

class BracketScoreWomens extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      modalIsOpen: false,
      type: "input",
      partiallySaved: false,
      partiallyUpdate: false,
      addClass: false,
      addClass1: false,
      addClass2: false,
      addClass3: false,
      tabNumber: 1,
      mensLeagueTopLeft: [],
      mensLeagueBottomLeft: [],
      mensLeagueTopRight: [],
      mensLeagueBottomRight: [],
      mensLeagueCenter: [],
      mensCenterText: "--",
      mensTopLeftText: "--",
      mensTopRightText: "--",
      mensBottomLeftText: "--",
      mensBottomRightText: "--",
      survivorsCenter: [],
      survivorsLeft: [],
      survivorsRight: [],
      challengeCenter: [],
      challengeLeft: [],
      challengeRight: [],
      championLeft: [],
      championRight: [],
      championCenter: [],
      checkTournamentStarted: true,
      showPremierScroll: true,
    };
    this.showHide = () => this.showHide.bind(this);
    this._userBracketId = "";
  }

  componentDidMount() {
    document.title = "NCR Rugby | Score Women's Bracket";
    this.getBrackets();
  }

  getBrackets = () => {
    this.setState({ loader: true });
    GetData("getGameLists?gender=female").then((response) => {
      //console.log('Response',response)
      this.setState({ loader: false });
      if (response.status === true) {
        this._userBracketId = response.data[0].user_bracket_id;
        const mensLeagueTopLeft = response.data[0].brackets.find(
          (x) => x.bracket_position === "top_left"
        );

        if (mensLeagueTopLeft.games[0].winner_id) {
          this.setState({ checkTournamentStarted: true });
        } else {
          // if tournament has not started but default team has to show.
          this.setState({ checkTournamentStarted: true });
          // return;
        }
        const mensLeagueTopRight = response.data[0].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const mensLeagueCenter = response.data[0].brackets[2];
        const survivorsLeft = response.data[1].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const survivorsRight = response.data[1].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const survivorsCenter = response.data[1].brackets[2];
        const challengeLeft = response.data[2].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const championLeft = response.data[3].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        console.log("championLeft", championLeft);
        this.setState({
          mensLeagueTopLeft: mensLeagueTopLeft.games,
          mensLeagueTopRight: mensLeagueTopRight.games,
          mensLeagueCenter: mensLeagueCenter.games,
          survivorsLeft: survivorsLeft.games,
          survivorsRight: survivorsRight.games,
          survivorsCenter: survivorsCenter.games,
          challengeLeft: challengeLeft.games,
          championLeft: championLeft.games,
        });
      } else {
        console.log("Error while fetching data");
      }
    });
  };

  submitBracket = () => {
    let stateKeys = [
      "championLeft",
      "challengeLeft",
      "mensLeagueTopLeft",
      "mensLeagueTopRight",
      "mensLeagueCenter",
      "survivorsLeft",
      "survivorsRight",
      "survivorsCenter",
    ];
    let formDataArray = [];
    let error = 0;
    stateKeys.map((key) => {
      let stateData = this.state[key];
      console.log("stateData", stateData);
      stateData.map((data) => {
        if (data.team1.team_id && data.team2.team_id) {
          formDataArray.push({
            user_bracket_id: this._userBracketId,
            game_id: data.game_id,
            team_1_id: data.team1.team_id,
            team_2_id: data.team2.team_id,
            winner_id: data.winner_id ? data.winner_id : data.winner,
            team1Score: null,
            team2Score: null,
          });
        } else {
          error = 1;
        }
      });

      return null;
    });
    console.log("formDataArray", formDataArray);
    if (error) {
      alert("Please fill all details");
    } else {
      let formData = new FormData();
      formData.append("userBracketDetails", JSON.stringify(formDataArray));
      this.setState({ loader: true });
      PostData("manage-user-bracket/updateDetails", formData).then(
        (response) => {
          console.log("response", response);
          this.setState({ loader: false });
          this.getBrackets();
        }
      );
    }
  };

  removeDependencies = (dependents) => {
    dependents.map((d) => {
      const updateState = this.state[d.state];
      let indexKey;
      if (d.position) {
        indexKey = updateState.findIndex(
          (x) => x.round === d.round && x.position === d.position
        );
      } else {
        indexKey = updateState.findIndex((x) => x.round === d.round);
      }
      updateState[indexKey]["winner_id"] = "";
      updateState[indexKey]["winner"] = "";
      updateState[indexKey]["selected"] = "";
      d.teams.map((t) => {
        updateState[indexKey][t] = {};
        updateState[indexKey][t] = {};
        return null;
      });

      return null;
    });
  };

  updateAllStates = (currentState, match) => {
    console.log(currentState, this.state[currentState]);
    switch (currentState) {
      case "mensLeagueTopLeft":
        var dependents = [];
        if (match.round === 1) {
          dependents = [
            {
              state: "mensLeagueTopLeft",
              round: 3,
              teams: match.position > 2 ? ["team2"] : ["team1"],
            },
            { state: "mensLeagueCenter", round: 4, teams: ["team1"] },
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team1"],
            },
            {
              state: "survivorsCenter",
              round: 2,
              position: 1,
              teams: match.position > 2 ? ["team2"] : ["team1"],
            },
            {
              state: "survivorsCenter",
              round: 3,
              position: 1,
              teams: ["team1"],
            },
            {
              state: "survivorsCenter",
              round: 3,
              position: 2,
              teams: ["team1"],
            },
            { state: "challengeLeft", round: 2, position: 1, teams: ["team1"] },
            { state: "challengeLeft", round: 2, position: 2, teams: ["team1"] },
          ];
        }
        if (match.round === 2) {
          dependents = [
            { state: "mensLeagueCenter", round: 4, teams: ["team1"] },
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team1"],
            },
          ];
        }
        if (dependents.length) {
          this.removeDependencies(dependents);
        }
        break;

      case "mensLeagueTopRight":
        var dependents = [];
        if (match.round === 1) {
          dependents = [
            {
              state: "mensLeagueTopRight",
              round: 3,
              teams: match.position > 2 ? ["team2"] : ["team1"],
            },
            { state: "mensLeagueCenter", round: 4, teams: ["team2"] },
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team2"],
            },
            {
              state: "survivorsCenter",
              round: 2,
              position: 2,
              teams: match.position > 2 ? ["team2"] : ["team1"],
            },
            {
              state: "survivorsCenter",
              round: 3,
              position: 1,
              teams: ["team2"],
            },
            {
              state: "survivorsCenter",
              round: 3,
              position: 2,
              teams: ["team2"],
            },
            { state: "challengeLeft", round: 2, position: 1, teams: ["team2"] },
            { state: "challengeLeft", round: 2, position: 2, teams: ["team2"] },
          ];
        }
        if (match.round === 2) {
          dependents = [
            { state: "mensLeagueCenter", round: 4, teams: ["team2"] },
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team2"],
            },
          ];
        }
        if (dependents.length) {
          this.removeDependencies(dependents);
        }
        break;

      case "championRight":
        var dependents = [
          { state: "championCenter", round: 3, teams: ["team2"] },
          { state: "championCenter", round: 3, position: 2, teams: ["team2"] },
        ];
        this.removeDependencies(dependents);
        break;
      default:
        break;
    }
  };
  forceUpdate = () => {
    this.handleClick(
      this._match,
      this._stateKey,
      this._team,
      this._losingTeam,
      this._index,
      true
    );
    this.setState({ modalIsOpen: false });
  };

  handleClick = (match, stateKey, team, losingTeam, cindex, force = false) => {
    return;
  };

  setAddClass = () => {
    this.setState({
      addClass: true,
      addClass1: false,
      addClass2: false,
      tabNumber: 1,
    });
  };
  setAddClass1 = () => {
    this.setState({
      addClass1: true,
      addClass: false,
      addClass2: false,
      tabNumber: 2,
    });
  };
  setAddClass2 = () => {
    this.setState({
      addClass2: true,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };
  setAddClass3 = () => {
    this.setState({
      addClass3: false,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };

  arrowClass = () => {
    if (this.state.tabNumber == 3) {
      this.setAddClass1();
    } else if (this.state.tabNumber == 2) {
      this.setAddClass();
    } else if (this.state.tabNumber == 1) {
      this.setAddClass3();
    } else {
    }
  };

  closelogin = () => {
    this.setState({ importModal: false });
  };

  render() {
    return (
      <Aux>
        <Loader show={this.state.loader} />
        <div className="Maincontent share-score-wrap leader-board-wrap">
          <div className="container-fluid">
            {/* <div className="leader-board-toplinks">
              <Container>
                <Row>
                  <div className="col-12">
                    <ul>
                      <li>
                        <div
                          className="premier-links"
                          onClick={() => this.props.history.push("/mens-score-share")}
                        >
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/men-icon.png"
                            className="premier-icon"
                          />{" "}
                          MEN’S
                        </div>
                        <div className="premier-links active">
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/women-icon.png"
                            className="premier-icon"
                          />{" "}
                          WOMEN’S
                        </div>
                      </li>
                    </ul>
                  </div>
                </Row>
              </Container>
            </div> */}
            <div className="invite-body-outer">
              {this.state.checkTournamentStarted === true ? (
                <div className="rugby-bracket-wrap">
                  <div className="bracket-wrapper-inner">
                    <div
                      className={`${"brackets hbreakers m_women"} 
                                   ${this.state.addClass ? "hbreakers-1" : ""}
                                   ${this.state.addClass1 ? "hbreakers-2" : ""}
                                   ${this.state.addClass2 ? "hbreakers-3" : ""} 
                                   ${this.state.showPremierScroll === true
                          ? "scrollableBracket"
                          : ""
                        }
                                   `}
                    >
                      {/* <ul
                        className={`
                                    ${this.state.addClass
                            ? "bracket-rouds-wrap secRd-left"
                            : "bracket-rouds-wrap"
                          }
                                    ${this.state.addClass1 ? "secRd-left1" : ""}
                                    ${this.state.addClass2 ? "secRd-left2" : ""}
                                
                                `}
                      >
                        <li
                          className={`
                                        ${this.state.addClass
                              ? "round-tab arrow-left shift-arr1"
                              : "round-tab arrow-left"
                            }
                                        ${this.state.addClass1
                              ? "shift-arr2"
                              : ""
                            }
                                        ${this.state.addClass2
                              ? "shift-arr3"
                              : ""
                            }
                                    
                                    `}
                        >
                          <span
                            onClick={
                              this.state.showPremierScroll === true
                                ? ""
                                : this.arrowClass
                            }
                          ></span>
                        </li>
                        <li className="round-tab r_1">
                          <span className="r_name">1st ROUND</span>
                          <span className="r_date">May 29</span>
                        </li>
                        <li
                          className="round-tab r_3"
                          onClick={
                            this.state.showPremierScroll === true
                              ? ""
                              : this.setAddClass
                          }
                        >
                          <span className="r_name">QUARTERFINALS</span>
                          <span className="r_date">May 29</span>
                        </li>
                        <li
                          className="round-tab r_4"
                          onClick={
                            this.state.showPremierScroll === true
                              ? ""
                              : this.setAddClass1
                          }
                        >
                          <span className="r_name">SEMI FINALS</span>
                          <span className="r_date">May 30</span>
                        </li>
                        <li
                          className="round-tab championship"
                          onClick={
                            this.state.showPremierScroll === true
                              ? ""
                              : this.setAddClass1
                          }
                        >
                          <span className="r_name">CHAMPIONSHIP</span>
                          <span className="r_date">May 30</span>
                        </li>
                        <li className="round-tab r_4 right">
                          <span className="r_name">SEMI FINALS</span>
                          <span className="r_date">May 30</span>
                        </li>
                        <li className="round-tab r_3 right">
                          <span className="r_name">QUARTERFINALS</span>
                          <span className="r_date">May 29</span>
                        </li>
                        <li className="round-tab r_1">
                          <span className="r_name">1st ROUND</span>
                          <span className="r_date">May 29</span>
                        </li>
                      </ul> */}

                      <div className="bracket-challenge-head"></div>
                      <div className="premier-cup-head">
                        PREMIER CUP
                      </div>
                      <div
                        className={`
                                    ${this.state.addClass
                            ? "womens pairs-wrap sec-pair"
                            : "womens pairs-wrap"
                          }
                                    ${this.state.addClass1 ? "sec-pair1" : ""}
                                    ${this.state.addClass2 ? "sec-pair2" : ""}
                                `}
                      >
                        {/* <div className="pairs-wrap"> */}
                        {/* first pair */}
                        <div className="mW mW_1 mw_women">
                          <BracketBox
                            stateKey="mensLeagueTopLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* second pair */}
                        <div className="mW mW_2 mw_women">
                          <BracketBox
                            stateKey="mensLeagueTopLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            round={1}
                            position={[3, 4]}
                          />
                        </div>

                        {/* 5th pair */}
                        <div className="mW mW_5 mw_women">
                          <BracketBox
                            stateKey="mensLeagueTopRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* 6th pair */}
                        <div className="mW mW_6 mw_women">
                          <BracketBox
                            stateKey="mensLeagueTopRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            round={1}
                            position={[3, 4]}
                          />
                        </div>

                        {/* 9th pair */}
                        <div
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_9 mw_women m9Sec"
                              : "mW mW_9 mw_women"
                            }
                                    ${this.state.addClass1 ? "m9Sec" : ""}
                                    ${this.state.addClass2 ? "m9Sec" : ""}
                                `}
                        >
                          <BracketBox
                            stateKey="mensLeagueTopLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            type={2}
                            round={2}
                            position={[1, 2]}
                          />
                        </div>
                        <div
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_9-1 mw_women m9Sec"
                              : "mW mW_9-1 mw_women"
                            }
                                    ${this.state.addClass1 ? "m9Sec-1" : ""}
                                    ${this.state.addClass2 ? "m9Sec-1" : ""}
                                `}
                        ></div>

                        {/* 11th pair */}
                        <div
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_11 mw_women m11Sec"
                              : "mW mW_11 mw_women"
                            }
                                    ${this.state.addClass1 ? "m11Sec" : ""}
                                    ${this.state.addClass2 ? "m11Sec" : ""}
                                `}
                        >
                          <BracketBox
                            stateKey="mensLeagueTopRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            type={2}
                            round={2}
                            position={[1, 2]}
                          />
                        </div>
                        <div className="mW_11-1 mW"></div>

                        {/* 15th pair */}
                        <div
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_15 mw_women m15Sec"
                              : "mW mW_15 mw_women"
                            }
                                    ${this.state.addClass1 ? "m15Sec1" : ""}
                                    ${this.state.addClass2 ? "m15Sec2" : ""}
                                `}
                        >
                          <div
                            className={`
                                        ${this.state.addClass
                                ? "regions-left regTop"
                                : "regions-left"
                              }
                                        ${this.state.addClass1 ? "regTop1" : ""}
                                    `}
                          >
                            {'West'}
                          </div>
                          <BracketBox
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            type={2}
                            round={3}
                            position={[1]}
                          />
                        </div>
                        {/* 16th pair */}
                        <div
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_16 m16Sec mw_women"
                              : "mW mW_16 mw_women"
                            }
                                    ${this.state.addClass1 ? "m16Sec1" : ""}
                                    ${this.state.addClass2 ? "m16Sec2" : ""}
                                `}
                        >
                          <div
                            className={`
                                        ${this.state.addClass
                                ? "regions-right regTop"
                                : "regions-right"
                              }
                                        ${this.state.addClass1 ? "regTop1" : ""}
                                    `}
                          >
                            {'East'}
                          </div>
                          <BracketBox
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            type={2}
                            round={3}
                            position={[2]}
                          />
                        </div>
                        {/* 17th pair */}
                        <div
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_17 m17Sec mw_women"
                              : "mW mW_17 mw_women"
                            }
                                    ${this.state.addClass1 ? "m17Sec1" : ""}
                                    ${this.state.addClass2 ? "m17Sec2" : ""}
                                `}
                        >
                          <div className="nat-champion-text">
                            National Championship
                          </div>
                          <BracketBox
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueCenter}
                            type={2}
                            round={4}
                            position={[1]}
                          />
                          <BracketBox
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueCenter}
                            type={2}
                            round={4}
                            position={[2]}
                          />
                          <div
                            className="third-place-text"
                            style={{ textTransform: "none" }}
                          >
                            3rd Place
                          </div>
                        </div>
                      </div>
                      {/* pairs end */}
                      {/* <div
                        className="logo-brackets-bottom"
                        style={{ bottom: "-5%" }}
                      >
                        <img src="https://ncrprodassets.s3.us-west-2.amazonaws.com/src_images/logo/logo.png" />
                      </div> */}
                    </div>

                    {/* -------------------------survivor cup bracket ------------------------------*/}
                    <div className="brackets challenge-bracket-wrap">
                      <div className="challenge-head">
                        SURVIVOR BOWL
                      </div>
                      <div className="challenge-bracket-outer">
                        <div className="challenge-bracket-inner">
                          {/* left col pair 1 */}
                          <div className="mW mW_27">
                            <BracketBox
                              stateKey="survivorsLeft"
                              handleClick={this.handleClick}
                              data={this.state.survivorsLeft}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* right col pair 1 */}
                          <div className="mW mW_28">
                            <BracketBox
                              stateKey="survivorsRight"
                              handleClick={this.handleClick}
                              data={this.state.survivorsRight}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* left col 2 */}
                          <div className="mW mW_29">
                            <BracketBox
                              stateKey="survivorsCenter"
                              handleClick={this.handleClick}
                              data={this.state.survivorsCenter}
                              type={2}
                              round={2}
                              position={[1]}
                            />
                          </div>
                          {/* lerightft col 2 */}
                          <div className="mW mW_30">
                            <BracketBox
                              stateKey="survivorsCenter"
                              handleClick={this.handleClick}
                              data={this.state.survivorsCenter}
                              type={2}
                              round={2}
                              position={[2]}
                            />
                          </div>
                          {/* center col */}
                          <div className="mW mW_31">
                            <div className="champion-text">Championship</div>
                            <BracketBox
                              stateKey="survivorsCenter"
                              handleClick={this.handleClick}
                              data={this.state.survivorsCenter}
                              type={2}
                              round={3}
                              position={[1, 2]}
                            />
                            <div
                              className="third-place-text"
                              style={{ textTransform: "none" }}
                            >
                              3rd Place
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ left: "0px" }}
                          className="bracket-bottom-text challenge-bottom-text"
                        >
                          1st round losers from
                          <br />
                          Premier Cup
                        </div>
                      </div>
                    </div>
                    {/* -------------------------champion cup bracket ------------------------------*/}
                    <div className="women_champions_brackets">
                      <div
                        className="brackets champion-bracket-wrap womens"
                        id="challengeCup"
                      >
                        <div className="champion-head">
                          CHALLENGE  SHIELD
                        </div>
                        <div className="champion-bracket-outer">
                          <div className="champion-bracket-inner womens">
                            {/* left col 2 */}
                            <div className="mw_women mW mW_34">
                              <BracketBox
                                stateKey="challengeLeft"
                                handleClick={this.handleClick}
                                data={this.state.challengeLeft}
                                type={2}
                                round={1}
                                position={[1]}
                              />
                            </div>
                            <div className="mw_women mW mW_34-1"></div>
                            {/* lerightft col 2 */}
                            <div className="mw_women mW mW_35">
                              <BracketBox
                                stateKey="challengeLeft"
                                handleClick={this.handleClick}
                                data={this.state.challengeLeft}
                                type={2}
                                round={1}
                                position={[2]}
                              />
                            </div>
                            <div className="mw_women mW mW_35-1"></div>
                            {/* center col */}
                            <div className="mw_women mW mW_36">
                              <div className="champion-text">Championship</div>
                              <BracketBox
                                stateKey="challengeLeft"
                                handleClick={this.handleClick}
                                data={this.state.challengeLeft}
                                type={2}
                                round={2}
                                position={[1, 2]}
                              />
                              <div
                                className="third-place-text"
                                style={{ textTransform: "none" }}
                              >
                                3rd Place
                              </div>
                            </div>
                            <div className="bracket-bottom-text womens challenge-bottom-text">
                              1st round losers of
                              <br />
                              Survivor Bowl
                            </div>
                          </div>
                          
                        </div>
                  </div>

                    {/* -------------------------champion cup bracket ------------------------------*/}
                    <div className="brackets champion-bracket-wrap womens">
                        <div className="champion-head">
                          CONTENDER PLATE
                        </div>
                        <div className="champion-bracket-outer">
                          <div className="champion-bracket-inner womens">
                            {/* left col 2 */}
                            <div className="mw_women mW mW_34">
                              <BracketBox
                                stateKey="championLeft"
                                handleClick={this.handleClick}
                                data={this.state.championLeft}
                                type={2}
                                round={1}
                                position={[1]}
                              />
                            </div>
                            <div className="mw_women mW mW_34-1"></div>
                            {/* lerightft col 2 */}
                            <div className="mw_women mW mW_35">
                              <BracketBox
                                stateKey="championLeft"
                                handleClick={this.handleClick}
                                data={this.state.championLeft}
                                type={2}
                                round={1}
                                position={[2]}
                              />
                            </div>
                            <div className="mw_women mW mW_35-1"></div>
                            {/* center col */}
                            <div className="mw_women mW mW_36">
                              <div className="champion-text">Championship</div>
                              <BracketBox
                                stateKey="championLeft"
                                handleClick={this.handleClick}
                                data={this.state.championLeft}
                                type={2}
                                round={2}
                                position={[1, 2]}
                              />
                              <div
                                className="third-place-text"
                                style={{ textTransform: "none" }}
                              >
                                3rd Place
                              </div>
                            </div>
                            <div className="bracket-bottom-text womens challenge-bottom-text">
                              2nd round losers of
                              <br />
                              Premier Cup
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="bracket-error-message">
                  {/* <img src={logo} alt="logo" />
                  <div className="message">
                    Tournament has not been started yet.
                  </div> */}
                </div>
              )}
            </div>
            <Placeaddsm />
            <Modal
              imgsrc={logo}
              toggle={() => this.setState({ modalIsOpen: false })}
              showModal={this.state.modalIsOpen}
              title="WOMEN’S PREMIER CUP"
              className="rugby-mens-bracket"
              bodytext={this.state.modalMsg}
              btn1value={this.state.btn1value}
              btn2value={this.state.btn2value}
              button1Click={() => this.setState({ modalIsOpen: false })}
              button2Click={this.forceUpdate}
            />
          </div>
        </div>
      </Aux>
    );
  }
}
export default withRouter(BracketScoreWomens);
