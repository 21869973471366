import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router";
import ScrollToTop from "../src/components/ScrollToTop";
import { GetData } from "./utils/apiRequestHandler";

import Footer from "../src/screens/includes/Footer";
import Home from "./screens/Home";
import Homelogin from "./screens/UserHome";
import Signup from "./screens/SignUp";
import Profile from "./screens/Profile";
import AboutUs from "./screens/AboutUs";
import MatchTimes from "./screens/MatchTimes";

import Termscondition from "./screens/Termscondition";
import Privatepolicy from "./screens/Privatepolicy";
import InviteFriend from "./screens/InviteFriend";
import HowToPlay from "./screens/HowToPlay";
import LeaderBoardMain from "./screens/LeaderBoardMain";
import Contactus from "./screens/Contactus";
import FillBracket from "./screens/FillBracket";
import Createbracket from "./screens/Create-bracket";
import WhereToWatch from './screens/whereToWatch';

import RugbyMensBracket from "./screens/rugby-mens-bracket";
import RugbyWomensBracket from "./screens/rugby-womens-bracket";

import RugbyMensWiningBracket from "./screens/rugby-mens-wining-bracket";
import RugbyWomensWiningBracket from "./screens/rugby-womens-wining-bracket";

import AdminMensBracket from "./screens/admin-mens-bracket";
import AdminWomensBracket from "./screens/admin-womens-bracket";

import MensBracketScore from "./screens/score-mens-bracket";
import WomensBracketScore from "./screens/score-womens-bracket";

import MensScoreShare from "./screens/MensScoreShare";
import WomensScoreShare from "./screens/WomensScoreShare";
import LeaderBoardShare from "./screens/LeaderBoardShare";

import VerifyEmail from "./screens/verify-email";
import ResetPassword from "./screens/reset-password";
import Groups from "./screens/Groups";

import CreateBracketNew from "./screens/CreateBracketNew";
import PublicGroup from "./screens/PublicGroup";
import Readmore from "./screens/Readmore";

// Create a wrapper component for protected routes
const ProtectedRoute = ({ children }) => {
  const auth = localStorage.getItem("login");
  if (!auth) {
    return <Navigate to="/" />;
  }
  return children;
};

const UnprotectedRoute = ({ children }) => {
  const auth = localStorage.getItem("login");
  if (auth) {
    return <Navigate to="/Home" />;
  }
  return children;
};

class AppRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tournamenttime: localStorage.getItem("SatrtTime"),
      localtoken: localStorage.getItem("login"),
    };
  }

  GetTime = () => {
    GetData("starttime").then((response) => {
      if (response !== "") {
        let checkLocalStorage = localStorage.SatrtTime;
        localStorage.setItem("SatrtTime", response.gameStarted);
        localStorage.setItem("tournamenttime", JSON.stringify(response));
        if (checkLocalStorage === undefined && response.gameStarted === true) {
          window.location.reload();
        }
      } else {
        console.log("Error while fetching data");
      }
    });
  };

  componentDidMount() {
    this.GetTime();
  }

  render() {
    return (
      <div>
        <ScrollToTop>
          <Routes>
            <Route
              path="/"
              element={
                <UnprotectedRoute>
                  {this.state.tournamenttime === "true" && 
                   this.state.localtoken == null ? (
                    <Homelogin />
                  ) : (
                    <Home />
                  )}
                </UnprotectedRoute>
              }
            />
            
            <Route
              path="/Home"
              element={
                <ProtectedRoute>
                  <Homelogin />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            
            <Route 
              path="/sign-up" 
              element={
                <UnprotectedRoute>
                  <Signup />
                </UnprotectedRoute>
              } 
            />

            {/* Public routes */}
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/match-times" element={<MatchTimes />} />
            <Route path="/terms-and-conditions" element={<Termscondition />} />
            <Route path="/private-policy" element={<Privatepolicy />} />
            <Route path="/invite-friends" element={<InviteFriend />} />
            <Route path="/how-to-play" element={<HowToPlay />} />
            <Route path="/leader-board-main/:id" element={<LeaderBoardMain />} />
            <Route path="/contact-us" element={<Contactus />} />
            
            {/* Protected routes */}
            <Route
              path="/fill-brackets"
              element={
                <ProtectedRoute>
                  <FillBracket />
                </ProtectedRoute>
              }
            />
            
            <Route path="/create-brackets/:tabname?/:gender?" element={<Createbracket />} />
            <Route path="/read-more" element={<Readmore />} />
            
            <Route
              path="/mens-bracket/:name/:id"
              element={
                <ProtectedRoute>
                  <RugbyMensBracket />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="/mens-bracket-public/:name/:id"
              element={<RugbyMensBracket />}
            />
            
            <Route
              path="/manage-mens-bracket"
              element={
                <ProtectedRoute>
                  <AdminMensBracket />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="/manage-womens-bracket"
              element={
                <ProtectedRoute>
                  <AdminWomensBracket />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="/womens-bracket/:name/:id"
              element={
                <ProtectedRoute>
                  <RugbyWomensBracket />
                </ProtectedRoute>
              }
            />
            
            <Route
              path="/womens-bracket-public/:name/:id"
              element={<RugbyWomensBracket />}
            />
            
            <Route path="/mens-wining-bracket" element={<RugbyMensWiningBracket />} />
            <Route path="/womens-wining-bracket" element={<RugbyWomensWiningBracket />} />
            <Route path="/mens-score" element={<MensBracketScore />} />
            <Route path="/my-groups" element={<Groups />} />
            <Route path="/public-groups" element={<PublicGroup />} />
            <Route path="/womens-score" element={<WomensBracketScore />} />
            <Route path="/where-to-watch" element={<WhereToWatch />} />
            <Route path="/verify-email/:token" element={<VerifyEmail />} />
            <Route path="/reset-password/:forgottoken" element={<ResetPassword />} />
            <Route path="/mens-score-share" element={<MensScoreShare />} />
            <Route path="/womens-score-share" element={<WomensScoreShare />} />
            <Route path="/leaderboard-share" element={<LeaderBoardShare />} />
          </Routes>
          {window.location.pathname !== '/mens-score-share' && window.location.pathname !== '/womens-score-share' && window.location.pathname !== '/leaderboard-share' && window.location.pathname !== '/mens-wining-bracket' && window.location.pathname !== '/womens-wining-bracket' ? <Footer /> : ''}
        </ScrollToTop>
      </div>
    );
  }
}

export default AppRoutes;