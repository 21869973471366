import axios from "axios";
const baseurltoken =
  "Authenticator eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFtaXR0aGFrdXJAbW9iaWthc2EuY29tIiwidXNlcl9pZCI6NiwiZnVsbE5hbWUiOiJBbWl0IiwiZXhwaXJlc0luIjoxNjE3MjY0MjAwOTUyLCJpYXQiOjE2MTcyNjQyMDAsImV4cCI6MTY0ODgwMDIwMH0.XPWxHDDft9cRNCyP4OZrju_La0N41FVoXhEXcKIcZfw ";
// let baseURL = "https://api.maymadness7s.com/api/v1/";
let baseURL = "https://stageapi.maymadness7s.com/api/v1/";
// let baseURL = process.env.REACT_APP_API_URL
// let baseURL = "https://bracketsapi.maymadness7s.com/api/v1/";

export const globalVariable = {
  baseURL: baseURL,
  baseurltoken: baseurltoken,
  appVersion: "0.1.0",
};

export const getMyIp = async () => {
  fetch("https://api.ipify.org?format=json")
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      localStorage.setItem("ip", res.ip);
    })
    .catch((err) => console.error("Problem fetching my IP", err));

  let ip = localStorage.getItem("ip");
  let baseipurl = "https://ipapi.co/" + ip + "/country_calling_code/";
  console.warn(baseipurl);
  axios
    .get(baseipurl)
    .then((resp) => {
      console.warn("resp", resp);
      console.warn(resp);
      localStorage.setItem("callingCode", resp.data);
    })
    .catch((err) => {
      alert(err.response.data.message);
    });
};

export const consts = {
  emailRegex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  passwordRegex: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
  phoneRegex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
  userNameRegex: /^(?:[A-Z\d][A-Z\d_-]{4,10}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i,
  multiplemailRegex: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/,
  alphanumericRegex: /^[a-zA-Z0-9 ]*$/
};
export const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 14)}`;
  }
};
