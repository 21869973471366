import React from "react";
import Aux from "../hoc/_Aux";
import { withRouter } from "../components/withRouter";
import Menu from "./includes/Navbar";
import { Row, Col, Container } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeadd from "../components/Placeadd";
import Placeaddsm from "../components/Placeaddsm";
import { GetData, PostData } from "../utils/apiRequestHandler";
import BracketBox from "../components/brackets/BracketBox";
import Loader from "../components/Loader";

class RugbyBracket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      modalIsOpen: false,
      type: "input",
      addClass: false,
      addClass1: false,
      addClass2: false,
      addClass3: false,
      tabNumber: 1,
      mensLeagueTopLeft: [],
      mensLeagueBottomLeft: [],
      mensLeagueTopRight: [],
      mensLeagueBottomRight: [],
      mensLeagueCenter: [],
      mensCenterText: "--",
      mensTopLeftText: "--",
      mensTopRightText: "--",
      mensBottomLeftText: "--",
      mensBottomRightText: "--",
      survivorsCenter: [],
      survivorsLeft: [],
      survivorsRight: [],
      challengeCenter: [],
      challengeLeft: [],
      challengeRight: [],
      championLeft: [],
      championRight: [],
      championCenter: [],
      showPremierScroll: true,
    };
    this.showHide = () => this.showHide.bind(this);
    this._userBracketId = "";
  }

  componentDidMount() {
    document.title = "NCR Rugby | Admin Women's Bracket";
    if (localStorage.tp !== "1") {
      this.props.navigate("/");
    } else {
      this.getBrackets();
    }
  }

  getBrackets = () => {
    this.setState({ loader: true });
    GetData("getGameLists?gender=female").then((response) => {
      //console.log('Response',response)
      this.setState({ loader: false });
      if (response.status === true) {
        this._userBracketId = response.data[0].user_bracket_id;
        const mensLeagueTopLeft = response.data[0].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const mensLeagueTopRight = response.data[0].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const mensLeagueCenter = response.data[0].brackets[2];
        const survivorsLeft = response.data[1].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const survivorsRight = response.data[1].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const survivorsCenter = response.data[1].brackets[2];
        const challengeLeft = response.data[2].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const championLeft = response.data[3].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        console.log("championLeft", championLeft);

        this.setState({
          mensLeagueTopLeft: mensLeagueTopLeft.games,
          mensLeagueTopRight: mensLeagueTopRight.games,
          mensLeagueCenter: mensLeagueCenter.games,
          survivorsLeft: survivorsLeft.games,
          survivorsRight: survivorsRight.games,
          survivorsCenter: survivorsCenter.games,
          challengeLeft: challengeLeft.games,
          championLeft: championLeft.games,
        });
      } else {
        console.log("Error while fetching data");
      }
    });
  };

  setScore = (e, match, team, index, stateKey) => {
    if (isNaN(e.target.value)) {
      return;
    }
    if (e.target.value.length > 3) {
      return;
    }
    let currentStateData = this.state[stateKey];
    let currentTeam = "team1_score";
    if (team === "team2") {
      currentTeam = "team2_score";
    }
    currentStateData[index][currentTeam] = e.target.value;
    this.setState({ [stateKey]: currentStateData });
  };

  submitBracket = (stateKeys) => {
    stateKeys = [
      "mensLeagueTopLeft",
      "mensLeagueTopRight",
      "mensLeagueCenter",
      "championLeft",
      "championRight",
      "championCenter",
      "challengeLeft",
      "challengeRight",
      "challengeCenter",
      "survivorsLeft",
      "survivorsRight",
      "survivorsCenter",
    ];
    let formDataArray = [];
    let error = 0;
    stateKeys.map((key) => {
      let stateData = this.state[key];
      console.log("stateData", stateData);
      stateData.map((data) => {
        formDataArray.push({
          game_id: data.game_id,
          team1_score: data.team1_score ? parseInt(data.team1_score) : 0,
          team2_score: data.team2_score ? parseInt(data.team2_score) : 0,
        });
      });

      return null;
    });

    if (error) {
      alert("Please fill all details");
    } else {
      let formData = new FormData();
      formData.append("game_data", JSON.stringify(formDataArray));
      this.setState({ loader: true });
      PostData("/updateTeamScore", formData).then((response) => {
        console.log("response", response);
        this.setState({ loader: false });
        this.getBrackets();
      });
    }
  };

  updateAllStates = (winningTeam, losingTeam, currentState, match) => {
    console.log("match", match);
    if (
      currentState === "mensLeagueTopLeft" ||
      currentState === "mensLeagueBottomLeft"
    ) {
      let teamToUpdate = "team1";
      if (match.position > 2 && match.position <= 4) {
        teamToUpdate = "team2";
      }
      if (match.position > 4 && match.position <= 6) {
        teamToUpdate = "team1";
      }
      if (match.position > 6 && match.position <= 8) {
        teamToUpdate = "team2";
      }
      if (match.round === 1) {
        const mensLeagueData = { ...this.state[currentState] };
        if (mensLeagueData[6][teamToUpdate].team_id !== winningTeam.team_id) {
          mensLeagueData[6][teamToUpdate] = { team_id: "", name: "" };
        }
      }

      const mensLeagueCenterData = { ...this.state["mensLeagueCenter"] };
      let centerTeamId = "team1";
      if (match.position > 4) {
        centerTeamId = "team2";
      }

      if (
        mensLeagueCenterData[0][centerTeamId].team_id !== winningTeam.team_id
      ) {
        mensLeagueCenterData[0][centerTeamId] = { team_id: "", name: "" };
      }

      if (mensLeagueCenterData[2]["team1"].team_id !== winningTeam.team_id) {
        mensLeagueCenterData[2]["team1"] = { team_id: "", name: "" };
      }
    }
  };

  handleClick = (match, stateKey, team, losingTeam, cindex) => {
    if (this.state[stateKey][cindex]) {
      let currentStateData = this.state[stateKey][cindex];
      currentStateData["winner"] = team.team_id;
      currentStateData["selected"] = team.team_id;
    }
    if (
      (stateKey === "challengeLeft" || stateKey === "championLeft") &&
      match.round === 2
    ) {
      let leagueData = this.state[stateKey];
      this.setState({ [stateKey]: leagueData });
    }

    if (
      (stateKey === "challengeLeft" || stateKey === "championLeft") &&
      match.round === 1
    ) {
      let leagueData = this.state[stateKey];
      console.log("leagueData", leagueData);
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 2 && x.position === 1
      );
      const leagueIndexLosingTeam = leagueData.findIndex(
        (x) => x.round === 2 && x.position === 2
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
        leagueData[leagueIndexLosingTeam].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
        leagueData[leagueIndexLosingTeam].team2 = losingTeam;
      }

      this.setState({ [stateKey]: leagueData });
    }

    if (stateKey === "survivorsCenter" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 1
      );
      const leagueIndexLosingTeam = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 2
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
        leagueData[leagueIndexLosingTeam].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
        leagueData[leagueIndexLosingTeam].team2 = losingTeam;
      }

      this.setState({ [stateKey]: leagueData });
      return;
    }
    if (stateKey === "survivorsCenter" && match.round === 3) {
      let leagueData = this.state[stateKey];
      this.setState({ [stateKey]: leagueData });
    }

    if (stateKey === "survivorsLeft" || stateKey === "survivorsRight") {
      let challengeKey = "survivorsCenter";
      let leagueData = this.state[challengeKey];
      let position = 1;
      if (stateKey === "survivorsRight") {
        position = 2;
      }

      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 2 && x.position === position
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
      }

      if (match.round === 1) {
        let k = 0;
        let challengeKey = "challengeLeft";
        if (stateKey === "survivorsRight") {
          k = 1;
        }
        let challengeData = this.state[challengeKey];

        if (match.position % 2 !== 0) {
          challengeData[k]["team1"] = losingTeam;
        }
        if (match.position % 2 === 0) {
          challengeData[k]["team2"] = losingTeam;
        }
        this.setState({ [challengeKey]: challengeData });
      }

      this.setState({ survivorsCenter: leagueData });
      return;
    }

    if (stateKey === "mensLeagueCenter" && match.round === 4) {
      let leagueData = this.state[stateKey];
      leagueData[cindex]["winner"] = team.team_id;
      leagueData[cindex]["selected"] = team.team_id;
      this.setState({ [stateKey]: leagueData });
    }
    if (
      (stateKey === "mensLeagueTopLeft" || stateKey === "mensLeagueTopRight") &&
      match.round === 3
    ) {
      stateKey = "mensLeagueCenter";
      let leagueData = this.state[stateKey];
      let stateDataKey = "mensLeagueTopLeft";
      if (match.position === 2) {
        stateDataKey = "mensLeagueTopRight";
      }
      this.state[stateDataKey][cindex]["winner"] = team.team_id;
      this.state[stateDataKey][cindex]["selected"] = team.team_id;
      console.log("leagueData", leagueData);

      let position = 1;

      if (match.position % 2 !== 0) {
        leagueData[0]["team1"] = team;
        leagueData[1]["team1"] = losingTeam;
      }
      if (match.position % 2 === 0) {
        leagueData[0]["team2"] = team;
        leagueData[1]["team2"] = losingTeam;
      }
      console.log(position, leagueData);
      this.setState({ [stateKey]: leagueData });
    }

    if (stateKey === "mensLeagueTopLeft" || stateKey === "mensLeagueTopRight") {
      let round = match.round + 1;
      let leagueData = this.state[stateKey];
      leagueData[cindex]["winner"] = team.team_id;
      leagueData[cindex]["selected"] = team.team_id;
      let position = 1;
      if (match.position > 2) {
        position = 2;
      }
      if (stateKey === "mensLeagueTopRight" && round === 3) {
        position = 2;
      }
      let index = leagueData.findIndex(
        (x) => x.position === position && x.round === round
      );

      if (match.position % 2 !== 0) {
        leagueData[index]["team1"] = team;
      }
      if (match.position % 2 === 0) {
        leagueData[index]["team2"] = team;
      }

      if (match.round === 1) {
        let survivorKey = "survivorsLeft";
        if (stateKey === "mensLeagueTopRight") {
          survivorKey = "survivorsRight";
        }
        let survivorData = this.state[survivorKey];
        let k = 0;
        if (match.position > 2) {
          k = 1;
        }
        if (match.position % 2 !== 0) {
          survivorData[k]["team1"] = losingTeam;
        }
        if (match.position % 2 === 0) {
          survivorData[k]["team2"] = losingTeam;
        }
        this.setState({ [survivorKey]: survivorData });
      }

      if (match.round === 2) {
        let k = 0;
        let championKey = "championLeft";
        console.log("stateKey", stateKey);
        if (stateKey === "mensLeagueTopRight") {
          k = 1;
        }
        let championData = this.state[championKey];

        if (match.position % 2 !== 0) {
          championData[k]["team1"] = losingTeam;
        }
        if (match.position % 2 === 0) {
          championData[k]["team2"] = losingTeam;
        }
        this.setState({ [championKey]: championData });
      }

      this.setState({ [stateKey]: leagueData });
    }
  };

  setAddClass = () => {
    this.setState({
      addClass: true,
      addClass1: false,
      addClass2: false,
      tabNumber: 1,
    });
  };
  setAddClass1 = () => {
    this.setState({
      addClass1: true,
      addClass: false,
      addClass2: false,
      tabNumber: 2,
    });
  };
  setAddClass2 = () => {
    this.setState({
      addClass2: true,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };
  setAddClass3 = () => {
    this.setState({
      addClass3: false,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };

  arrowClass = () => {
    if (this.state.tabNumber == 3) {
      this.setAddClass1();
    } else if (this.state.tabNumber == 2) {
      this.setAddClass();
    } else if (this.state.tabNumber == 1) {
      this.setAddClass3();
    } else {
    }
  };

  closelogin = () => {
    this.setState({ importModal: false });
  };

  render() {
    return (
      <Aux>
        <Loader show={this.state.loader} />
        <Menu />
        <div className="Maincontent admin-container leader-board-wrap">
          <div className="container-fluid">
            <Row>
              <Col md="12">
                <div className="leaderord headerAdvt">
                  <Placeadd />
                </div>
              </Col>
            </Row>
            <div className="leader-board-toplinks">
              <Container>
                <Row>
                  <div className="col-12">
                    <ul>
                      <li>
                        <div
                          className="premier-links"
                          onClick={() =>
                            this.props.navigate("/manage-mens-bracket")
                          }
                        >
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/men-icon.png"
                            className="premier-icon"
                          />{" "}
                          MEN’S TOURNAMENT
                        </div>
                        <div className="premier-links active">
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/women-icon.png"
                            className="premier-icon"
                          />{" "}
                          WOMEN’S TOURNAMENT
                        </div>
                        <div
                          className="premier-links"
                          onClick={() => this.props.navigate("/groups")}
                        >
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/men-icon.png"
                            className="premier-icon"
                          />{" "}
                          GROUPS
                        </div>
                      </li>
                    </ul>
                  </div>
                </Row>
              </Container>
            </div>
            <div className="invite-body-outer">
              <div className="rugby-bracket-wrap">
                <h1></h1>

                <div className="bracket-wrapper-inner">
                  <div
                    className={`${"brackets hbreakers m_women"} 
                                   ${this.state.addClass ? "hbreakers-1" : ""}
                                   ${this.state.addClass1 ? "hbreakers-2" : ""}
                                   ${this.state.addClass2 ? "hbreakers-3" : ""} 
                                   ${
                                     this.state.showPremierScroll === true
                                       ? "scrollableBracket"
                                       : ""
                                   }
                                   `}
                  >
                    <ul
                      className={`
                                    ${
                                      this.state.addClass
                                        ? "bracket-rouds-wrap secRd-left"
                                        : "bracket-rouds-wrap"
                                    }
                                    ${this.state.addClass1 ? "secRd-left1" : ""}
                                    ${this.state.addClass2 ? "secRd-left2" : ""}
                                
                                `}
                    >
                      <li
                        className={`
                                        ${
                                          this.state.addClass
                                            ? "round-tab arrow-left shift-arr1"
                                            : "round-tab arrow-left"
                                        }
                                        ${
                                          this.state.addClass1
                                            ? "shift-arr2"
                                            : ""
                                        }
                                        ${
                                          this.state.addClass2
                                            ? "shift-arr3"
                                            : ""
                                        }
                                    
                                    `}
                      >
                        <span
                          onClick={
                            this.state.showPremierScroll === true
                              ? ""
                              : this.arrowClass
                          }
                        ></span>
                      </li>
                      <li className="round-tab r_1">
                        <span className="r_name">1st ROUND</span>
                        <span className="r_date">April 27</span>
                      </li>
                      <li
                        className="round-tab r_3"
                        onClick={
                          this.state.showPremierScroll === true
                            ? ""
                            : this.setAddClass
                        }
                      >
                        <span className="r_name">QUARTERFINALS</span>
                        <span className="r_date">April 27</span>
                      </li>
                      <li
                        className="round-tab r_4"
                        onClick={
                          this.state.showPremierScroll === true
                            ? ""
                            : this.setAddClass1
                        }
                      >
                        <span className="r_name">SEMI FINALS</span>
                        <span className="r_date">April 28</span>
                      </li>
                      <li
                        className="round-tab championship"
                        onClick={
                          this.state.showPremierScroll === true
                            ? ""
                            : this.setAddClass1
                        }
                      >
                        <span className="r_name">Championship</span>
                        <span className="r_date">April 28</span>
                      </li>
                      <li className="round-tab r_4 right">
                        <span className="r_name">SEMI FINALS</span>
                        <span className="r_date">April 28</span>
                      </li>
                      <li className="round-tab r_3 right">
                        <span className="r_name">QUARTERFINALS</span>
                        <span className="r_date">April 27</span>
                      </li>
                      <li className="round-tab r_1">
                        <span className="r_name">1st ROUND</span>
                        <span className="r_date">April 27</span>
                      </li>
                    </ul>

                    <div className="bracket-challenge-head"></div>
                    <div className="premier-cup-head">
                      PREMIER
                      <br />
                      CUP
                    </div>
                    <div
                      className={`
                                    ${
                                      this.state.addClass
                                        ? "pairs-wrap sec-pair"
                                        : "pairs-wrap"
                                    }
                                    ${this.state.addClass1 ? "sec-pair1" : ""}
                                    ${this.state.addClass2 ? "sec-pair2" : ""}
                                `}
                    >
                      {/* <div className="pairs-wrap"> */}
                      {/* first pair */}
                      <div className="mW mW_1 mw_women">
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopLeft"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopLeft}
                          round={1}
                          position={[1, 2]}
                        />
                      </div>
                      {/* second pair */}
                      <div className="mW mW_2 mw_women">
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopLeft"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopLeft}
                          round={1}
                          position={[3, 4]}
                        />
                      </div>

                      {/* 5th pair */}
                      <div className="mW mW_5 mw_women">
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopRight"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopRight}
                          round={1}
                          position={[1, 2]}
                        />
                      </div>
                      {/* 6th pair */}
                      <div className="mW mW_6 mw_women">
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopRight"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopRight}
                          round={1}
                          position={[3, 4]}
                        />
                      </div>

                      {/* 9th pair */}
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_9 mw_women m9Sec"
                                        : "mW mW_9 mw_women"
                                    }
                                    ${this.state.addClass1 ? "m9Sec" : ""}
                                    ${this.state.addClass2 ? "m9Sec" : ""}
                                `}
                      >
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopLeft"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopLeft}
                          type={2}
                          round={2}
                          position={[1, 2]}
                        />
                      </div>
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_9-1 mw_women m9Sec"
                                        : "mW mW_9-1 mw_women"
                                    }
                                    ${this.state.addClass1 ? "m9Sec-1" : ""}
                                    ${this.state.addClass2 ? "m9Sec-1" : ""}
                                `}
                      ></div>

                      {/* 11th pair */}
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_11 mw_women m11Sec"
                                        : "mW mW_11 mw_women"
                                    }
                                    ${this.state.addClass1 ? "m11Sec" : ""}
                                    ${this.state.addClass2 ? "m11Sec" : ""}
                                `}
                      >
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopRight"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopRight}
                          type={2}
                          round={2}
                          position={[1, 2]}
                        />
                      </div>
                      <div className="mW_11-1 mW"></div>
                      {/* 15th pair */}
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_15 mw_women m15Sec"
                                        : "mW mW_15 mw_women"
                                    }
                                    ${this.state.addClass1 ? "m15Sec1" : ""}
                                    ${this.state.addClass2 ? "m15Sec2" : ""}
                                `}
                      >
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopLeft"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopLeft}
                          type={2}
                          round={3}
                          position={[1]}
                        />
                      </div>
                      {/* 16th pair */}
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_16 m16Sec mw_women"
                                        : "mW mW_16 mw_women"
                                    }
                                    ${this.state.addClass1 ? "m16Sec1" : ""}
                                    ${this.state.addClass2 ? "m16Sec2" : ""}
                                `}
                      >
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueTopRight"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueTopRight}
                          type={2}
                          round={3}
                          position={[2]}
                        />
                      </div>
                      {/* 17th pair */}
                      <div
                        className={`
                                    ${
                                      this.state.addClass
                                        ? "mW mW_17 m17Sec mw_women"
                                        : "mW mW_17 mw_women"
                                    }
                                    ${this.state.addClass1 ? "m17Sec1" : ""}
                                    ${this.state.addClass2 ? "m17Sec2" : ""}
                                `}
                      >
                        <div className="nat-champion-text">
                          National Championship
                        </div>
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueCenter"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueCenter}
                          type={2}
                          round={4}
                          position={[1]}
                        />
                        <BracketBox
                          setScore={this.setScore}
                          edit="true"
                          stateKey="mensLeagueCenter"
                          handleClick={this.handleClick}
                          data={this.state.mensLeagueCenter}
                          type={2}
                          round={4}
                          position={[2]}
                        />
                        <div
                          className="third-place-text"
                          style={{ textTransform: "none" }}
                        >
                          3rd Place
                        </div>
                      </div>
                    </div>
                    {/* pairs end */}
                    <div
                      className="logo-brackets-bottom"
                      style={{ bottom: "-5%" }}
                    >
                      <img
                        src="/assets/images/logo-brackets.png"
                        alt="Bracket challenge"
                      />
                      <div
                        className="submit-bracket"
                        style={{ position: "unset", marginTop: "20px" }}
                      >
                        <input
                          type="button"
                          className="submit-style"
                          value="SAVE"
                          onClick={() =>
                            this.submitBracket([
                              "mensLeagueTopLeft",
                              "mensLeagueTopRight",
                              "mensLeagueCenter",
                            ])
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {/* -------------------------champion cup bracket ------------------------------*/}
                  <div className="brackets champion-bracket-wrap womens">
                    <div className="champion-head"> PLATE</div>
                    <div className="champion-bracket-outer">
                      <div className="champion-bracket-inner womens">
                        {/* left col 2 */}
                        <div className="mw_women mW mW_34">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="championLeft"
                            handleClick={this.handleClick}
                            data={this.state.championLeft}
                            type={2}
                            round={1}
                            position={[1]}
                          />
                        </div>
                        <div className="mw_women mW mW_34-1"></div>
                        {/* lerightft col 2 */}
                        <div className="mw_women mW mW_35">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="championLeft"
                            handleClick={this.handleClick}
                            data={this.state.championLeft}
                            type={2}
                            round={1}
                            position={[2]}
                          />
                        </div>
                        <div className="mw_women mW mW_35-1"></div>
                        {/* center col */}
                        <div className="mw_women mW mW_36">
                          <div className="champion-text">Championship</div>
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="championLeft"
                            handleClick={this.handleClick}
                            data={this.state.championLeft}
                            type={2}
                            round={2}
                            position={[1, 2]}
                          />
                          <div
                            className="third-place-text"
                            style={{ textTransform: "none" }}
                          >
                            3rd Place
                          </div>
                        </div>
                        <div className="bracket-bottom-text challenge-bottom-text womens blue-bracket-bottom-text">
                          {/* 2nd Round Losers of Premier
                          <br />
                          Move to Champion */}
                          2nd Round Losers
                          <br />
                          of Premier Cup
                        </div>
                      </div>

                      <div
                        className="submit-bracket"
                        style={{ position: "unset", marginTop: "20px" }}
                      >
                        <input
                          type="button"
                          className="submit-style"
                          value="SAVE"
                          onClick={() => this.submitBracket(["championLeft"])}
                        />
                      </div>
                    </div>
                  </div>
                  {/* -------------------------survivor cup bracket ------------------------------*/}
                  <div className="brackets challenge-bracket-wrap">
                    <div className="challenge-head">BOWL</div>
                    <div className="challenge-bracket-outer">
                      <div className="challenge-bracket-inner">
                        {/* left col pair 1 */}
                        <div className="mW mW_27">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="survivorsLeft"
                            handleClick={this.handleClick}
                            data={this.state.survivorsLeft}
                            type={2}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* right col pair 1 */}
                        <div className="mW mW_28">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="survivorsRight"
                            handleClick={this.handleClick}
                            data={this.state.survivorsRight}
                            type={2}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* left col 2 */}
                        <div className="mW mW_29">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="survivorsCenter"
                            handleClick={this.handleClick}
                            data={this.state.survivorsCenter}
                            type={2}
                            round={2}
                            position={[1]}
                          />
                        </div>
                        {/* lerightft col 2 */}

                        <div className="mW mW_30">
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="survivorsCenter"
                            handleClick={this.handleClick}
                            data={this.state.survivorsCenter}
                            type={2}
                            round={2}
                            position={[2]}
                          />
                        </div>
                        {/* center col */}
                        <div className="mW mW_31">
                          <div className="champion-text">Championship</div>
                          <BracketBox
                            setScore={this.setScore}
                            edit="true"
                            stateKey="survivorsCenter"
                            handleClick={this.handleClick}
                            data={this.state.survivorsCenter}
                            type={2}
                            round={3}
                            position={[1, 2]}
                          />
                          <div
                            className="third-place-text"
                            style={{ textTransform: "none" }}
                          >
                            3rd Place
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ left: "0px" }}
                        className="bracket-bottom-text challenge-bottom-text blue-bracket-bottom-text"
                      >
                        {/* 1st Round Premier Losers Move
                        <br />
                        to Survivors Bracket */}
                        1st Round Losers
                        <br />
                        From Premier Cup
                      </div>
                      <div
                        className="submit-bracket"
                        style={{ position: "unset", marginTop: "20px" }}
                      >
                        <input
                          type="button"
                          className="submit-style"
                          value="SAVE"
                          onClick={() =>
                            this.submitBracket([
                              "survivorsLeft",
                              "survivorsRight",
                              "survivorsCenter",
                            ])
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/* -------------------------champion cup bracket ------------------------------*/}
                  <div className="women_champions_brackets">
                    <div
                      className="brackets champion-bracket-wrap womens"
                      id="challengeCup"
                    >
                      <div className="champion-head"> SHIELD</div>
                      <div className="champion-bracket-outer">
                        <div className="champion-bracket-inner womens">
                          {/* left col 2 */}
                          <div className="mw_women mW mW_34">
                            <BracketBox
                              setScore={this.setScore}
                              edit="true"
                              stateKey="challengeLeft"
                              handleClick={this.handleClick}
                              data={this.state.challengeLeft}
                              type={2}
                              round={1}
                              position={[1]}
                            />
                          </div>
                          <div className="mw_women mW mW_34-1"></div>
                          {/* lerightft col 2 */}
                          <div className="mw_women mW mW_35">
                            <BracketBox
                              setScore={this.setScore}
                              edit="true"
                              stateKey="challengeLeft"
                              handleClick={this.handleClick}
                              data={this.state.challengeLeft}
                              type={2}
                              round={1}
                              position={[2]}
                            />
                          </div>
                          <div className="mw_women mW mW_35-1"></div>
                          {/* center col */}
                          <div className="mw_women mW mW_36">
                            <div className="champion-text">Championship</div>
                            <BracketBox
                              setScore={this.setScore}
                              edit="true"
                              stateKey="challengeLeft"
                              handleClick={this.handleClick}
                              data={this.state.challengeLeft}
                              type={2}
                              round={2}
                              position={[1, 2]}
                            />
                            <div
                              className="third-place-text"
                              style={{ textTransform: "none" }}
                            >
                              3rd Place
                            </div>
                          </div>
                          <div className="bracket-bottom-text challenge-bottom-text womens blue-bracket-bottom-text">
                            {/* 1st Round Losers of Survivors
                            <br />
                            Move to Challenge */}
                            1st Round Losers
                            <br />
                            of Shield Bracket
                          </div>
                        </div>

                        <div
                          className="submit-bracket"
                          style={{ position: "unset", marginTop: "20px" }}
                        >
                          <input
                            type="button"
                            className="submit-style"
                            value="SAVE"
                            onClick={() =>
                              this.submitBracket(["challengeLeft"])
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Placeaddsm />
          </div>
        </div>
      </Aux>
    );
  }
}
export default withRouter(RugbyBracket);
