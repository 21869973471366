import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "../screens/includes/Navbar";
import { Link } from "react-router";
import { withRouter } from "../components/withRouter";
import Placeadd from "../components/Placeadd";
import { Row, Col, Container, Tabs, Tab } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeaddsm from "../components/Placeaddsm";
import TimerBanner from "../components/timer-banner";
import Bluebtn from "../components/Bluebtn";
import { GetData } from "../utils/apiRequestHandler";
import Loader from "../components/Loader";
// import { globalVariable } from "../utils/global";
import axios from "axios";
import { hydrate } from "react-dom";
import { Form, Modal, Button } from "react-bootstrap";
import MyGroupLogo from "../assets/images/my-group-logo.jpg";
import PublicIcon from "../assets/images/public-icon.jpg";
import UploadLogo from "../assets/images/logo-upload.jpg";
import CopyIcon from "../assets/images/copy-icon.jpg";
import CrossBtn from "../assets/images/cross-btn.png";
import MemberListTable from "../components/MemberListTable";
import LeaderBoardTable from "../components/LeaderBoardTable";
import CreateGroupModal from "../components/groupModal/CreateGroupModal";
import CreateBracketModal from "../components/groupModal/CreateBracketModal";
import DeleteGroup from "../components/groupModal/DeleteGroup";
import TransferModal from "../components/groupModal/TransferModal";

const men =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/men-tourna.png";
const women =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/women-tourna.png";
const loginclose =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/login-cross.svg";

class CreateBracketNew extends React.Component {
  constructor(props) {
    super(props);    
    this.state = {
      loader: false,
      currentBracket: "mens-bracket",
      isPartiallyFilledMens: false,
      isPartiallyFilledWomens: false,
      isBracketEditableMens: true,
      isBracketEditableWomens: true,
      removeButton: false,
      activeTabBracket:"tabBracket1",
      activeTabMyGroups:"tabMyGroup1",
      activeTabPublicGroups:"tabPublicGroup1",
      leaderMemberTableList:false,
      showModal: false,
      bracketNameModal: false,
      deleteGrpModal: false,
      transferOwner:false,
    };
    this._currentBracket = "mens-bracket";
   
  }
  createYourGroupModal = () => {
        this.setState({ showModal: !this.state.showModal });
  }
  
  yourBracketModal = () => {
    this.setState({ bracketNameModal: !this.state.bracketNameModal });
  }
  deleteModalOpen = (e)=>{
      e.preventDefault();
      this.setState({deleteGrpModal:true})
  }
  deleteModalClose = ()=>this.setState({deleteGrpModal:false});
  openTransferModal = ()=>this.setState({transferOwner:true, deleteGrpModal:false});
  closeTransferModal = ()=>this.setState({transferOwner:false,  deleteGrpModal:true});
  

  mensBracketTab1 = () => this.setState({activeTabBracket:"tabBracket1"});
  womensBracketTab2 = () => this.setState({activeTabBracket:"tabBracket2"});
  mensMyGroupTab1 = () => this.setState({activeTabMyGroups:"tabMyGroup1"});
  womensMyGroupTab2 = () => this.setState({activeTabMyGroups:"tabMyGroup2"});
  mensPublicGroupTab1 = () => this.setState({activeTabPublicGroups:"tabPublicGroup1"});
  womensPublicGroupTab2 = () => this.setState({activeTabPublicGroups:"tabPublicGroup2"});
  leaderBoardBtn = (e) => {
      e.preventDefault();
      this.setState({leaderMemberTableList:"leaderBoardList"})
    }
  memberListBtn = (e)=> {
      e.preventDefault();
      this.setState({leaderMemberTableList:"memberList"})
    }
  

  getBrackets = (t) => {
    this.setState({ loader: true });
    
    GetData("manage-user-bracket/bracketDetails/"+t).then((response) => {
      console.log("Responseeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", response);
      if (response.data.bracketDetails.length === 0) {
        this.setState({ removeButton: true });
      } else {
        this.setState({ removeButton: false });
      }
      this.setState({
        loader: false,
        isPartiallyFilledMens: response.data.isPartiallyFilledBracket.male,
        isPartiallyFilledWomens: response.data.isPartiallyFilledBracket.female,
        isBracketEditableMens: response.data.isBracketEditable,
      });
    });
  };

  closelogin = () => {
    this.setState({ importModal: false });
  };

  openBracketPage = () => {
    this.props.navigate("/" + this._currentBracket);
  };

  setBracketType = (type) => {
    this._currentBracket = type;
    this.getBrackets(type === 'mens-bracket' ? 'male' : 'female')
    this.setState({ currentBracket: type });
  };

  handleSelect = (key) => {
    if (key === "leaderboard") {
      this.props.navigate("/leader-board-main/mens");
    }
  };

  render() {
    return (
      <Aux>
        <Loader show={this.state.loader} />
        <Menu />        
        <div className="Maincontent invite-friend-wrap fillbracket">
          <div className="container-fluid">
            <Row>
              <Col md="12">
                <div className="leaderord headerAdvt">
                  <Placeadd />
                </div>
              </Col>
            </Row>
            <TimerBanner />
            <div className="invite-body-outer">
              <Container fluid>
                <Row>
                  <div className="col-12">
                    <Tabs
                      defaultActiveKey="profile"
                      transition={false}
                      onSelect={(e) => this.handleSelect(e)}
                    >
                    
                      <Tab className="tab1" eventKey="profile" title="MY BRACKETS">                        
                        <div className="createbracket">
                            <div className="my-brackets-wrap">
                                <div className="bracket-head-outer">
                                    <div className="my-bracket-head">My Brackets (<span>3/10</span>)</div>
                                    <div className="bracket-top-tab">
                                        <span onClick={this.mensBracketTab1} className={this.state.activeTabBracket === "tabBracket1" ? "active" : ""}>MEN'S</span>
                                        <span onClick={this.womensBracketTab2} className={this.state.activeTabBracket === "tabBracket2" ? "active" : ""}>WOMEN'S</span>
                                    </div>
                                </div>

                                {this.state.activeTabBracket === "tabBracket1"
                                    ?
                                    <div className="mens-container">
                                        {/* white box start */}
                                        <div className="white-box">
                                            <div className="white-box-inner">
                                                <div className="bracket-box-name">
                                                    <span className="name-inner">Bracket Name 1</span>
                                                    <span className="mens-circle">men's</span>
                                                </div>
                                                <div className="right-btns">
                                                    <Link to="#" className="viewbtn">View</Link>
                                                    <Link to="#" 
                                                    onClick={(e)=> {this.deleteModalOpen(e);}} 
                                                    className="deletebtn">Delete</Link>
                                                </div>
                                            </div>
                                        </div>
                                        {/* end */}
                                        <div className="white-box">
                                            <div className="white-box-inner">
                                                <div className="bracket-box-name">
                                                    <span className="name-inner">Bracket Name 3</span>
                                                    <span className="mens-circle">men's</span>
                                                </div>
                                                <div className="right-btns">
                                                    <Link to="#" className="viewbtn">View</Link>
                                                    <Link to="#" className="deletebtn">Delete</Link>
                                                </div>
                                            </div>
                                        </div>
                                        {/* end */}
                                    </div>
                                    :
                                    <div className="womens-container">
                                        <div className="white-box">
                                            <div className="white-box-inner">
                                                <div className="bracket-box-name">
                                                    <span className="name-inner">Bracket Name 2</span>
                                                    <span className="womens-circle">Women's</span>
                                                </div>
                                                <div className="right-btns">
                                                    <Link to="#" className="viewbtn">View</Link>
                                                    <Link to="#" className="deletebtn">Delete</Link>
                                                </div>
                                            </div>
                                        </div>
                                        {/* end */}
                                    </div>
                                }
                            </div>
                            <div className="inner-body">
                            <h1>
                              {this.state.isBracketEditableMens ? "Build" : ""}{" "}
                              Your Bracket
                            </h1>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lerem Ipsum has been the industry's standard dummy text every since the 1500s, when an unknown printer took a gallery of type and scrambled it to make a type specimen book.</p>
                            <h3>Select Tournament</h3>
                            <div className="gendertornament">
                              <div className="button1">
                                <button
                                  onClick={() =>
                                    this.setBracketType("mens-bracket")
                                  }
                                  className={
                                    this.state.currentBracket === "mens-bracket"
                                      ? "btn btn-mentournament active"
                                      : "btn btn-mentournament"
                                  }
                                >
                                  <img src={men} alt="deafult" />
                                  MEN’S Tournament
                                </button>
                              </div>
                              <div className="button2">
                                <button
                                  onClick={() =>
                                    this.setBracketType("womens-bracket")
                                  }
                                  className={
                                    this.state.currentBracket ===
                                    "womens-bracket"
                                      ? "btn btn-womentournament active"
                                      : "btn btn-womentournament"
                                  }
                                >
                                  <img src={women} alt="deafult" />
                                  WOMEN’S Tournament
                                </button>
                              </div>
                            </div>
                            <div className="startbtn">
                              {this.state.removeButton === true ? (
                                <div style={{ color: "red", fontSize: 20 }}>
                                  The bracket challenge is now closed.
                                </div>
                              ) : (
                                // <Bluebtn
                                //   value={
                                //     (this.state.isPartiallyFilledMens &&
                                //       this.state.currentBracket ===
                                //         "mens-bracket") ||
                                //     (this.state.isPartiallyFilledWomens &&
                                //       this.state.currentBracket ===
                                //         "womens-bracket")
                                //       ? this.state.isBracketEditableMens
                                //         ? "Edit"
                                //         : "View"
                                //       : this.state.isBracketEditableMens
                                //       ? "START CREATING BRACKET"
                                //       : "View"
                                //   }
                                //   type="submit"
                                //   onClick={this.openBracketPage}
                                // />
                                // <Bluebtn
                                //   value="START CREATING BRACKET"
                                //   type="submit"
                                //   onClick={this.openModalBracketName}
                                // />
                                <Bluebtn
                                  value="START CREATING BRACKET"
                                  type="submit"
                                  onClick={this.yourBracketModal}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </Tab>
                      <Tab eventKey="my-groups" title="MY GROUPS">
                        <div className="my-groups-wrap createbracket">
                            <div className="my-brackets-wrap">
                                <div className="bracket-head-outer">
                                    <div className="bracket-top-tab">
                                        <span onClick={this.mensMyGroupTab1} className={this.state.activeTabMyGroups === "tabMyGroup1" ? "active" : ""}>MEN'S</span>
                                        <span onClick={this.womensMyGroupTab2} className={this.state.activeTabMyGroups === "tabMyGroup2" ? "active" : ""}>WOMEN'S</span>
                                    </div>
                                </div>

                                {this.state.activeTabMyGroups === "tabMyGroup1"
                                    ?
                                    <div className="mens-container-my-group">
                                        {/* white box start */}
                                        <div className="white-box">
                                            <div className="white-box-inner">
                                                <div className="my-group-left">
                                                    <div className="group-left-inner">
                                                        <div className="mygroup-logo">
                                                            <img src={MyGroupLogo} alt="Logo" />
                                                        </div>
                                                        <div className="bracket-box-name">
                                                            <span className="name-inner">Sammy Group</span>
                                                            <div className="public-logo-wrap">
                                                                <span className="mens-circle">men's</span>
                                                                <div className="public-icon">
                                                                    <img src={PublicIcon} alt="Icon" />
                                                                    Public <span>(123)</span>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="group-text">
                                                                Lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right-btns">
                                                    <span className="mem-lead-group">
                                                        <Link to="#"
                                                            onClick={(e) => this.memberListBtn(e)}
                                                            className={
                                                                this.state.leaderMemberTableList === "memberList"
                                                                ?
                                                                "member-btn active-btn" 
                                                                :
                                                                "member-btn"                                                           
                                                        }
                                                        >Member List</Link>
                                                        <Link to="#"
                                                            onClick={(e) => this.leaderBoardBtn(e)}
                                                            className={
                                                                this.state.leaderMemberTableList === "leaderBoardList"
                                                                ?
                                                                "member-btn active-btn" 
                                                                :
                                                                "member-btn"
                                                        }
                                                        >Leaderboard</Link>
                                                        {/* <Link to="#" className="member-btn active-btn">Member List</Link>
                                                        <Link to="#" className="member-btn">Leaderboard</Link> */}
                                                    </span>
                                                    <span className="edit-dt-group">
                                                        <Link to="#" className="viewbtn">View</Link>
                                                        <Link to="#" className="deletebtn">Delete</Link>
                                                    </span>
                                                </div>
                                            </div>
                                            {
                                                this.state.leaderMemberTableList === "memberList"
                                                ?
                                                <MemberListTable/>
                                                :
                                                this.state.leaderMemberTableList === "leaderBoardList"
                                                ?
                                                <LeaderBoardTable/>
                                                :
                                                null
                                            }
                                            
                                            
                                        </div>
                                        {/* end */}
                                        {/* white box start */}
                                        <div className="white-box">
                                            <div className="white-box-inner">
                                                <div className="my-group-left">
                                                    <div className="group-left-inner">
                                                        <div className="mygroup-logo">
                                                            <img src={MyGroupLogo} alt="Logo" />
                                                        </div>
                                                        <div className="bracket-box-name">
                                                            <span className="name-inner">Sammy Group</span>
                                                            <div className="public-logo-wrap">
                                                                <span className="mens-circle">men's</span>
                                                                <div className="public-icon">
                                                                    <img src={PublicIcon} alt="Icon" />
                                                                    Public <span>(123)</span>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="group-text">
                                                                Lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right-btns">
                                                    <span className="mem-lead-group">
                                                        <Link to="#" className="member-btn">Member List</Link>
                                                        <Link to="#" className="member-btn active-btn">Leaderboard</Link>
                                                    </span>
                                                    <span className="edit-dt-group">
                                                        <Link to="#" className="deletebtn">Leave</Link>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* end */}
                                        <div className="white-box">
                                            <div className="white-box-inner">
                                                <div className="my-group-left">
                                                    <div className="group-left-inner">
                                                        <div className="mygroup-logo">
                                                            <img src={MyGroupLogo} alt="Logo" />
                                                        </div>
                                                        <div className="bracket-box-name">
                                                            <span className="name-inner">Serena Group</span>
                                                            <div className="public-logo-wrap">
                                                                <span className="mens-circle">men's</span>
                                                                <div className="public-icon">
                                                                    <img src={PublicIcon} alt="Icon" />
                                                                    Public <span>(75)</span>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="group-text">
                                                                Lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right-btns">
                                                    <span className="mem-lead-group">
                                                        <Link to="#" className="member-btn">Member List</Link>
                                                        <Link to="#" className="member-btn">Leaderboard</Link>
                                                    </span>
                                                    <span className="edit-dt-group">
                                                        <Link to="#" className="deletebtn">Leave</Link>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* end */}
                                    </div>
                                    :
                                    <div className="womens-container-my-group">
                                        <div className="white-box">
                                            <div className="white-box-inner">
                                                <div className="my-group-left">
                                                    <div className="group-left-inner">
                                                        <div className="mygroup-logo">
                                                            <img src={MyGroupLogo} alt="Logo" />
                                                        </div>
                                                        <div className="bracket-box-name">
                                                            <span className="name-inner">John Micheal Group</span>
                                                            <div className="public-logo-wrap">
                                                                <span className="womens-circle">Women's</span>
                                                                <div className="public-icon">
                                                                    <img src={PublicIcon} alt="Icon" />
                                                                    Public <span>(40)</span>
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="group-text">
                                                                Lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right-btns">
                                                    <span className="mem-lead-group">
                                                        <Link to="#" className="member-btn">Member List</Link>
                                                        <Link to="#" className="member-btn">Leaderboard</Link>
                                                    </span>
                                                    <span className="edit-dt-group">
                                                        <Link to="#" className="deletebtn">Leave</Link>
                                                    </span>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        {/* end */}
                                    </div>
                                }
                            </div>
                            <div className="inner-body">
                            <h1>
                              Create you group
                            </h1>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lerem Ipsum has been the industry's standard dummy text every since the 1500s, when an unknown printer took a gallery of type and scrambled it to make a type specimen book.</p>
                            <div className="startbtn">
                              {this.state.removeButton === true ? (
                                <div style={{ color: "red", fontSize: 20 }}>
                                  The bracket challenge is now closed.
                                </div>
                              ) : (
                                <Bluebtn
                                  value={"START CREATING GROUP"}
                                  type="submit"
                                  onClick={this.createYourGroupModal}
                                />
                              )}
                            </div>
                          </div>
                        
                        </div>
                      </Tab>
                      <Tab eventKey="public-groups" title="PUBLIC GROUPS">
                        <div className="public-groups-wrap createbracket">
                              <div className="my-brackets-wrap">
                                <div className="bracket-head-outer">
                                    <div className="my-bracket-head">PUBLIC GROUPS</div>
                                    <div className="bracket-top-tab">
                                        <span onClick={this.mensPublicGroupTab1} className={this.state.activeTabPublicGroups === "tabPublicGroup1" ? "active" : ""}>MEN'S</span>
                                        <span onClick={this.womensPublicGroupTab2} className={this.state.activeTabPublicGroups === "tabPublicGroup2" ? "active" : ""}>WOMEN'S</span>
                                    </div>
                                    <div className="search-box">
                                        <Form.Control type="text" className="search-group" placeholder="Search..." onBlur={(event) => { event.target.value = ''; }} />
                                        <button type="button" className="btn">
                                            <svg width="15px" height="15px">
                                                <path d="M11.618 9.897l4.224 4.212c.092.09.1.23.02.312l-1.464 1.46c-.08.08-.222.072-.314-.02L9.868 11.66M6.486 10.9c-2.42 0-4.38-1.955-4.38-4.367 0-2.413 1.96-4.37 4.38-4.37s4.38 1.957 4.38 4.37c0 2.412-1.96 4.368-4.38 4.368m0-10.834C2.904.066 0 2.96 0 6.533 0 10.105 2.904 13 6.486 13s6.487-2.895 6.487-6.467c0-3.572-2.905-6.467-6.487-6.467 "></path>
                                            </svg>
                                        </button>
                                        <div className="cross">
                                            <img src={CrossBtn} alt="cross" />
                                        </div>
                                    </div>
                                </div>

                                <div className="public-group-box-outer">
                                    {this.state.activeTabPublicGroups === "tabPublicGroup1"
                                        ?
                                        <div className="mens-container">
                                            {/* white box start */}
                                            <div className="white-box">
                                                <div className="white-box-inner">
                                                    <div className="my-group-left">
                                                        <div className="group-left-inner">
                                                            <div className="mygroup-logo">
                                                                <img src={MyGroupLogo} alt="Logo" />
                                                            </div>
                                                            <div className="bracket-box-name">
                                                                <span className="name-inner">Sammy Group</span>
                                                                <div className="public-logo-wrap">
                                                                    <span className="mens-circle">men's</span>
                                                                    <div className="public-icon">
                                                                        <img src={PublicIcon} alt="Icon" />
                                                                        Public <span>(123)</span>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="group-text">
                                                                    Lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right-btns">
                                                        <span className="copy-icon"><img src={CopyIcon} alt="copy" /></span>
                                                        <span className="mem-lead-group">
                                                            <Link to="#" className="member-btn">Join Group</Link>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* end */}
                                            <div className="white-box">
                                                <div className="white-box-inner">
                                                    <div className="my-group-left">
                                                        <div className="group-left-inner">
                                                            <div className="mygroup-logo">
                                                                <img src={MyGroupLogo} alt="Logo" />
                                                            </div>
                                                            <div className="bracket-box-name">
                                                                <span className="name-inner">JOHN MICHEAL Group</span>
                                                                <div className="public-logo-wrap">
                                                                    <span className="mens-circle">men's</span>
                                                                    <div className="public-icon">
                                                                        <img src={PublicIcon} alt="Icon" />
                                                                        Public <span>(123)</span>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="group-text">
                                                                    Lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right-btns">
                                                        <span className="copy-icon"><img src={CopyIcon} alt="copy" /></span>
                                                        <span className="mem-lead-group">
                                                            <Link to="#" className="member-btn">Join Group</Link>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* end */}
                                            <div className="white-box">
                                                <div className="white-box-inner">
                                                    <div className="my-group-left">
                                                        <div className="group-left-inner">
                                                            <div className="mygroup-logo">
                                                                <img src={MyGroupLogo} alt="Logo" />
                                                            </div>
                                                            <div className="bracket-box-name">
                                                                <span className="name-inner">SERENA Group</span>
                                                                <div className="public-logo-wrap">
                                                                    <span className="mens-circle">men's</span>
                                                                    <div className="public-icon">
                                                                        <img src={PublicIcon} alt="Icon" />
                                                                        Public <span>(123)</span>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="group-text">
                                                                    Lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right-btns">
                                                        <span className="copy-icon"><img src={CopyIcon} alt="copy" /></span>
                                                        <span className="mem-lead-group">
                                                            <Link to="#" className="member-btn">Join Group</Link>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* end */}
                                            <div className="white-box">
                                                <div className="white-box-inner">
                                                    <div className="my-group-left">
                                                        <div className="group-left-inner">
                                                            <div className="mygroup-logo">
                                                                <img src={MyGroupLogo} alt="Logo" />
                                                            </div>
                                                            <div className="bracket-box-name">
                                                                <span className="name-inner">Ricky GROUP</span>
                                                                <div className="public-logo-wrap">
                                                                    <span className="mens-circle">men's</span>
                                                                    <div className="public-icon">
                                                                        <img src={PublicIcon} alt="Icon" />
                                                                        Public <span>(123)</span>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="group-text">
                                                                    Lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right-btns">
                                                        <span className="copy-icon"><img src={CopyIcon} alt="copy" /></span>
                                                        <span className="mem-lead-group">
                                                            <Link to="#" className="member-btn">Join Group</Link>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* end */}
                                        </div>
                                        :
                                        <div className="womens-container">
                                            <div className="white-box">
                                                <div className="white-box-inner">
                                                    <div className="my-group-left">
                                                        <div className="group-left-inner">
                                                            <div className="mygroup-logo">
                                                                <img src={MyGroupLogo} alt="Logo" />
                                                            </div>
                                                            <div className="bracket-box-name">
                                                                <span className="name-inner">Ricky GROUP</span>
                                                                <div className="public-logo-wrap">
                                                                    <span className="womens-circle">women's</span>
                                                                    <div className="public-icon">
                                                                        <img src={PublicIcon} alt="Icon" />
                                                                        Public <span>(123)</span>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="group-text">
                                                                    Lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar consectetur lorem ispum sit amet dollar.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="right-btns">
                                                        <span className="copy-icon"><img src={CopyIcon} alt="copy" /></span>
                                                        <span className="mem-lead-group">
                                                            <Link to="#" className="member-btn">Join Group</Link>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* end */}
                                        </div>
                                    }
                                </div>

                                
                                    
                                
                                <div className="pagination-group">
                                    <ul>
                                        <li className="prev"><span>prev</span></li>
                                        <li className="active"><span>1</span></li>
                                        <li><span>2</span></li>
                                        <li><span>3</span></li>
                                        <li><span>4</span></li>
                                        <li><span>5</span></li>
                                        <li><span>6</span></li>
                                        <li><span>7</span></li>
                                        <li className="next"><span>next</span></li>
                                    </ul>
                                </div>
                              </div>
                        </div>
                      </Tab>
                    
                    </Tabs>
                  </div>
                </Row>
              </Container>
            </div>
            <Placeaddsm />
          </div>
        </div>
        
        {/* Create your group modal */}
        <CreateGroupModal
            show={this.state.showModal}
            hide={this.createYourGroupModal} 
            
        />
        {/* Bracket Name Modal */}
        <CreateBracketModal
            show={this.state.bracketNameModal}
            hide={this.yourBracketModal} 
            
        />
        {/* Delete Group */}
        <DeleteGroup
            show={this.state.deleteGrpModal}
            show2={this.openTransferModal}
            hide={this.deleteModalClose}
        />

        <TransferModal
            show={this.state.transferOwner}
            hide={this.closeTransferModal}
        />
        
        
      </Aux>
    );
  }
}
export default withRouter(CreateBracketNew);
