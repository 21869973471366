import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "./includes/Navbar";
import { Row, Col, Container } from "react-bootstrap";
import { withRouter } from "../components/withRouter";
import { Prompt, Redirect } from "react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeadd from "../components/Placeadd";
import Placeaddsm from "../components/Placeaddsm";
import TimerBanner from "../components/timer-banner";
import { GetData, PostData } from "../utils/apiRequestHandler";
import BracketBox from "../components/brackets/BracketBox";
import ConfirmationModal from "../components/groupModal/ConfirmationModal";
import Loader from "../components/Loader";
import Modal from "../components/Modal";
import { Link } from "react-router";
import { Button } from "react-bootstrap";

const logo = "https://ncrprodassets.s3.us-west-2.amazonaws.com/src_images/logo/logo.png";
const closeIcon = "https://d7le4ielk3ho1.cloudfront.net/src_images/login-cross.png";
const loginclose = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/login-cross.svg";

class RugbyBracket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      totalScore: "N/A",
      modalIsOpen: false,
      crossBtn: false,
      type: "input",
      partiallySaved: false,
      partiallyMens: false,
      tieBrackerModal: false,
      partiallyUpdate: false,
      addClass: false,
      addClass1: false,
      addClass2: false,
      addClass3: false,
      tabNumber: 1,
      tieBrackerScore: { team1: 0, team2: 0 },
      tieBrackerMatch: {},
      mensLeagueTopLeft: [],
      mensLeagueBottomLeft: [],
      mensLeagueTopRight: [],
      mensLeagueBottomRight: [],
      mensLeagueCenter: [],
      mensCenterText: "--",
      mensTopLeftText: "--",
      mensTopRightText: "--",
      mensBottomLeftText: "--",
      mensBottomRightText: "--",
      survivorsCenter: [],
      survivorsLeft: [],
      survivorsRight: [],
      challengeCenter: [],
      challengeLeft: [],
      challengeRight: [],
      championLeft: [],
      championRight: [],
      championCenter: [],
      bracketNotFilled: false,
      showPrompt: false,
      showPremierScroll: true,
      redirect: false,
      modalState: {
        show: false,
        hide: this.closeModal,
        modalMessage: 'You have unsaved changes, are you sure you want to leave?',
        modalTitle: 'Confirmation',
        modalAction: this.modalCallback,
        noButtonLink: this.closeModal,
        yesButtonName: "Yes",
        noButtonName: "Cancel",
      },
      tournamentStart: true
      
    };
    this.showHide = () => this.showHide.bind(this);
    this._userBracketId = "";
    this._popupButton = 1;
    this._send_bracket_mail = "yes";
    this.name = props?.match?.params?.name;
  }

  componentDidMount() {
    if (localStorage.tp !== "2" && 'a' !== 'a') {
      this.props.navigate("/");
    } else {
      document.title = "NCR Rugby | Rugby Women's Bracket";
      let tournamenttime = JSON.parse(localStorage.getItem("tournamenttime"));
      if (tournamenttime) {
        // this.setState({
        //   tournamentStart: tournamenttime.gameStarted
        // }, () => {
        //   this.getBrackets();
        //   this.getUserRank();
        // })
        this.getBrackets();
        // this.getUserRank();
      } else {
        this.getBrackets();
        // this.getUserRank();
      }
    }
  }

  closeModal = () => {
    this.setState({
      modalState: {
        show: false,
        hide: this.closeModal,
        modalMessage: 'What do you want to do?',
        modalTitle: 'Confirmation',
        modalAction: this.modalCallback,
        noButtonLink: this.closeModal,
        yesButtonName: "Save",
        noButtonName: "Leave",
      }
    })

    this.modalCallback();
  }

  hideModal = (redirect =false) => {
    this.setState({
      modalState: {
        show: false,
        hide: this.closeModal,
        modalMessage: 'What do you want to do?',
        modalTitle: 'Confirmation',
        modalAction: this.modalCallback,
        noButtonLink: this.closeModal,
        yesButtonName: "Save",
        noButtonName: "Leave",
      }
    })
    redirect && this.modalCallback();
  }

  openModal = (e) => {
    e.preventDefault();
    if (!this.state.tournamentStart) {
      this.setState({
        modalState: {
          show: true,
          hide: this.hideModal,
          modalMessage: 'What do you want to do?',
          modalTitle: 'Confirmation',
          modalAction: this.savebtnClick,
          noButtonLink: this.closeModal,
          yesButtonName: "Save",
          noButtonName: "Leave",
        }
      })
      // this.submitBracket(true);
    } else {
      this.modalCallback()
    }

  }

  modalCallback = () => {
    this.setState({
      redirect: true,
      modalState: {
        ...{ show: false },
      }
    })
  }

  getUserRank = () => {
    const formData = new FormData();

    formData.append("bracket_type", "female");
    let ub_id = this.props?.match?.params?.id;
    if( ub_id ) {
      formData.append("user_bracket_id", ub_id);
    }
    PostData("leaderboard/getUserRank", formData).then((response) => {
      if (response.status === true) {
        if (response.data.womensRank[0]) {
          this.setState({
            totalScore: response.data.womensRank[0].score
              ? response.data.womensRank[0].score
              : 0,
          });
        }
      } else {
        console.log("Error while fetching data");
      }
    });
  };

  getBrackets = () => {
    this.setState({ loader: true });
    GetData(`leaderboard/getTopperDetails?gender=female`).then((response) => {
      //console.log('Response',response)
      this.setState({ loader: false });
      if (response.status === true) {
        if(response.data["userBracketDetails"] && response.data["userBracketDetails"]["bracketDetails"] ) {
          response.data["bracketDetails"] =  response.data["userBracketDetails"]["bracketDetails"];
          response.data["loser_ids"] = response.data["userBracketDetails"]["loser_ids"];
          response.data.isBracketEditable = false;
        }
        if (response.data["bracketDetails"].length === 0) {
          this.setState({ bracketNotFilled: true });
          return;
        }

        this.setState({
          totalScore: response.data.score ? response.data.score : 'N/A',
          isEditable: response.data.isBracketEditable,
          partiallySaved: response.data.isBracketEditable
            ? response.data.isPartiallyFilledBracket.female
            : false,
          partiallyMens: response.data.isBracketEditable
            ? response.data.isPartiallyFilledBracket.male
            : false,
        });
        this._userBracketId = response.data.bracketDetails[0].user_bracket_id;
        const mensLeagueTopLeft = response.data.bracketDetails[0].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        if (response.data.isBracketEditable === false) {
          if (!mensLeagueTopLeft["games"][0].winner_id) {
            this.setState({ bracketNotFilled: true });
            return;
          }
        } else {
          this._send_bracket_mail = "no";
        }
        const mensLeagueTopRight =
          response.data.bracketDetails[0].brackets.find(
            (x) => x.bracket_position === "top_right"
          );
        const mensLeagueCenter = response.data.bracketDetails[0].brackets[2];

        this.setState({
          tieBrackerScore: {
            team1: mensLeagueCenter && mensLeagueCenter.games && mensLeagueCenter.games[0] && mensLeagueCenter.games[0].team1 && mensLeagueCenter.games[0].team1.team_1_score
              ? mensLeagueCenter.games[0].team1.team_1_score
              : 0,
            team2: mensLeagueCenter && mensLeagueCenter.games && mensLeagueCenter.games[0] && mensLeagueCenter.games[0].team2 && mensLeagueCenter.games[0].team2.team_2_score
              ? mensLeagueCenter.games[0].team2.team_2_score
              : 0,
          },
        });

        const survivorsLeft = response.data.bracketDetails[1].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const survivorsRight = response.data.bracketDetails[1].brackets.find(
          (x) => x.bracket_position === "top_right"
        );
        const survivorsCenter = response.data.bracketDetails[1].brackets[2];
        const challengeLeft = response.data.bracketDetails[2].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        const championLeft = response.data.bracketDetails[3].brackets.find(
          (x) => x.bracket_position === "top_left"
        );
        console.log("championLeft", championLeft);
        this.setState({
          mensLeagueTopLeft: mensLeagueTopLeft.games,
          mensLeagueTopRight: mensLeagueTopRight.games,
          mensLeagueCenter: mensLeagueCenter.games,
          survivorsLeft: survivorsLeft.games,
          survivorsRight: survivorsRight.games,
          survivorsCenter: survivorsCenter.games,
          challengeLeft: challengeLeft.games,
          championLeft: championLeft.games,
        });
      } else {
        console.log("Error while fetching data");
      }
    });
  };

  leavebtnClick = () => {
    this.setState({
      modalIsOpen: false,
      leavebtnvalue: null,
      savebtnvalue: null,
      modalMsg: "Please fill all brackets",
    });
  }

  savebtnClick= () => {
    this.submitBracket(true);
  }

  submitBracket = (save=false) => {
    let stateKeys = [
      "championLeft",
      "challengeLeft",
      "mensLeagueTopLeft",
      "mensLeagueTopRight",
      "mensLeagueCenter",
      "survivorsLeft",
      "survivorsRight",
      "survivorsCenter",
    ];
    let formDataArray = [];
    let error = 0;
    console.log("this.state.mensLeagueCenter", this.state.mensLeagueCenter);
    if (
      this.state.mensLeagueCenter[0].team1.team_id &&
      this.state.mensLeagueCenter[0].team2.team_id && !save
    ) {
      if (
        !this.state.mensLeagueCenter[0].winner_id ||
        !this.state.mensLeagueCenter[1].winner_id
      ) {
        let message =
          "Please select the winner for the Premier Cup 3rd place games.";
        if (!this.state.mensLeagueCenter[0].winner_id) {
          message =
            "Please select the winner for the Premier Cup championship.";
        }
        if (
          !this.state.mensLeagueCenter[0].winner_id &&
          !this.state.mensLeagueCenter[1].winner_id
        ) {
          message =
            "Please select the winner for the Premier Cup championship and 3rd place games.";
        }
        const elmnt = document.getElementById("premiorBracket");
        elmnt.scrollIntoView();
        this.setState({
          modalIsOpen: true,
          crossBtn: false,
          btn1value: "Back",
          btn2value: "",
          modalMsg: message,
        });
        return;
      }
    }

    if (
      this.state.survivorsCenter[2].team1.team_id &&
      this.state.survivorsCenter[2].team2.team_id && !save
    ) {
      if (
        !this.state.survivorsCenter[2].winner_id ||
        !this.state.survivorsCenter[3].winner_id
      ) {
        //survivorsCup

        let message =
          "Please select the winner for the Survivors Bowl 3rd place games.";
        if (!this.state.survivorsCenter[2].winner_id) {
          message =
            "Please select the winner for the Survivors Bowl championship.";
        }
        if (
          !this.state.survivorsCenter[2].winner_id &&
          !this.state.survivorsCenter[3].winner_id
        ) {
          message =
            "Please select the winner for the Survivors Bowl championship and 3rd place games.";
        }

        const elmnt = document.getElementById("survivorsCup");
        elmnt.scrollIntoView();
        this.setState({
          modalIsOpen: true,
          crossBtn: false,
          btn1value: "Back",
          btn2value: "",
          modalMsg: message,
        });
        return;
      }
    }

    if (
      this.state.challengeLeft[2].team1.team_id &&
      this.state.challengeLeft[2].team2.team_id && !save
    ) {
      if (
        !this.state.challengeLeft[2].winner_id ||
        !this.state.challengeLeft[3].winner_id
      ) {
        //challengeCup
        let message =
          "Please select the winner for the Challenge Bowl 3rd place games.";
        if (!this.state.challengeLeft[2].winner_id) {
          message =
            "Please select the winner for the Challenge Bowl championship.";
        }
        if (
          !this.state.challengeLeft[2].winner_id &&
          !this.state.challengeLeft[3].winner_id
        ) {
          message =
            "Please select the winner for the Challenge Bowl championship and 3rd place games.";
        }
        const elmnt = document.getElementById("challengeCup");
        elmnt.scrollIntoView();
        this.setState({
          modalIsOpen: true,
          crossBtn: false,
          btn1value: "Back",
          btn2value: "",
          modalMsg: message,
        });
        return;
      }
    }

    if (
      this.state.championLeft[2].team1.team_id &&
      this.state.championLeft[2].team2.team_id && !save
    ) {
      if (
        !this.state.championLeft[2].winner_id ||
        !this.state.championLeft[3].winner_id
      ) {
        //championCup
        let message =
          "Please select the winner for the Champion Plate 3rd place games.";
        if (!this.state.championLeft[2].winner_id) {
          message =
            "Please select the winner for the Champion Plate championship.";
        }
        if (
          !this.state.championLeft[2].winner_id &&
          !this.state.championLeft[3].winner_id
        ) {
          message =
            "Please select the winner for the Champion Plate championship and 3rd place games.";
        }
        const elmnt = document.getElementById("championCup");
        elmnt.scrollIntoView();
        this.setState({
          modalIsOpen: true,
          btn1value: "Back",
          crossBtn: false,
          btn2value: "",
          modalMsg: message,
        });
        return;
      }
    }

    stateKeys.map((key) => {
      let stateData = this.state[key];
      console.log("stateData", stateData);
      stateData.map((data) => {
        if (data.team1.team_id && data.team2.team_id) {
          formDataArray.push({
            user_bracket_id: this._userBracketId,
            game_id: data.game_id,
            team_1_id: data.team1.team_id,
            team_2_id: data.team2.team_id,
            winner_id: data.winner_id
              ? data.winner_id
              : data.winner
                ? data.winner
                : null,
            team_1_score: data.team1.score || data.team1.score === 0
              ? data.team1.score
              : data.team1.team_1_score,
            team_2_score: data.team2.score || data.team2.score === 0
              ? data.team2.score
              : data.team1.team_2_score,
          });
        } else {
          formDataArray.push({
            user_bracket_id: this._userBracketId,
            game_id: data.game_id,
            team_1_id: data.team1.team_id ? data.team1.team_id : null,
            team_2_id: data.team2.team_id ? data.team2.team_id : null,
            winner_id: data.winner_id
              ? data.winner_id
              : data.winner
                ? data.winner
                : null,
            team_1_score: data.team1.score || data.team1.score === 0
              ? data.team1.score
              : data.team1.team_1_score,
            team_2_score: data.team2.score || data.team2.score === 0
              ? data.team2.score
              : data.team1.team_2_score,
          });
          error = 1;
        }
      });
      return null;
    });

    if (error & !save) {
      this.setState({
        modalIsOpen: true,
        crossBtn: false,
        btn1value: null,
        btn2value: null,
        leavebtnvalue: 'Leave',
        savebtnvalue: 'Save',
        modalMsg: "Please fill all brackets",
      });
      return;
    }
    // return false;
    let formData = new FormData();
    formData.append("userBracketDetails", JSON.stringify(formDataArray));
    formData.append("send_bracket_mail", this._send_bracket_mail);
    formData.append("bracketType", "female");
    this.setState({ loader: true });
    PostData("manage-user-bracket/updateDetails", formData).then((response) => {
      this.getBrackets();
      this.setState({ 
        showPrompt: false,
        modalState: {
          ...{ show: false }
        }
      });
      
      this.leavebtnClick();
      setTimeout(() => {
        this.setState({
          partiallyUpdate: false,
          crossBtn: true,
          modalIsOpen: true,
          btn1value: "",
          btn2value: "",
          modalMsg: "Your bracket has been saved",
        });
      }, 800);
    });
  };

  removeDependencies = (dependents) => {
    dependents.map((d) => {
      const updateState = this.state[d.state];
      let indexKey;
      if (d.position) {
        indexKey = updateState.findIndex(
          (x) => x.round === d.round && x.position === d.position
        );
      } else {
        indexKey = updateState.findIndex((x) => x.round === d.round);
      }
      updateState[indexKey]["winner_id"] = "";
      updateState[indexKey]["winner"] = "";
      updateState[indexKey]["selected"] = "";
      d.teams.map((t) => {
        updateState[indexKey][t] = {};
        updateState[indexKey][t] = {};
        return null;
      });

      return null;
    });
  };

  updateAllStates = (currentState, match) => {
    console.log("updateAllStates", currentState);
    switch (currentState) {
      case "mensLeagueCenter":
        var dependents = [];
        if (match.round === 3) {
          dependents = [
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 1,
              teams: match.position === 1 ? ["team1"] : ["team2"],
            },
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: match.position === 1 ? ["team1"] : ["team2"],
            },
          ];
        }
        if (dependents.length) {
          this.removeDependencies(dependents);
        }
        break;
      case "mensLeagueTopLeft":
        var dependents = [];
        if (match.round === 1) {
          dependents = [
            {
              state: "mensLeagueTopLeft",
              round: 3,
              teams: match.position > 2 ? ["team2"] : ["team1"],
            },
            { state: "mensLeagueCenter", round: 4, teams: ["team1"] },
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team1"],
            },
            {
              state: "survivorsCenter",
              round: 2,
              position: 1,
              teams: match.position > 2 ? ["team2"] : ["team1"],
            },
            {
              state: "survivorsCenter",
              round: 3,
              position: 1,
              teams: ["team1"],
            },
            {
              state: "survivorsCenter",
              round: 3,
              position: 2,
              teams: ["team1"],
            },
            { state: "challengeLeft", round: 2, position: 1, teams: ["team1"] },
            { state: "challengeLeft", round: 2, position: 2, teams: ["team1"] },
            {
              state: "championLeft",
              round: 1,
              position: 1,
              teams: match.position < 3 ? ["team1"] : ["team2"],
            },
            { state: "championLeft", round: 2, position: 1, teams: ["team1"] },
            { state: "championLeft", round: 2, position: 2, teams: ["team1"] },
          ];
        }
        if (match.round === 2) {
          dependents = [
            { state: "mensLeagueCenter", round: 4, teams: ["team1"] },
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team1"],
            },
            {
              state: "championLeft",
              round: 1,
              position: 1,
              teams: match.position < 2 ? ["team1"] : ["team2"],
            },
            { state: "championLeft", round: 2, position: 1, teams: ["team1"] },
            { state: "championLeft", round: 2, position: 2, teams: ["team1"] },
          ];
        }
        if (dependents.length) {
          this.removeDependencies(dependents);
        }
        break;

      case "mensLeagueTopRight":
        var dependents = [];
        if (match.round === 1) {
          dependents = [
            {
              state: "mensLeagueTopRight",
              round: 3,
              teams: match.position > 2 ? ["team2"] : ["team1"],
            },
            { state: "mensLeagueCenter", round: 4, teams: ["team2"] },
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team2"],
            },
            {
              state: "survivorsCenter",
              round: 2,
              position: 2,
              teams: match.position > 2 ? ["team2"] : ["team1"],
            },
            {
              state: "survivorsCenter",
              round: 3,
              position: 1,
              teams: ["team2"],
            },
            {
              state: "survivorsCenter",
              round: 3,
              position: 2,
              teams: ["team2"],
            },
            { state: "challengeLeft", round: 2, position: 1, teams: ["team2"] },
            { state: "challengeLeft", round: 2, position: 2, teams: ["team2"] },
            {
              state: "championLeft",
              round: 1,
              position: 2,
              teams: match.position < 3 ? ["team1"] : ["team2"],
            },
            { state: "championLeft", round: 2, position: 1, teams: ["team2"] },
            { state: "championLeft", round: 2, position: 2, teams: ["team2"] },
          ];
        }
        if (match.round === 2) {
          dependents = [
            { state: "mensLeagueCenter", round: 4, teams: ["team2"] },
            {
              state: "mensLeagueCenter",
              round: 4,
              position: 2,
              teams: ["team2"],
            },
            {
              state: "championLeft",
              round: 1,
              position: 2,
              teams: match.position < 2 ? ["team1"] : ["team2"],
            },
            { state: "championLeft", round: 2, position: 1, teams: ["team2"] },
            { state: "championLeft", round: 2, position: 2, teams: ["team2"] },
          ];
        }
        if (dependents.length) {
          this.removeDependencies(dependents);
        }
        break;

      case "championRight":
        var dependents = [
          { state: "championCenter", round: 3, teams: ["team2"] },
          { state: "championCenter", round: 3, position: 2, teams: ["team2"] },
        ];
        this.removeDependencies(dependents);
        break;

      case "survivorsLeft":
        if (match.position === 1) {
          var dependents = [
            { state: "survivorsCenter", round: 3, teams: ["team1"] },
            {
              state: "survivorsCenter",
              round: 3,
              position: 2,
              teams: ["team1"],
            },
            {
              state: "survivorsCenter",
              round: 2,
              position: 1,
              teams: ["team1"],
            },
            { state: "challengeLeft", round: 1, position: 1, teams: ["team1"] },
            { state: "challengeLeft", round: 2, position: 1, teams: ["team1"] },
            { state: "challengeLeft", round: 2, position: 2, teams: ["team1"] },
          ];
        }
        if (match.position === 2) {
          var dependents = [
            { state: "survivorsCenter", round: 3, teams: ["team1"] },
            {
              state: "survivorsCenter",
              round: 3,
              position: 2,
              teams: ["team1"],
            },
            {
              state: "survivorsCenter",
              round: 2,
              position: 1,
              teams: ["team2"],
            },
            { state: "challengeLeft", round: 1, position: 1, teams: ["team2"] },
            { state: "challengeLeft", round: 2, position: 1, teams: ["team1"] },
            { state: "challengeLeft", round: 2, position: 2, teams: ["team1"] },
          ];
        }
        this.removeDependencies(dependents);
        break;

      case "survivorsRight":
        if (match.position === 1) {
          var dependents = [
            { state: "survivorsCenter", round: 3, teams: ["team2"] },
            {
              state: "survivorsCenter",
              round: 3,
              position: 2,
              teams: ["team2"],
            },
            {
              state: "survivorsCenter",
              round: 2,
              position: 2,
              teams: ["team1"],
            },
            { state: "challengeLeft", round: 1, position: 2, teams: ["team1"] },
            { state: "challengeLeft", round: 2, position: 1, teams: ["team2"] },
            { state: "challengeLeft", round: 2, position: 2, teams: ["team2"] },
          ];
        }
        if (match.position === 2) {
          var dependents = [
            { state: "survivorsCenter", round: 3, teams: ["team2"] },
            {
              state: "survivorsCenter",
              round: 3,
              position: 2,
              teams: ["team2"],
            },
            {
              state: "survivorsCenter",
              round: 2,
              position: 2,
              teams: ["team2"],
            },
            { state: "challengeLeft", round: 1, position: 2, teams: ["team2"] },
            { state: "challengeLeft", round: 2, position: 1, teams: ["team2"] },
            { state: "challengeLeft", round: 2, position: 2, teams: ["team2"] },
          ];
        }
        this.removeDependencies(dependents);
        break;

      case "survivorsCenter":
        if (match.round === 2) {
          var dependents = [
            {
              state: "survivorsCenter",
              round: 3,
              teams: match.position === 1 ? ["team1"] : ["team2"],
            },
            {
              state: "survivorsCenter",
              round: 3,
              position: 2,
              teams: match.position === 1 ? ["team1"] : ["team2"],
            },
          ];
          this.removeDependencies(dependents);
        }
        break;

      case "challengeLeft":
        if (match.round === 1) {
          var dependents = [
            {
              state: "challengeLeft",
              round: 2,
              position: 1,
              teams: match.position === 1 ? ["team1"] : ["team2"],
            },
            {
              state: "challengeLeft",
              round: 2,
              position: 2,
              teams: match.position === 1 ? ["team1"] : ["team2"],
            },
          ];
          this.removeDependencies(dependents);
        }
        break;

      case "championLeft":
        if (match.round === 1) {
          var dependents = [
            {
              state: "championLeft",
              round: 2,
              position: 1,
              teams: match.position === 1 ? ["team1"] : ["team2"],
            },
            {
              state: "championLeft",
              round: 2,
              position: 2,
              teams: match.position === 1 ? ["team1"] : ["team2"],
            },
          ];
          this.removeDependencies(dependents);
        }
        break;

      default:
        break;
    }
  };
  forceUpdate = () => {
    if (this._popupButton === 2) {
      this.props.navigate("mens-bracket");
      return;
    }
    this.handleClick(
      this._match,
      this._stateKey,
      this._team,
      this._losingTeam,
      this._index,
      true
    );
    this.setState({ modalIsOpen: false });
  };

  handleClick = (match, stateKey, team, losingTeam, cindex, force = false) => {
    if (
      (this.state.partiallySaved === true &&
        this.state.partiallyUpdate === false) ||
      this.state.isEditable === false
    ) {
      return;
    }

    if (!match.team1.team_id || !match.team2.team_id) {
      this.setState({
        modalIsOpen: true,
        crossBtn: false,
        btn1value: "Back",
        btn2value: "",
        modalMsg: "Please select a winner in the previous game.",
      });
      return;
    }

    this.updateAllStates(stateKey, match);
    if (!this.state.partiallySaved) {
      this.setState({ showPrompt: true });
    }
    if (this.state[stateKey][cindex]) {
      let currentStateData = this.state[stateKey][cindex];
      currentStateData["winner"] = team.team_id;
      currentStateData["winner_id"] = team.team_id;
      currentStateData["selected"] = team.team_id;
    }
    if (
      (stateKey === "challengeLeft" || stateKey === "championLeft") &&
      match.round === 2
    ) {
      let leagueData = this.state[stateKey];
      if (
        (leagueData[2].winner_id || leagueData[2].winner_id) &&
        (leagueData[3].winner_id || leagueData[3].winner_id) &&
        window.innerWidth < 700
      ) {
        this.scrollToTargetAdjusted("championCup");
      }
      this.setState({ [stateKey]: leagueData });
    }

    if (
      (stateKey === "challengeLeft" || stateKey === "championLeft") &&
      match.round === 1
    ) {
      let leagueData = this.state[stateKey];
      console.log("leagueData", leagueData);
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 2 && x.position === 1
      );
      const leagueIndexLosingTeam = leagueData.findIndex(
        (x) => x.round === 2 && x.position === 2
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
        leagueData[leagueIndexLosingTeam].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
        leagueData[leagueIndexLosingTeam].team2 = losingTeam;
      }

      this.setState({ [stateKey]: leagueData });
    }

    if (stateKey === "survivorsCenter" && match.round === 2) {
      let leagueData = this.state[stateKey];
      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 1
      );
      const leagueIndexLosingTeam = leagueData.findIndex(
        (x) => x.round === 3 && x.position === 2
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
        leagueData[leagueIndexLosingTeam].team1 = losingTeam;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
        leagueData[leagueIndexLosingTeam].team2 = losingTeam;
      }

      this.setState({ [stateKey]: leagueData });
      return;
    }
    if (stateKey === "survivorsCenter" && match.round === 3) {
      let leagueData = this.state[stateKey];
      if (
        (leagueData[2].winner_id || leagueData[2].winner_id) &&
        (leagueData[3].winner_id || leagueData[3].winner_id) &&
        window.innerWidth < 700
      ) {
        this.scrollToTargetAdjusted("challengeCup");
      }
      this.setState({ [stateKey]: leagueData });
    }

    if (stateKey === "survivorsLeft" || stateKey === "survivorsRight") {
      let challengeKey = "survivorsCenter";
      let leagueData = this.state[challengeKey];
      let position = 1;
      if (stateKey === "survivorsRight") {
        position = 2;
      }

      const leagueIndex = leagueData.findIndex(
        (x) => x.round === 2 && x.position === position
      );
      if (match.position % 2 !== 0) {
        leagueData[leagueIndex].team1 = team;
      }
      if (match.position % 2 === 0) {
        leagueData[leagueIndex].team2 = team;
      }

      if (match.round === 1) {
        let k = 0;
        let challengeKey = "challengeLeft";
        if (stateKey === "survivorsRight") {
          k = 1;
        }
        let challengeData = this.state[challengeKey];

        if (match.position % 2 !== 0) {
          challengeData[k]["team1"] = losingTeam;
        }
        if (match.position % 2 === 0) {
          challengeData[k]["team2"] = losingTeam;
        }
        this.setState({ [challengeKey]: challengeData });
      }

      this.setState({ survivorsCenter: leagueData });
      return;
    }

    if (stateKey === "mensLeagueCenter" && match.round === 4) {
      let leagueData = this.state[stateKey];
      leagueData[cindex]["winner_id"] = team.team_id;
      leagueData[cindex]["winner"] = team.team_id;
      leagueData[cindex]["selected"] = team.team_id;

      if (match.position === 1) {
        console.log("leagueData", leagueData);

        this.setState({
          tieBrackerModal: true,
          tieBrackerScore: {
            team1: leagueData[cindex].team1.team_1_score
              ? leagueData[cindex].team1.team_1_score
              : 0,
            team2: leagueData[cindex].team2.team_2_score
              ? leagueData[cindex].team2.team_2_score
              : 0,
          },
          tieBrackerMatch: match,
          tiebrackerError: "",
        });
      }
      if (
        (leagueData[0].winner_id || leagueData[0].winner_id) &&
        (leagueData[1].winner_id || leagueData[1].winner_id) &&
        window.innerWidth < 700
      ) {
        this.scrollToTargetAdjusted("survivorsCup");
      }
      this.setState({ [stateKey]: leagueData });
    }
    if (stateKey === "mensLeagueCenter" && match.round === 3) {
      let leagueData = this.state[stateKey];
      let stateDataKey = "mensLeagueTopLeft";
      if (match.position === 2) {
        stateDataKey = "mensLeagueTopRight";
      }
      this.state[stateDataKey][cindex]["winner"] = team.team_id;
      this.state[stateDataKey][cindex]["winner_id"] = team.team_id;
      this.state[stateDataKey][cindex]["selected"] = team.team_id;

      let position = 1;

      if (match.position % 2 !== 0) {
        leagueData[0]["team1"] = team;
        leagueData[1]["team1"] = losingTeam;
      }
      if (match.position % 2 === 0) {
        leagueData[0]["team2"] = team;
        leagueData[1]["team2"] = losingTeam;
      }

      if (
        (leagueData[0].winner_id || leagueData[0].winner_id) &&
        (leagueData[1].winner_id || leagueData[1].winner_id) &&
        window.innerWidth < 700
      ) {
        this.scrollToTargetAdjusted("survivorsCup");
      }

      this.setState({ [stateKey]: leagueData });
    }

    if (stateKey === "mensLeagueTopLeft" || stateKey === "mensLeagueTopRight") {
      let round = match.round + 1;
      let leagueData = this.state[stateKey];
      leagueData[cindex]["winner"] = team.team_id;
      leagueData[cindex]["winner_id"] = team.team_id;
      leagueData[cindex]["selected"] = team.team_id;
      let position = 1;
      if (match.position > 2) {
        position = 2;
      }
      if (stateKey === "mensLeagueTopRight" && round === 3) {
        position = 2;
      }
      let index = leagueData.findIndex(
        (x) => x.position === position && x.round === round
      );

      if (match.position % 2 !== 0) {
        leagueData[index]["team1"] = team;
      }
      if (match.position % 2 === 0) {
        leagueData[index]["team2"] = team;
      }
      leagueData[index]["winner"] = "";
      leagueData[index]["selected"] = "";
      leagueData[index]["winner_id"] = "";

      if (match.round === 1) {
        let survivorKey = "survivorsLeft";
        if (stateKey === "mensLeagueTopRight") {
          survivorKey = "survivorsRight";
        }
        let survivorData = this.state[survivorKey];
        let k = 0;
        if (match.position > 2) {
          k = 1;
        }
        if (match.position % 2 !== 0) {
          survivorData[k]["team1"] = losingTeam;
        }
        if (match.position % 2 === 0) {
          survivorData[k]["team2"] = losingTeam;
        }

        survivorData[k]["winner"] = "";
        survivorData[k]["selected"] = "";
        survivorData[k]["winner_id"] = "";
        this.setState({ [survivorKey]: survivorData });
      }

      if (match.round === 2) {
        let k = 0;
        let championKey = "championLeft";
        console.log("stateKey", stateKey);
        if (stateKey === "mensLeagueTopRight") {
          k = 1;
        }
        let championData = this.state[championKey];

        if (match.position % 2 !== 0) {
          championData[k]["team1"] = losingTeam;
        }
        if (match.position % 2 === 0) {
          championData[k]["team2"] = losingTeam;
        }

        championData[k]["winner"] = "";
        championData[k]["selected"] = "";
        championData[k]["winner_id"] = "";
        this.setState({ [championKey]: championData });
      }

      this.setState({ [stateKey]: leagueData });
    }
  };

  setAddClass = () => {
    this.setState({
      addClass: true,
      addClass1: false,
      addClass2: false,
      tabNumber: 1,
    });
  };
  setAddClass1 = () => {
    this.setState({
      addClass1: true,
      addClass: false,
      addClass2: false,
      tabNumber: 2,
    });
  };
  setAddClass2 = () => {
    this.setState({
      addClass2: true,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };
  setAddClass3 = () => {
    this.setState({
      addClass3: false,
      addClass1: false,
      addClass: false,
      tabNumber: 3,
    });
  };

  arrowClass = () => {
    if (this.state.tabNumber == 3) {
      this.setAddClass1();
    } else if (this.state.tabNumber == 2) {
      this.setAddClass();
    } else if (this.state.tabNumber == 1) {
      this.setAddClass3();
    } else {
    }
  };

  closelogin = () => {
    this.setState({ importModal: false });
  };

  gotomens = () => {
    if (this.state.partiallyMens && this.state.isEditable === true) {
      this._popupButton = 2;
      this.setState({
        modalIsOpen: true,
        crossBtn: false,
        btn1value: "Cancel",
        btn2value: "Proceed",
        modalMsg: "Would you like to update men's bracket?",
      });
    } else {
      this.props.navigate("mens-bracket");
    }
  };

  setTieBreakerScore = (e, team) => {
    this.setState({ tiebrackerError: "" });
    if (isNaN(e.target.value)) {
      return;
    }
    if (e.target.value.length > 3) {
      return;
    }
    let tieBreaker = this.state.tieBrackerScore;
    if (team === "team1") {
      tieBreaker["team_1_score"] =
        e.target.value === "" ? 0 : parseInt(e.target.value);
    } else {
      tieBreaker["team_2_score"] =
        e.target.value === "" ? 0 : parseInt(e.target.value);
    }
    tieBreaker[team] = e.target.value === "" ? 0 : parseInt(e.target.value);
    this.setState({ tieBrackerScore: tieBreaker });
  };

  saveTieBreakerScore = () => {
    let leagueData = this.state.mensLeagueCenter;

    if (
      this.state.tieBrackerScore["team2"] ===
      this.state.tieBrackerScore["team1"]
    ) {
      this.setState({ tiebrackerError: "Scores cannot be equal" });
      return;
    }

    if (
      (leagueData[0]["winner_id"] === leagueData[0]["team1"].team_id ||
        leagueData[0]["winner"] === leagueData[0]["team1"].team_id) &&
      this.state.tieBrackerScore["team2"] > this.state.tieBrackerScore["team1"]
    ) {
      this.setState({
        tiebrackerError: "Team 1 score should be greater than Team 2 score",
      });
      return;
    }
    if (
      (leagueData[0]["winner_id"] === leagueData[0]["team2"].team_id ||
        leagueData[0]["winner"] === leagueData[0]["team2"].team_id) &&
      this.state.tieBrackerScore["team1"] > this.state.tieBrackerScore["team2"]
    ) {
      this.setState({
        tiebrackerError: "Team 2 score should be greater than Team 1 score",
      });
      return;
    }

    leagueData[0]["team1"]["score"] = this.state.tieBrackerScore["team1"];
    leagueData[0]["team2"]["score"] = this.state.tieBrackerScore["team2"];
    this.setState({ mensLeagueCenter: leagueData, tieBrackerModal: false });
  };

  selectBracket = (e) => {
    this.scrollToTargetAdjusted(e.target.value);
  };

  scrollToTargetAdjusted = (id) => {
    let offset = -600;
    var element = document.getElementById(id);
    var headerOffset = offset;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    return (

      <Aux>
        <React.Fragment>
          {/* <Prompt
            when={this.state.showPrompt}
            message="You have unsaved changes, are you sure you want to leave?"
          /> */}
        </React.Fragment>
        <Loader show={this.state.loader} />
        {/* <Menu /> */}
        <div className="Maincontent leader-board-wrap" style={{'paddingTop': '0px'}}>
          <div className="container-fluid">
            {/* <Row>
              <Col md="12">
                <div className="leaderord headerAdvt">
                  <Placeadd />
                </div>
              </Col>
            </Row>
            <TimerBanner />
            <div className="bracket-name-style-inner">
              {this.name}
              <span onClick={this.openModal} className="close-bkt-name"><Link to="#"><img src={loginclose} alt="close" /></Link></span>
            </div> */}
            {/* <div className="leader-board-toplinks">
              <Container>
                <Row>
                  <div className="col-12">
                    <ul>
                      
                      <li>
                        
                        <div className="premier-links active">
                          <img
                            src="https://d7le4ielk3ho1.cloudfront.net/public_images/women-icon.png"
                            className="premier-icon"
                          />{" "}
                          WOMEN’S PREMIER
                        </div>
                      </li>
                    </ul>
                  </div>
                </Row>
              </Container>
            </div> */}
            <div className="invite-body-outer">
              {this.state.bracketNotFilled === true ? (
                <div className="bracket-error-message">
                  <img src={logo} alt="logo" />
                  <div className="message">
                    You have not participated in this tournament
                  </div>
                  <Link to="/womens-score">
                    Click here to check the tournament score
                  </Link>
                </div>
              ) : (
                <div className="rugby-bracket-wrap">
                  <h1>
                    Total Women’s Tournament Points:
                    <span>{this.state.totalScore}</span>
                    {/* <Link to="/create-brackets/leaderboard/womens">view details</Link> */}
                  </h1>
                  <div className="tournament-selector">
                    <label htmlFor="tournament-view">Choose bracket</label>
                    <select
                      id="tournament-view"
                      className="form-control"
                      onChange={this.selectBracket}
                    >
                      <option value="premiorBracket">Premier Cup</option>
                      <option value="survivorsCup">Survivor Bowl</option>
                      <option value="challengeCup">Challenge Bowl</option>
                      <option value="championCup">Champion Plate</option>
                    </select>
                  </div>

                  <div className="bracket-wrapper-inner">
                    <div
                      className={`${"brackets hbreakers m_women"} 
                                   ${this.state.addClass ? "hbreakers-1" : ""}
                                   ${this.state.addClass1 ? "hbreakers-2" : ""}
                                   ${this.state.addClass2 ? "hbreakers-3" : ""} 
                                   ${this.state.showPremierScroll === true
                          ? "scrollableBracket"
                          : ""
                        }
                                   `}
                    >
                      <ul
                        className={`
                                    ${this.state.addClass
                            ? "bracket-rouds-wrap secRd-left"
                            : "bracket-rouds-wrap"
                          }
                                    ${this.state.addClass1 ? "secRd-left1" : ""}
                                    ${this.state.addClass2 ? "secRd-left2" : ""}
                                
                                `}
                      >
                        <li
                          className={`
                                        ${this.state.addClass
                              ? "round-tab arrow-left shift-arr1"
                              : "round-tab arrow-left"
                            }
                                        ${this.state.addClass1
                              ? "shift-arr2"
                              : ""
                            }
                                        ${this.state.addClass2
                              ? "shift-arr3"
                              : ""
                            }
                                    
                                    `}
                        >
                          <span
                            onClick={
                              this.state.showPremierScroll === true
                                ? ""
                                : this.arrowClass
                            }
                          ></span>
                        </li>
                        <li className="round-tab r_1">
                          <span className="r_name">1st ROUND</span>
                          <span className="r_date">May 28</span>
                        </li>
                        <li
                          className="round-tab r_3"
                          onClick={
                            this.state.showPremierScroll === true
                              ? ""
                              : this.setAddClass
                          }
                        >
                          <span className="r_name">QUARTERFINALS</span>
                          <span className="r_date">May 28</span>
                        </li>
                        <li
                          className="round-tab r_4"
                          onClick={
                            this.state.showPremierScroll === true
                              ? ""
                              : this.setAddClass1
                          }
                        >
                          <span className="r_name">SEMI FINALS</span>
                          <span className="r_date">May 29</span>
                        </li>
                        <li
                          className="round-tab championship"
                          onClick={this.state.showPremierScroll === true
                            ? ""
                            : this.setAddClass1}
                        >
                          <span className="r_name">Championship</span>
                          <span className="r_date">May 29</span>
                        </li>
                        <li className="round-tab r_4 right">
                          <span className="r_name">SEMI FINALS</span>
                          <span className="r_date">May 29</span>
                        </li>
                        <li className="round-tab r_3 right">
                          <span className="r_name">QUARTERFINALS</span>
                          <span className="r_date">May 28</span>
                        </li>
                        <li className="round-tab r_1">
                          <span className="r_name">1st ROUND</span>
                          <span className="r_date">May 28</span>
                        </li>
                      </ul>
                      <div className="bracket-challenge-head">
                        {
                          !this.state.tournamentStart ? this.state.isEditable ? (
                            this.state.partiallySaved ? (
                              <div
                                className="submit-bracket"
                                style={{ position: "unset", marginTop: "20px" }}
                              >
                                <input
                                  type="button"
                                  className="submit-style"
                                  value={
                                    this.state.partiallyUpdate === false
                                      ? "Edit Bracket"
                                      : "Update Bracket"
                                  }
                                  onClick={() =>
                                    this.state.partiallyUpdate === false
                                      ? this.setState({ partiallyUpdate: true })
                                      : this.submitBracket()
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className="submit-bracket"
                                style={{ position: "unset", marginTop: "20px" }}
                              >
                                <input
                                  type="button"
                                  className="submit-style"
                                  value="SUBMIT BRACKET"
                                  onClick={() => this.submitBracket()}
                                />
                              </div>
                            )
                          ) : (
                            ""
                          ) : ''
                        }

                      </div>

                      <div
                        className="premier-cup-head premier-women-head"
                        id="premiorBracket"
                      >
                        PREMIER
                        <br />
                        CUP BRACKET
                      </div>

                      <div
                        className={`
                                    ${this.state.addClass
                            ? "womens pairs-wrap sec-pair"
                            : "womens pairs-wrap"
                          }
                                    ${this.state.addClass1 ? "sec-pair1" : ""}
                                    ${this.state.addClass2 ? "sec-pair2" : ""}
                                `}
                      >
                        {/* <div className="pairs-wrap"> */}
                        {/* first pair */}
                        <div className="mW mW_1 mw_women">
                          <BracketBox
                            stateKey="mensLeagueTopLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* second pair */}
                        <div className="mW mW_2 mw_women">
                          <BracketBox
                            stateKey="mensLeagueTopLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            round={1}
                            position={[3, 4]}
                          />
                        </div>
                        
                        {/* 5th pair */}
                        <div className="mW mW_5 mw_women">
                          <BracketBox
                            stateKey="mensLeagueTopRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            round={1}
                            position={[1, 2]}
                          />
                        </div>
                        {/* 6th pair */}
                        <div className="mW mW_6 mw_women">
                        
                          <BracketBox
                            stateKey="mensLeagueTopRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            round={1}
                            position={[3, 4]}
                          />
                        </div>

                        {/* 9th pair */}
                        <div
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_9 mw_women m9Sec"
                              : "mW mW_9 mw_women"
                            }
                                    ${this.state.addClass1 ? "m9Sec" : ""}
                                    ${this.state.addClass2 ? "m9Sec" : ""}
                                `}
                        >
                          
                          <BracketBox
                            stateKey="mensLeagueTopLeft"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            type={2}
                            round={2}
                            position={[1, 2]}
                          />
                        </div>
                        <div
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_9-1 mw_women m9Sec"
                              : "mW mW_9-1 mw_women"
                            }
                                    ${this.state.addClass1 ? "m9Sec-1" : ""}
                                    ${this.state.addClass2 ? "m9Sec-1" : ""}
                                `}
                        ></div>
                        
                        {/* 11th pair */}
                        <div
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_11 mw_women m11Sec"
                              : "mW mW_11 mw_women"
                            }
                                    ${this.state.addClass1 ? "m11Sec" : ""}
                                    ${this.state.addClass2 ? "m11Sec" : ""}
                                `}
                        >
                          
                          <BracketBox
                            stateKey="mensLeagueTopRight"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            type={2}
                            round={2}
                            position={[1, 2]}
                          />
                        </div>
                        <div className="mW_11-1 mW"></div>
                        {/* 15th pair */}
                        <div
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_15 mw_women m15Sec"
                              : "mW mW_15 mw_women"
                            }
                                    ${this.state.addClass1 ? "m15Sec1" : ""}
                                    ${this.state.addClass2 ? "m15Sec2" : ""}
                                `}
                        >
                          <div
                            className={`
                                        ${this.state.addClass
                                ? "regions-left regTop"
                                : "regions-left"
                              }
                                        ${this.state.addClass1 ? "regTop1" : ""}
                                    `}
                          >
                            {'West'}
                          </div>
                          <BracketBox
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopLeft}
                            type={2}
                            round={3}
                            position={[1]}
                          />
                        </div>
                        {/* 16th pair */}
                        <div
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_16 m16Sec mw_women"
                              : "mW mW_16 mw_women"
                            }
                                    ${this.state.addClass1 ? "m16Sec1" : ""}
                                    ${this.state.addClass2 ? "m16Sec2" : ""}
                                `}
                        >
                          <div
                            className={`
                                        ${this.state.addClass
                                ? "regions-right regTop"
                                : "regions-right"
                              }
                                        ${this.state.addClass1 ? "regTop1" : ""}
                                    `}
                          >
                            {'East'}
                          </div>
                          <BracketBox
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueTopRight}
                            type={2}
                            round={3}
                            position={[2]}
                          />
                        </div>
                        {/* 17th pair */}
                        <div
                          className={`
                                    ${this.state.addClass
                              ? "mW mW_17 m17Sec mw_women"
                              : "mW mW_17 mw_women"
                            }
                                    ${this.state.addClass1 ? "m17Sec1" : ""}
                                    ${this.state.addClass2 ? "m17Sec2" : ""}
                                `}
                        >
                          <div
                            className={
                              parseInt(this.state.tieBrackerScore.team1) +
                                parseInt(this.state.tieBrackerScore.team2) >
                                0
                                ? "nat-champion-text active"
                                : "nat-champion-text"
                            }
                          >
                            National Championship
                            {parseInt(this.state.tieBrackerScore.team1) +
                              parseInt(this.state.tieBrackerScore.team2) >
                              0 ? (
                              <div>
                                Total Points:
                                {parseInt(this.state.tieBrackerScore.team1) +
                                  parseInt(this.state.tieBrackerScore.team2)}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <BracketBox
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueCenter}
                            type={2}
                            round={4}
                            position={[1]}
                          />
                          <BracketBox
                            stateKey="mensLeagueCenter"
                            handleClick={this.handleClick}
                            data={this.state.mensLeagueCenter}
                            type={2}
                            round={4}
                            position={[2]}
                          />
                          <div
                            className="third-place-text"
                            style={{ textTransform: "none" }}
                          >
                            3rd Place
                          </div>
                        </div>
                      </div>
                      {/* pairs end */}
                      <div
                        className="logo-brackets-bottom"
                        style={{ bottom: "-10%" }}
                      >
                        <img src="/assets/images/logo-brackets.png" alt="Bracket challenge" />
                      </div>
                    </div>

                    {/* -------------------------survivor cup bracket ------------------------------*/}
                    <div
                      className="brackets challenge-bracket-wrap"
                      id="survivorsCup"
                    >
                      <div className="challenge-head">
                        SURVIVORS BOWL BRACKET
                      </div>
                      <div className="challenge-bracket-outer">
                        <div className="challenge-bracket-inner">
                          {/* left col pair 1 */}
                          <div className="mW mW_27">
                            <BracketBox
                              stateKey="survivorsLeft"
                              handleClick={this.handleClick}
                              data={this.state.survivorsLeft}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* right col pair 1 */}
                          <div className="mW mW_28">
                            <BracketBox
                              stateKey="survivorsRight"
                              handleClick={this.handleClick}
                              data={this.state.survivorsRight}
                              type={2}
                              round={1}
                              position={[1, 2]}
                            />
                          </div>
                          {/* left col 2 */}
                          <div className="mW mW_29">
                            <BracketBox
                              stateKey="survivorsCenter"
                              handleClick={this.handleClick}
                              data={this.state.survivorsCenter}
                              type={2}
                              round={2}
                              position={[1]}
                            />
                          </div>
                          {/* lerightft col 2 */}
                          <div className="mW mW_30">
                            <BracketBox
                              stateKey="survivorsCenter"
                              handleClick={this.handleClick}
                              data={this.state.survivorsCenter}
                              type={2}
                              round={2}
                              position={[2]}
                            />
                          </div>
                          {/* center col */}
                          <div className="mW mW_31">
                            <div className="champion-text">Championship</div>
                            <BracketBox
                              stateKey="survivorsCenter"
                              handleClick={this.handleClick}
                              data={this.state.survivorsCenter}
                              type={2}
                              round={3}
                              position={[1, 2]}
                            />
                            <div
                              className="third-place-text"
                              style={{ textTransform: "none" }}
                            >
                              3rd Place
                            </div>
                          </div>
                        </div>
                        <div
                          style={{ left: "0px" }}
                          className="bracket-bottom-text challenge-bottom-text"
                        >
                          1st round losers from
                          <br />
                          Premier Cup
                        </div>
                      </div>
                    </div>
                    {/* -------------------------champion cup bracket ------------------------------*/}
                    <div className="women_champions_brackets">
                      <div
                        className="brackets champion-bracket-wrap womens"
                        id="challengeCup"
                      >
                        <div className="champion-head">
                          CHALLENGE  SHIELD
                        </div>
                        <div className="champion-bracket-outer">
                          <div className="champion-bracket-inner womens">
                            {/* left col 2 */}
                            <div className="mw_women mW mW_34">
                              <BracketBox
                                stateKey="challengeLeft"
                                handleClick={this.handleClick}
                                data={this.state.challengeLeft}
                                type={2}
                                round={1}
                                position={[1]}
                              />
                            </div>
                            <div className="mw_women mW mW_34-1"></div>
                            {/* lerightft col 2 */}
                            <div className="mw_women mW mW_35">
                              <BracketBox
                                stateKey="challengeLeft"
                                handleClick={this.handleClick}
                                data={this.state.challengeLeft}
                                type={2}
                                round={1}
                                position={[2]}
                              />
                            </div>
                            <div className="mw_women mW mW_35-1"></div>
                            {/* center col */}
                            <div className="mw_women mW mW_36">
                              <div className="champion-text">Championship</div>
                              <BracketBox
                                stateKey="challengeLeft"
                                handleClick={this.handleClick}
                                data={this.state.challengeLeft}
                                type={2}
                                round={2}
                                position={[1, 2]}
                              />
                              <div
                                className="third-place-text"
                                style={{ textTransform: "none" }}
                              >
                                3rd Place
                              </div>
                            </div>
                            <div className="bracket-bottom-text womens challenge-bottom-text">
                              1st round losers of
                              <br />
                              Survivor Bowl
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* -------------------------champion cup bracket ------------------------------*/}
                      <div
                        className="brackets champion-bracket-wrap womens"
                        id="championCup"
                      >
                        <div className="champion-head">
                          CONTENDER PLATE
                        </div>
                        <div className="champion-bracket-outer">
                          <div className="champion-bracket-inner womens">
                            {/* left col 2 */}
                            <div className="mw_women mW mW_34">
                              <BracketBox
                                stateKey="championLeft"
                                handleClick={this.handleClick}
                                data={this.state.championLeft}
                                type={2}
                                round={1}
                                position={[1]}
                              />
                            </div>
                            <div className="mw_women mW mW_34-1"></div>
                            {/* lerightft col 2 */}
                            <div className="mw_women mW mW_35">
                              <BracketBox
                                stateKey="championLeft"
                                handleClick={this.handleClick}
                                data={this.state.championLeft}
                                type={2}
                                round={1}
                                position={[2]}
                              />
                            </div>
                            <div className="mw_women mW mW_35-1"></div>
                            {/* center col */}
                            <div className="mw_women mW mW_36">
                              <div className="champion-text">Championship</div>
                              <BracketBox
                                stateKey="championLeft"
                                handleClick={this.handleClick}
                                data={this.state.championLeft}
                                type={2}
                                round={2}
                                position={[1, 2]}
                              />
                              <div
                                className="third-place-text"
                                style={{ textTransform: "none" }}
                              >
                                3rd Place
                              </div>
                            </div>
                            <div className="bracket-bottom-text challenge-bottom-text womens">
                              2nd round losers of
                              <br />
                              Premier Cup
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="submit-bracket-2"
                      style={{ position: "unset", marginTop: "20px" }}
                    >
                      {
                        !this.state.tournamentStart ? this.state.isEditable ? (
                          this.state.partiallySaved ? (
                            <div
                              className="submit-bracket"
                              style={{ position: "unset", marginTop: "20px" }}
                            >
                              <input
                                type="button"
                                className="submit-style"
                                value={
                                  this.state.partiallyUpdate === false
                                    ? "Edit Bracket"
                                    : "Update Bracket"
                                }
                                onClick={() =>
                                  this.state.partiallyUpdate === false
                                    ? this.setState({ partiallyUpdate: true })
                                    : this.submitBracket()
                                }
                              />
                            </div>
                          ) : (
                            <div
                              className="submit-bracket"
                              style={{ position: "unset", marginTop: "20px" }}
                            >
                              <input
                                type="button"
                                className="submit-style"
                                value="SUBMIT BRACKET"
                                onClick={() => this.submitBracket()}
                              />
                            </div>
                          )
                        ) : (
                          ""
                        ) : ''
                      }

                    </div>
                  </div>
                </div>
              )}
            </div>
            <Placeaddsm />
            <div
              className="modal fade WelcomeModal"
              style={{
                display: `${this.state.tieBrackerModal ? "block" : "none"}`,
                opacity: "1",
              }}
              id="WelcomeModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="rugby-tie-bracker">
                <div
                  className="modal-dialog modal-dialog-centered"
                  style={{ width: "320px" }}
                  width="350"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <Button
                        style={{ display: "none" }}
                        className="btn closebtn"
                        onClick={() =>
                          this.setState({ tieBrackerModal: false })
                        }
                      >
                        <img src={closeIcon} alt="deafult" />
                      </Button>
                    </div>
                    <div className="modal-body ">
                      <div className="tie-title">
                        Tie Breaker
                        <div className="tie-subtitle">Pick the final score</div>
                      </div>
                      <div className="tie-title" style={{ marginTop: "10px" }}>
                        Total :{" "}
                        {parseInt(this.state.tieBrackerScore.team1) +
                          parseInt(this.state.tieBrackerScore.team2)}
                      </div>

                      <div
                        className="team-tiles"
                        style={
                          this.state.tieBrackerMatch.team2
                            ? this.state.tieBrackerMatch.winner_id ===
                              this.state.tieBrackerMatch.team2.team_id ||
                              this.state.tieBrackerMatch.winner ===
                              this.state.tieBrackerMatch.team2.team_id
                              ? { flexDirection: "column-reverse" }
                              : {}
                            : {}
                        }
                      >
                        <div className="tile" style={{ marginBottom: "10px" }}>
                          <div className="tile-box">
                            <div className="flag">
                              {this.state.tieBrackerMatch.team1
                                ? this.state.tieBrackerMatch.team1.name
                                : ""}
                            </div>
                            <input
                              type="text"
                              className="score"
                              onChange={(e) =>
                                this.setTieBreakerScore(e, "team1")
                              }
                              value={this.state.tieBrackerScore.team1}
                            />
                          </div>
                          <div className="tile-text">
                            {this.state.tieBrackerMatch.team1
                              ? this.state.tieBrackerMatch.winner_id ===
                                this.state.tieBrackerMatch.team1.team_id ||
                                this.state.tieBrackerMatch.winner ===
                                this.state.tieBrackerMatch.team1.team_id
                                ? "WINNER"
                                : "LOSER"
                              : "LOSER"}
                          </div>
                        </div>
                        <div className="tile" style={{ marginBottom: "10px" }}>
                          <div className="tile-box">
                            <div className="flag">
                              {this.state.tieBrackerMatch.team2
                                ? this.state.tieBrackerMatch.team2.name
                                : ""}
                            </div>
                            <input
                              type="text"
                              className="score"
                              onChange={(e) =>
                                this.setTieBreakerScore(e, "team2")
                              }
                              value={this.state.tieBrackerScore.team2}
                            />
                          </div>
                          <div className="tile-text">
                            {this.state.tieBrackerMatch.team2
                              ? this.state.tieBrackerMatch.winner_id ===
                                this.state.tieBrackerMatch.team2.team_id ||
                                this.state.tieBrackerMatch.winner ===
                                this.state.tieBrackerMatch.team2.team_id
                                ? "WINNER"
                                : "LOSER"
                              : "LOSER"}
                          </div>
                        </div>
                      </div>
                      <div className="error-message">
                        {this.state.tiebrackerError}
                      </div>
                      <div
                        className="submit-bracket-2"
                        style={{ position: "unset", marginTop: "20px" }}
                      >
                        <input
                          style={{ width: "100px" }}
                          type="button"
                          className="submit-style"
                          value="Save"
                          onClick={() => this.saveTieBreakerScore()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              imgsrc={logo}
              toggle={() => {
                  this.setState({ modalIsOpen: false }) 
                  if( this.state.crossBtn ) {
                    this.modalCallback();
                  }
              }}
              showModal={this.state.modalIsOpen}
              title="WOMEN’S PREMIER CUP"
              className="rugby-mens-bracket"
              bodytext={this.state.modalMsg}
              btn1value={this.state.btn1value}
              btn2value={this.state.btn2value}
              button1Click={() => this.setState({ modalIsOpen: false })}
              button2Click={this.forceUpdate}

              savebtnvalue={this.state.savebtnvalue}
              savebtnClick={this.savebtnClick}

              leavebtnvalue={this.state.leavebtnvalue}
              leavebtnClick={this.modalCallback}
            />
            <ConfirmationModal
              {...this.state.modalState}
            />
          </div>
        </div>
      </Aux>
    );
  }
}
export default withRouter(RugbyBracket);
