import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router";
import { Table } from "react-bootstrap";
const loginclose = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/login-cross.svg";
const tieloginclose = "https://d7le4ielk3ho1.cloudfront.net/src_images/logo/tie-break-cross.svg";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tiebreaker: false,
      invitefrend: false,
    };
  }
  dismissModal = () => {
    this.props.toggle();
  };
  render() {
    return (
      <>
        <div
          className={`modal fade WelcomeModal ${this.props.modalclassName} ${this.props.showModal ? "show" : ""
            }`}
          style={{
            display: `${this.props.showModal ? "block" : "none"}`,
            opacity: "1",
          }}
          id="WelcomeModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className={this.props.className}>
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <Button className="btn closebtn" onClick={this.dismissModal}>
                    <img src={loginclose} alt="deafult" />
                  </Button>
                  <div
                    className="camera-box"
                    style={{ width: "100%", textAlign: "center" }}
                  >
                    <h5 className="modal-title" id="exampleModalLabel">
                      {this.props.invitefrend && !this.state.invitefrend
                        ? this.props.title
                        : ""}
                      <span>
                        {" "}
                        <img className="bodyimg" src={this.props.imgsrc} />
                      </span>
                    </h5>
                  </div>
                </div>
                <div className="modal-body" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {this.props.tiebreaker && !this.state.tiebreaker ? (
                    <div className="tiebreak">
                      <div className="closebtn">
                        {" "}
                        <Button
                          className="btn closebtn"
                          onClick={this.dismissModal}
                        >
                          <img src={tieloginclose} alt="deafult" />
                        </Button>
                      </div>
                      <div className="scorebox">
                        <Table>
                          <tbody>
                            <tr className="firstrow">
                              <td>
                                <div className="teamlogo">
                                  <img
                                    src={this.props.teamoneimg}
                                    alt="default"
                                  />
                                  <span>WRU</span>
                                </div>
                              </td>
                              <td>
                                <div className="teamscore">87</div>
                              </td>
                            </tr>
                            <tr className="secondrow">
                              <td>
                                <div className="teamlogo">
                                  <img
                                    src={this.props.teamtwoimg}
                                    alt="default"
                                  />
                                  <span>Saracens</span>
                                </div>
                              </td>
                              <td>
                                <div className="teamscore">67</div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="midcontent">
                        <h4>Tie Breaker</h4>
                        <p>Pick the final score</p>
                      </div>
                      <div className="footercontent">
                        <h4>Total: 154</h4>
                        <div className="scoreboxes">
                          <span>
                            <Table>
                              <tbody>
                                <tr>
                                  <td>
                                    <img
                                      src={this.props.teamoneimg}
                                      alt="default"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <h4>87</h4>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            <p>WINNER</p>
                          </span>
                          <span style={{ width: "20.5%" }}>
                            <div className="midborder">-</div>
                          </span>
                          <span>
                            <Table>
                              <tbody>
                                <tr>
                                  <td>
                                    <img
                                      src={this.props.teamtwoimg}
                                      alt="default"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <h4>67</h4>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            <p>LOSER</p>
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p className="text-center">
                      {this.props.bodytext}
                      <br />
                      {this.props.btn1value ? (
                        <span className="btnspan">
                          <buttons
                            className="btn btn-primary"
                            onClick={this.props.button1Click}
                          >
                            {this.props.btn1value}
                          </buttons>
                        </span>
                      ) : (
                        ""
                      )}
                      {this.props.btn2value ? (
                        <span className="btnspan">
                          <buttons
                            className="btn btn-primary"
                            onClick={this.props.button2Click}
                          >
                            {this.props.btn2value}
                          </buttons>
                        </span>
                      ) : (
                        ""
                      )}

                      {this.props.savebtnvalue ? (
                        <span className="btnspan">
                          <buttons
                            className="btn btn-primary"
                            onClick={this.props.savebtnClick}
                          >
                            {this.props.savebtnvalue}
                          </buttons>
                        </span>
                      ) : (
                        ""
                      )}
                      {this.props.leavebtnvalue ? (
                        <span className="btnspan">
                          <buttons
                            className="btn btn-primary"
                            onClick={this.props.leavebtnClick}
                          >
                            {this.props.leavebtnvalue}
                          </buttons>
                        </span>
                      ) : (
                        ""
                      )}
                      <NavLink
                        activeClassName="active"
                        className="nav-link"
                        to="/"
                        exact
                      >
                        {this.props.btnvalue}
                      </NavLink>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Modal;
