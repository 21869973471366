import React from "react";
import { withRouter } from "../withRouter";
import "../../assets/style.scss";
import Aux from "../../hoc/_Aux";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { globalVariable } from "../../utils/global";
import { consts } from "../../utils/validation";
import { swDev } from "../../swDev";
import axios from "axios";

const eyeopen =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye.png";
const eyeclose =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye-close.png";
const loginclose =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/login-cross.svg";
//Login component
class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      outsidemodal: false,
      addClass: false,
      eyestate: true,
      inputpasswordtype: true,
      userName: null,
      addClassc: false,
      AddClassd: false,
      disbleforgotbtn: false,
      succesMessage: "",
      errorMessage: "",
      forgoterrorMessage: "",
      forgotsuccesserrorMessage: "",
      forgotuserName: "",
      userName: null,
      redirectTimeout: null,
      password: null,
      userNameError: "",
      passwordError: "",
      show: false,
      hide: false,
      isNotLoggedin: true,
      showSpinner: false,
      errUserName: "",
      errPassword: "",
      errforgotUserName: "",
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleIsNotLoggedIn = this.handleIsNotLoggedIn.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.passwordshowhide = this.passwordshowhide.bind(this);
  }

  passwordshowhide() {
    this.setState((prevState) => ({ eyestate: !prevState.eyestate }));
    this.setState((prevState) => ({
      inputpasswordtype: !prevState.inputpasswordtype,
    }));
  }
  handleeyestate() {
    this.setState({ eyestate: true, inputpasswordtype: true });
  }
  setOutsidemodal = () => {
    this.setState((prevState) => ({ eyestate: !prevState.eyestate }));
    this.setState((prevState) => ({
      inputpasswordtype: !prevState.inputpasswordtype,
    }));
    this.setState({
      outsidemodal: true,
    });
    // this.setState({ eyestate: false, inputpasswordtype: false });
  };
  setAddClass = () => {
    this.setState({
      addClass: true,
    });
    this.redirectTimeout = setTimeout(() => {
      this.setState({
        disbleforgotbtn: false,
        forgotsuccesserrorMessage: false,
        forgotuserName: "",
      });
    }, 5000);
  };
  setAddClassb = () => {
    this.setState({
      addClass: false,
      show: false,
    });
  };
  setAddClassc = () => {
    this.setState({
      addClassc: false,
      addClass: false,
      errforgotUserName: "",
      forgotsuccesserrorMessage: "",
      forgoterrorMessage: "",
    });
  };
  toggleModal = () => {
    this.setState({
      modalIsOpen: !this.state.show,
    });
  };
  handleIsNotLoggedIn() {
    this.setState({ isNotLoggedin: false });
  }
  handleClose() {
    this.setState({ show: false });
  }

  onKeyUp(event) {
    if (event.charCode === 13) {
      this.login();
    }
  }
  componentWillUnmount() {
    clearTimeout(this.redirectTimeout);
  }

  login() {
    const data = {
        userName: this.state.userName,
        password: this.state.password,
    };

    // Validation logic...

    this.setState({
        showSpinner: true,
    });

    let BaseUrl = globalVariable.baseURL + "manage-user/login";

    axios
        .post(BaseUrl, data)
        .then((resp) => {
            console.log("API Response:", resp); // Log the entire response for debugging
            if (resp && resp.data && resp.data.code === 200) {
                // Check if the response has the expected structure
                if (resp.data.data) {
                    if (resp.data.data.admin) {
                        localStorage.setItem("tp", 1);
                    } else {
                        localStorage.setItem("tp", 2);
                    }
                    localStorage.setItem(
                        "login",
                        JSON.stringify({
                            login: resp.headers.access_token,
                        })
                    );
                    this.setState({ succesMessage: resp.data.message }, () => {
                      console.log("Success Message:", this.state.succesMessage);
                  });
                    this.setState({ errorMessage: false });
                    this.handleIsNotLoggedIn();
                    if (resp.data.data.admin) {
                        this.props.navigate("/manage-mens-bracket");
                    } else {
                        if (this.props.redirectUrl) {
                            this.props.navigate(this.props.redirectUrl);
                        } else {
                            this.props.navigate("/create-brackets");
                        }
                    }
                    swDev();
                } else {
                    this.setState({ errorMessage: "Data not found in response." });
                }
            } else {
                this.setState({ errorMessage: resp.data ? resp.data.message : "Unexpected response format." });
            }
        })
        .catch((err) => {
            console.error("API Error:", err); // Log the error for debugging
            const errorMessage = err.response ? err.response.data.message : "Network error. Please try again.";
            this.setState({ errorMessage });
            this.setState({ succesMessage: false });
            this.setState({
                showSpinner: false,
            });
        });
}
  handleSubmitforgitp() {
    if (this.state.forgotuserName == null || this.state.forgotuserName == "") {
      this.setState({
        errforgotUserName: "Please fill email Id",
        forgoterrorMessage: false,
      });
    } else {
      const forgotdata = {
        userName: this.state.forgotuserName,
      };
      let BaseUrl = globalVariable.baseURL + "manage-user/forgotPassword";
      fetch(BaseUrl, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer access_token",
        },
        body: JSON.stringify(forgotdata),
      })
        .then((data) => data.json())
        .then((resp) => {
          console.warn("resp", resp);
          if (resp.code === 200) {
            // alert(resp.message);
            this.setState({ forgotsuccesserrorMessage: resp.message });
            this.setState({
              forgoterrorMessage: false,
              disbleforgotbtn: true,
            });
            // console.warn(this.props.history.push("/home"));
            this.redirectTimeout = setTimeout(() => {
              this.setState({ addClass: false });
            }, 5000);
          } else {
            this.setState({ show: false });
            this.setState({ forgoterrorMessage: resp.message });
            this.setState({ forgotsuccesserrorMessage: false });
            // alert(resp.message);
          }
          console.warn({ resp });
        });
    }
  }
  render() {
    let { onBlur, onChange, value, ...props } = this.props;
    return (
      <Aux>
        <Modal
          show={this.props.show && !this.state.show && this.state.isNotLoggedin}
          onKeyPress={this.handleKeypress}
          centered
          onHide={this.props.onClick}
          onClick={this.setOutsidemodal}
          animation={false}
          className={`${this.state.outsidemodal ? "closemodal" : null} ${
            this.state.addClass ? "forgotpassword" : null
          }`}
        >
          <Modal.Header
            className={`${this.state.addClass ? "modalhide" : "mdalshow"}`}
           
          >
            <Button onClick={this.props.onClick} className="btn closebtn 2">
              <img src={loginclose} alt="deafult" />
            </Button>
            <Modal.Title className="text-white">
              Login
              <p className="text-white">Sign in to see your brackets and tournament leaderboard.</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Header
            className={`${this.state.addClass ? "mdalshow" : "modalhide"}`}
            
            style={{ height: "100px" }}
          >
            {this.state.addClassc ? (
              <Button onClick={this.props.onClick} className="btn closebtn 4">
                <img src={loginclose} alt="deafult" />
              </Button>
            ) : (
              <Button onClick={this.setAddClassc} className="btn closebtn 5">
                <img src={loginclose} alt="deafult" />
              </Button>
            )}

            <Modal.Title>Forgot Password</Modal.Title>
          </Modal.Header>
          <Modal.Body
            className={`${this.state.addClass ? "modalhide" : "mdalshow"}`}
          >
            <Form.Group>
              <Form.Label>Username/Email</Form.Label>
              <Form.Control
                onKeyPress={this.onKeyUp}
                autoCorrect="off"
                autoCapitalize="off"
                onChange={(event) => {
                  this.setState({
                    userName: event.target.value,
                    errUserName: "",
                  });
                }}
                type="text"
                placeholder="Enter Username/Email"
              />
              <p className="err-paragraph">{this.state.errUserName}</p>
            </Form.Group>
            <Form.Text style={{ marginBottom: "10px" }} className="text-muted">
              <div className="error">{this.state.userNameError}</div>
            </Form.Text>

            <Form.Group className="password_b">
              <Form.Label>Password</Form.Label>
              <Form.Control
                onKeyPress={this.onKeyUp}
                onBlur={this.handleeyestate.bind(this)}
                className="password__input"
                // type={this.state.inputpasswordtype ? "text" : "password"}
                type="password"
                onChange={(event) => {
                  this.setState({
                    password: event.target.value,
                    errPassword: "",
                  });
                }}
                placeholder="Enter Password"
              />
              {/* <span className="password__show" onClick={this.passwordshowhide}>
                {this.state.eyestate ? (
                  <img src={eyeopen} default="open" />
                ) : (
                  <img src={eyeclose} default="close" />
                )}{" "}
              </span> */}
              <p className="err-paragraph">
                {this.state.errPassword}
                {this.state.errorMessage && (
                  <div style={{ color: "red" }} className="error">
                    {this.state.errorMessage}
                  </div>
                )}
              </p>
            </Form.Group>
            <div className="error">{this.state.passwordError}</div>
            <Form.Text style={{ marginBottom: "20px" }} className="text-muted">
              {this.state.succesMessage && (
                <div style={{ color: "green" }} className="succesmsg">
                  {this.state.succesMessage}
                </div>
              )}
            </Form.Text>
            <div className="footer-btn">
              <Button
                className="buttonload"
                id="login-button"
                onClick={() => {
                  this.login();
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.login();
                  }
                }}
                variant="primary"
                type="submit"
              >
                {this.state.showSpinner && (
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                )}
                LOGIN
              </Button>
            </div>
            <div className="footer-forgot-p">
              <p>
                <a onClick={this.setAddClass}>Forgot Password?</a>
              </p>
            </div>
            <div className="footer-register">
              <p>
                Don’t have an account? <a href="/sign-up">Register</a>
              </p>
            </div>
          </Modal.Body>
          <Modal.Body
            className={`${this.state.addClass ? "mdalshow" : "modalhide"}`}
          >
            <Form.Group style={{ marginBottom: "2px" }}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                onChange={(event) => {
                  this.setState({
                    forgotuserName: event.target.value,
                    errforgotUserName: "",
                  });
                }}
                type="email"
                placeholder="Enter Email"
              />
            </Form.Group>
            <p style={{ margin: "0" }} className="err-paragraph">
              {this.state.errforgotUserName}
              {this.state.forgoterrorMessage && (
                <div style={{ color: "red" }} className="error">
                  {this.state.forgoterrorMessage}
                </div>
              )}
            </p>
            <Form.Text style={{ marginBottom: "20px" }} className="text-muted">
              <p className="err-paragraph">{this.state.errForgotUserName}</p>
              {this.state.forgotsuccesserrorMessage && (
                <div style={{ color: "green" }} className="succesmsg">
                  {this.state.forgotsuccesserrorMessage}
                </div>
              )}
            </Form.Text>
            <div
              className={`footer-btn ${
                this.state.disbleforgotbtn ? "hideforgotbtn" : ""
              }`}
            >
              <Button
                className=""
                onClick={() => {
                  this.handleSubmitforgitp();
                }}
                variant="primary"
                type="submit"
              >
                {this.state.showSpinner && (
                  <i className="fa fa-circle-o-notch fa-spin"></i>
                )}
                Submit
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </Aux>
    );
  }
}

export default withRouter(LoginModal);
