import React, { Component } from "react";
import Aux from "../hoc/_Aux";
import Menu from "../screens/includes/Navbar";
import { Link, NavLink } from "react-router";
import { globalVariable } from "../utils/global";
import { normalizeInput, consts } from "../utils/validation";
import axios from "axios";
import Placeadd from "../components/Placeadd";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Placeaddsm from "../components/Placeaddsm";
import ProfileBaner from "../components/Profile-hero";
import Input from "../components/Input";
import Radio from "../components/Radio";
import Bluebtn from "../components/Bluebtn";
import ChangePassword from "../components/Profile/ChangePassword";
import calender from "../assets/socilicons/calendaricon.jpg";
import DatePicker from "react-datepicker";
import moment from "moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { PostData } from "../utils/apiRequestHandler";

const stepiconB = "https://d7le4ielk3ho1.cloudfront.net/src_images/mobileview/create-bracket.svg";
const pointsimgC = "https://d7le4ielk3ho1.cloudfront.net/src_images/mobileview/leader-borad.svg";
const pencilicon = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/edit-icon.svg";
const imgDefault = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/user-default-picture.png";
const Bracketicon = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/bracket.svg";
const Invitefrndicon = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/invite-friend-blue.svg";
const Rightarrow = "https://d7le4ielk3ho1.cloudfront.net/src_images/new/score-arrow-right.svg";
const profilehero = "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/profile-banner.jpg";
const profileheromobile = "https://d7le4ielk3ho1.cloudfront.net/src_images/account-mobile.jpg";
const leaderBoardIcon = "https://d7le4ielk3ho1.cloudfront.net/src_images/leaderboard-score.svg";


class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      countries: null,
      countryId: null,
      stateId: "",
      states: null,
      roles: null,
      role: null,
      savebtn: false,
      errFilesize: "",
      editsave: false,
      rank: "N/A",
      womensRank: "N/A",
      mensTotalScore: 0,
      womensTotalScore: 0,
      uploaddisable: true,
      readOnly: true,
      isProfileRemoved: "",
      uploadbtnhide: true,
      user: [],
      selectedFile: null,
      imagePreviewUrl: "",
      profile_image: "",
      showSpinner: false,
      succesMessage: "",
      errgender: "",
      errorMessage: "",
      succesEditMessage: "",
      errorEditMessage: "",
      firstName: "",
      errPhoneNumber: "",
      lastName: "",
      userName: "",
      email: "",
      errphoneNumber: "",
      selectdisable: true,
      phoneNumber: "",
      gender: "",
      date_of_birth: null,
      dob: "",
      datePickerIsOpen: false,
      selectedFile: null,
      selectedFile64: "",
      removeuserimg: false,
      inputactive: true,
      test: "",
      showCalendar: false
    };
    this.Editbtn = this.Editbtn.bind(this);
    this.Edit = this.Edit.bind(this);
    this.savedata = this.savedata.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.dateChange = this.dateChange.bind(this);
    this.removepicHandle = this.removepicHandle.bind(this);
    this.handlephonenumber = this.handlephonenumber.bind(this);
  }

  handlephonenumber({ target: { value } }) {
    this.setState((prevState) => ({
      phoneNumber: normalizeInput(value, prevState.phone),
    }));
  }
  savedata() {
    this.setState((prevState) => ({
      editsave: !prevState.editsave,
    }));
    this.Editbtn();
  }

  toggle = () => {
    // this.component.setOpen(this.focus);
    // this.focus = !this.focus;
    this.setState({
      showCalendar: !this.state.showCalendar
    })
  };

  componentDidMount() {
    if (localStorage.tp !== "2") {
      this.props.navigate("/");
    } else {
      document.title = "NCR Rugby | Profile";
      let token = JSON.parse(localStorage.getItem("login"));
      const accesstoken = token.login;
      console.warn(accesstoken);
      this.GetRank("male");
      let BaseUrl = globalVariable.baseURL + "manage-user/getUser";

      axios
        .get(BaseUrl, {
          headers: {
            access_token: accesstoken,
          },
        })
        .then((resp) => {
          console.warn("resp", resp);
          console.warn(resp);
          if (resp.data.code === 200) {
            localStorage.setItem(
              "changepassowrd",
              JSON.stringify({
                password: resp.data.data.resetPasswordToken,
                resetPasswordExpires: resp.data.data.resetPasswordExpires,
              })
            );
            // alert(resp.data.message);
            this.setState({
              succesMessage: resp.data.message,
            });
            this.setState({ errorMessage: false });
            const user = resp.data.data;
            this.setState({ user: user });
            this.setState({
              firstName: user.firstName,
              lastName: user.lastName,
              userName: user.userName,
              email: user.email,
              phoneNumber: user.phoneNumber,
              gender: user.gender,
              user_images: user.user_images,
              dob: user.date_of_birth,
              stateId: user.stateId,
              countryCode: user.countryCode,
              role: user.role,
              dob: user.date_of_birth,
              user_image_url: resp.data.s3url,
              selectedFile64: user.user_images,
              countryId: user.countryId,
              stateIdLuser: user.stateId,
            });
            let datedata = moment(this.state.dob).format("MM/dd/YYYY");

            this.setState({
              date_of_birth: moment(this.state.dob).format('MM/DD/YYYY'),
              test: datedata,
            });
            this.setState({
              imagePreviewUrl:
                this.state.user_image_url + this.state.user_images[0].image_path,
            });
          }
        })
        .catch((err) => {
          // alert(err.message);
          this.setState({
            succesMessage: false,
          });
          this.setState({ errorMessage: err.message });
        });

      //calling api to fetch countries data
      let BaseUrlB = globalVariable.baseURL + "commonData";
      fetch(BaseUrlB)
        .then((res) => res.json())
        .then((data) => {
          if (data.code == 200) {
            this.setState({
              countries: data.data.getCountriesData,
              states: data.data.getStatesData,
              roles: data.data.getRolesData,
            });
          }
        })
        .catch((err) => { });
    }
  }
  // dateChange(date) {
  //   console.log(date);
  //   this.setState({
  //     date_of_birth: true,
  //   });
  // }
  // dateChange(date) {
  //   this.setState({
  //     date_of_birth: date,
  //   });
  // }
  dateChange(dt) {
    console.log(dt);
    let date = moment(dt).format('MM/DD/YYYY');
    this.setState({
      date_of_birth: date,
      errdate_of_birth: null,
      showCalendar: false
    });
  }

  Edit() {
    console.warn(this.state.dob, this.state.date_of_birth, this.state.test);
    this.setState((prevState) => ({ readOnly: !prevState.readOnly }));
    this.setState((prevState) => ({
      selectdisable: !prevState.selectdisable,
    }));
    this.setState((prevState) => ({ savebtn: !prevState.savebtn }));
    this.setState((prevState) => ({
      uploaddisable: !prevState.uploaddisable,
    }));
    this.setState((prevState) => ({
      editsave: !prevState.editsave,
    }));
    this.setState((prevState) => ({ inputactive: !prevState.inputactive }));
    this.setState((prevState) => ({
      uploadbtnhide: !prevState.uploadbtnhide,
    }));
    this.setState({
      succesEditMessage: false,
      showSpinner: false,
    });
    this.setState({ errorEditMessage: false });
  }

  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let selectedFile = e.target.files[0];
    if (selectedFile.size > 10485760) {
      this.setState({ errFilesize: "File size should not be more than 10 MB" });
    } else {
      reader.onloadend = () => {
        this.setState({
          selectedFile: selectedFile,
          imagePreviewUrl: reader.result,
          removeuserimg: false,
        });
      };
      reader.readAsDataURL(selectedFile);
    }
  }

  Editbtn() {
    // this.setState({ uploadedfile: formData });

    this.setState({
      showSpinner: true,
    });

    // const formdata = new FormData();
    // formdata.append("user_image", this.state.profileImage);

    if (
      (this.state.userName == null || this.state.userName == "") &&
      (this.state.email == null || this.state.email == "") &&
      (this.state.password == null || this.state.password == "") &&
      (this.state.confirmPassword == null ||
        this.state.confirmPassword == "") &&
      (this.state.firstName == null || this.state.firstName == "") &&
      (this.state.lastName == null || this.state.lastName == "") &&
      (this.state.gender == null || this.state.gender == "") &&
      (this.state.role == null || this.state.role == "0") &&
      (this.state.date_of_birth == null || this.state.date_of_birth == "") &&
      (this.state.phoneNumber == null || this.state.phoneNumber == "") &&
      (this.state.stateId == null ||
        (this.state.stateId == 0 && this.state.countryId == 224))
      // (this.state.countryId == null || this.state.countryId == "")
    ) {
      this.setState({
        errUserName: "Please fill Username",
        errgender: "Please fill Gender",
        errEmail: "Please Fill a valid email ID",
        errPassword: "Please Fill Passsword",
        errstateId: "Please Select State",
        errConfirmPassword: "Please Fill Confirm Passsword",
        errmessage: "Please fill message",
        errrole: "Please select role",
        // errPhoneNumber: "Please fill Phone Number",
        errlastName: "Please fill last name",
        errlastName: "Please fill last name",
        errfirstName: "Please fill first name",
        errdate_of_birth: "Please fill date of birth",
        succesMessage: false,
        errorMessage: false,
        showSpinner: false,
      });

      return;
    } else if (this.state.gender == null || this.state.gender == "") {
      this.setState({
        showSpinner: false,
        errgender: "Please Select Gender",
      });
      return;
    }
    console.warn("state", this.state);
    console.log(this.state.selectedFile);
    const formData = new FormData();
    if (this.state.selectedFile == null || this.state.selectedFile == "") {
      formData.append("profile_image", 2);
    } else {
      formData.append(
        "profile_image",
        this.state.selectedFile,
        this.state.selectedFile.name
      );
    }
    if (
      (this.state.selectedFile == "" || this.state.selectedFile !== "") &&
      this.state.removeuserimg == false
    ) {
      formData.append("isProfileRemoved", 0);
    } else if (
      (this.state.selectedFile == "" || this.state.selectedFile !== "") &&
      this.state.removeuserimg == true
    ) {
      formData.append("isProfileRemoved", 1);
    } else if (this.state.selectedFile !== "") {
      formData.append("isProfileRemoved", 0);
    }
    formData.append("firstName", this.state.firstName);
    formData.append("lastName", this.state.lastName);
    formData.append("email", this.state.email);
    formData.append("userName", this.state.userName);
    formData.append("phoneNumber", this.state.phoneNumber);
    formData.append("gender", this.state.gender);
    formData.append("date_of_birth", this.state.date_of_birth);
    formData.append("countryId", this.state.countryId);
    if (this.state.stateId == null || this.state.stateId == "") {
      this.setState({ stateId: "0" });
      formData.append("stateId", this.state.stateId);
    } else {
      formData.append("stateId", this.state.stateId);
    }
    formData.append("role", this.state.role);

    console.warn(formData);
    console.warn("test send data", formData);

    let token = JSON.parse(localStorage.getItem("login"));
    const accesstoken = token.login;
    console.warn(accesstoken);

    let BaseUrl = globalVariable.baseURL + "manage-user/editProfile";

    if (this.state.firstName == null || this.state.firstName == "") {
      this.setState({
        errfirstName: "Please fill first name",
        showSpinner: false,
      });
      this.setState({ errorMessage: false });
    } else if (this.state.lastName == null || this.state.lastName == "") {
      this.setState({
        errlastName: "Please fill last name",
        showSpinner: false,
      });
      this.setState({ errorMessage: false });
    } else if (this.state.userName == null || this.state.userName == "") {
      this.setState({
        erruserName: "Please fill user name",
        showSpinner: false,
      });
      this.setState({ errorMessage: false });
    } else if (this.state.gender == null || this.state.gender == "") {
      this.setState({
        showSpinner: false,
        errgender: "Please Select Gender",
      });
      return;
    } else if (this.state.email == null || this.state.email == "") {
      this.setState({
        erremail: "Please fill email",
        showSpinner: false,
      });
      this.setState({ errorMessage: false });
    } else if (this.state.lastName == null || this.state.lastName == "") {
      this.setState({
        showSpinner: false,
        errlastName: "Please Fill Last Name",
      });
      return;
    } else if (
      this.state.date_of_birth == null ||
      this.state.date_of_birth == ""
    ) {
      this.setState({
        showSpinner: false,
        errdate_of_birth: "Please Fill Date Of Birth",
      });
      return;
    } else if (this.state.role == null || this.state.role == "0") {
      this.setState({
        showSpinner: false,
        errrole: "Please Select Role",
      });
      return;
    } else if (this.state.gender == null || this.state.gender == "") {
      this.setState({
        errgender: "Please fill gender",
        showSpinner: false,
      });
      this.setState({ errorMessage: false });
    } else {
      axios
        .post(BaseUrl, formData, {
          headers: {
            access_token: accesstoken,
            // "content-type": "multipart/form-data",
          },
        })
        .then((resp) => {
          console.warn("resp", resp);
          console.warn(resp);
          if (resp.data.code === 200) {
            // alert(resp.data.message);
            this.setState({
              succesEditMessage: resp.data.message,
              showSpinner: false,
              errphoneNumber: false,
            });
            this.setState({ errorEditMessage: false, removeuserimg: false });
            this.setState((prevState) => ({ readOnly: !prevState.readOnly }));
            this.setState((prevState) => ({ savebtn: !prevState.savebtn }));
            this.setState((prevState) => ({
              selectdisable: !prevState.selectdisable,
            }));
            this.setState((prevState) => ({
              inputactive: !prevState.inputactive,
            }));
            this.setState((prevState) => ({
              uploaddisable: !prevState.uploaddisable,
            }));
            this.setState((prevState) => ({
              uploadbtnhide: !prevState.uploadbtnhide,
            }));
          }
        })
        .catch((err) => {
          // alert(err.response.data.message);
          this.setState({
            succesEditMessage: false,
            showSpinner: false,
          });
          this.setState({ errorEditMessage: err.response.data.message });
        });
    }
  }

  onValueChange(changeEvent) {
    this.setState({
      gender: changeEvent.target.value,
    });
  }

  removepicHandle() {
    this.setState({
      imagePreviewUrl: "",
      removeuserimg: true,
    });
  }

  GetRank = (type) => {
    const formData = new FormData();
    formData.append("bracket_type", type);
    PostData("leaderboard/getUserRank", formData).then((response) => {
      if (response.status === true) {
        if (response.data.mensRank[0]) {
          this.setState({
            rank: response.data.mensRank[0].rank,
            mensTotalScore: response.data.mensRank[0].score
              ? response.data.mensRank[0].score
              : 0,
          });
        }
        if (response.data.womensRank[0]) {
          this.setState({
            womensRank: response.data.womensRank[0].rank,
            womensTotalScore: response.data.womensRank[0].score
              ? response.data.womensRank[0].score
              : 0,
          });
        }
      } else {
        console.log("Error while fetching data");
      }
    });
  };

  render() {
    let { onBlur, onChange, value, ...props } = this.props;
    let input_class = this.state.inputactive ? "" : "form-bg-color";

    if (this.state.countries) {
      this.state.countries.map((cn, i) => {
        if (cn.id == 224 && this.state.countryId == null) {
          this.setState({ countryId: cn.id });
        }
      });
    }
    if (this.state.roles) {
      this.state.roles.map((rs, i) => {
        if (rs.id == 1 && this.state.role == null) {
          this.setState({ role: rs.id });
        }
      });
    }
    let displayCountries = this.state.countries ? (
      this.state.countries.map((c, index) => {
        return (
          <option value={c.id} selected={c.id == 224}>
            {c.name}
          </option>
        );
      })
    ) : (
      <option>Data not fetched</option>
    );
    const { savebtn } = this.state;
    let { imagePreviewUrl } = this.state;
    let imagePreview = null;
    if (imagePreviewUrl !== "") {
      imagePreview = imagePreviewUrl;
    } else {
      imagePreview = imgDefault;
    }
    return (
      <Aux>
        <Menu />
        <div className="Maincontent">
          <Form>
            <Row>
              <Col md="12">
                <div className="leaderord headerAdvt">
                  <Placeadd />
                </div>
              </Col>
            </Row>
            <Row className="profile-banner-box">
              <ProfileBaner
                img={profilehero}
                imgmobile={profileheromobile}
                alt="deafult"
              />
            </Row>
            <Row style={{ backgroundColor: "#fafafa" }}>
              <Col className="profileBox">
                <div className="userProfile">
                  <div className="Profile-box">
                    <Row>
                      <Col md="3">
                        <div className="sighnup-img">
                          <Col>
                            <div className="profilepic">
                              <div className="upimg">
                                <img src={imagePreview}></img>
                              </div>
                              <div
                                className={`uploadimg ${this.state.uploadbtnhide
                                    ? "uploadbtn-hide"
                                    : ""
                                  }`}
                              >
                                <input
                                  onChange={(e) => this.handleImageChange(e)}
                                  name="user_image"
                                  type="file"
                                  id="getFile"
                                  accept="image/x-png,image/gif,image/jpeg"
                                  style={{ display: "none" }}
                                ></input>
                                <Button
                                  onClick={() =>
                                    document.getElementById("getFile").click()
                                  }
                                  className="btn uploadbtn"
                                  disabled={this.state.uploaddisable}
                                >
                                  Edit Picture
                                </Button>
                                <Button
                                  style={{ marginTop: "5px" }}
                                  onClick={this.removepicHandle}
                                  className="btn uploadbtn"
                                  disabled={this.state.uploaddisable}
                                >
                                  Remove Picture
                                </Button>
                                <p className="err-paragraph">
                                  {this.state.errFilesize}
                                </p>
                              </div>
                            </div>
                          </Col>
                          <hr />
                          <Col>
                            <div className="ProfileButton">
                              <Link to="/create-brackets">
                                <Button
                                  type="button"
                                  className="btn btn-profile"
                                >
                                  <span className="btn-icon">
                                    <img src={stepiconB} alt="deafult" />
                                  </span>
                                  View Brackets
                                  <span className="arrow-icon">
                                    <img src={Rightarrow} alt="deafult" />
                                  </span>
                                </Button>
                              </Link>
                            </div>
                          </Col>
                          <hr />
                          <Col>
                            <div className="ProfileButton">
                              <Link to="/create-brackets/my-groups">
                                <Button
                                  type="button"
                                  className="btn btn-profile"
                                >
                                  <span className="btn-icon">
                                    <img src={stepiconB} alt="deafult" />
                                  </span>
                                  View Groups
                                  <span className="arrow-icon">
                                    <img src={Rightarrow} alt="deafult" />
                                  </span>
                                </Button>
                              </Link>
                            </div>
                          </Col>
                          <hr />
                          <Col>
                            <div className="ProfileButton">
                              <Link to="/create-brackets/leaderboard">
                                <Button
                                  type="button"
                                  className="btn btn-profile"
                                >
                                  <span className="btn-icon profile-leaderbord-icon">
                                    <img
                                      className="leaderbord"
                                      src={pointsimgC}
                                      alt="deafult"
                                    />
                                  </span>
                                  Leader Board
                                  <span className="arrow-icon">
                                    <img src={Rightarrow} alt="deafult" />
                                  </span>
                                </Button>
                              </Link>
                            </div>
                          </Col>
                          <hr />
                          <Col>
                            <div className="ProfileButton">
                              <Link to="/invite-friends">
                                {" "}
                                <Button
                                  type="button"
                                  className="btn btn-profile"
                                >
                                  <span className="btn-icon">
                                    <img src={Invitefrndicon} alt="deafult" />
                                  </span>
                                  Invite Friends
                                  <span className="arrow-icon">
                                    <img src={Rightarrow} alt="deafult" />
                                  </span>
                                </Button>
                              </Link>
                            </div>
                          </Col>
                          <hr />
                          <Col>
                            <div className="Pointsbox">
                              <div className="points">
                                <p className="text">Men’s Tournament Points</p>
                                <p className="number">
                                  {this.state.mensTotalScore}
                                </p>
                              </div>
                            </div>
                            <div className="Pointsbox pointbox2">
                              <div className="points">
                                <p className="text">Men's Rank</p>
                                <p
                                  className={`number ${this.state.rank < 0 ? "red" : "green"
                                    }`}
                                >
                                  {this.state.rank === -1
                                    ? "N/A"
                                    : this.state.rank}
                                </p>
                              </div>
                            </div>
                            <div className="Pointsbox">
                              <div className="points">
                                <p className="text">
                                  Women’s Tournament Points
                                </p>
                                <p className="number">
                                  {this.state.womensTotalScore}
                                </p>
                              </div>
                            </div>

                            <div className="Pointsbox pointbox2">
                              <div className="points">
                                <p className="text">Women's Rank</p>
                                <p
                                  className={`number ${this.state.rank < 0 ? "red" : "green"
                                    }`}
                                >
                                  {this.state.womensRank === -1
                                    ? "N/A"
                                    : this.state.womensRank}
                                </p>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Col md="8">
                        <div className="sighnup-form">
                          <Tabs defaultActiveKey="profile" transition={false}>
                            <Tab
                              className="tab1"
                              eventKey="profile"
                              title="MY PROFILE"
                           
                            >
                              <div>
                                <Row>
                                  <Col>
                                    <Form.Text
                                      style={{ marginBottom: "10px" }}
                                      className="text-muted"
                                    >
                                      <div className="error">
                                        {this.state.errmessage}
                                      </div>
                                    </Form.Text>
                                    {/* <Form.Text
                                      style={{ marginBottom: "20px" }}
                                      className="text-muted"
                                    >
                                      {this.state.errorMessage && (
                                        <div
                                          style={{ color: "red" }}
                                          className="error"
                                        >
                                          {this.state.errorMessage}
                                        </div>
                                      )}
                                      {this.state.succesMessage && (
                                        <div
                                          style={{ color: "green" }}
                                          className="error"
                                        >
                                          {this.state.succesMessage}
                                        </div>
                                      )}
                                    </Form.Text> */}
                                  </Col>
                                  <Col className="editcol">
                                    <div className="editbtn">
                                      {this.state.savebtn ? (
                                        <Button
                                          onClick={this.savedata}
                                          className="btn edit-btn"
                                        >
                                          <img src={pencilicon} alt="deafult" />{" "}
                                          Save
                                        </Button>
                                      ) : (
                                        <Button
                                          onClick={this.Edit}
                                          className="btn edit-btn"
                                        >
                                          <img src={pencilicon} alt="deafult" />{" "}
                                          Edit
                                        </Button>
                                      )}
                                    </div>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col style={{ padding: "0" }}>
                                    <Row style={{ marginBottom: "12px" }}>
                                      <Col className="fname" md="6">
                                        <Input
                                          label="First Name"
                                          placeholder="Jhon"
                                          className={input_class}
                                          value={this.state.firstName}
                                          readOnly={this.state.readOnly}
                                          onChange={(event) => {
                                            this.setState({
                                              firstName: event.target.value,
                                              errfirstName: "",
                                            });
                                            console.warn(this.state.firstName);
                                          }}
                                        />
                                        <Form.Text
                                          style={{ marginBottom: "10px" }}
                                          className="text-muted"
                                        >
                                          <div className="error">
                                            {this.state.errfirstName}
                                          </div>
                                        </Form.Text>
                                      </Col>
                                      <Col className="lname" md="6">
                                        <Input
                                          label="Last Name"
                                          placeholder="DOE"
                                          className={input_class}
                                          value={this.state.lastName}
                                          readOnly={this.state.readOnly}
                                          onChange={(event) => {
                                            this.setState({
                                              lastName: event.target.value,
                                              errlastName: "",
                                            });
                                          }}
                                        />
                                        <Form.Text
                                          style={{ marginBottom: "10px" }}
                                          className="text-muted"
                                        >
                                          <div className="error">
                                            {this.state.errlastName}
                                          </div>
                                        </Form.Text>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>







                                <Row>
                                  <Col style={{ padding: "0" }}>
                                    <Form.Group>
                                      <Input
                                        label="User name"
                                        placeholder="Charlotte A.hill"
                                        value={this.state.userName}
                                        readOnly="true"
                                        onChange={(event) => {
                                          this.setState({
                                            userName: event.target.value,
                                            erruserName: "",
                                          });
                                        }}
                                      />
                                      <Form.Text
                                        style={{ marginBottom: "10px" }}
                                        className="text-muted"
                                      >
                                        <div className="error">
                                          {this.state.erruserName}
                                        </div>
                                      </Form.Text>
                                    </Form.Group>
                                    <Form.Group>
                                      <Input
                                        label="Email Address"
                                        placeholder="xyz@domainname.com"
                                        value={this.state.email}
                                        readOnly="true"
                                        onChange={(event) => {
                                          this.setState({
                                            email: event.target.value,
                                            erremail: "",
                                          });
                                        }}
                                      />
                                      <Form.Text
                                        style={{ marginBottom: "10px" }}
                                        className="text-muted"
                                      >
                                        <div className="error">
                                          {this.state.erremail}
                                        </div>
                                      </Form.Text>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="mb-3">
                                      <label
                                        style={{ marginLeft: "-8px" }}
                                        className="form-label"
                                      >
                                        Sex
                                      </label>
                                      <Radio
                                        value="male"
                                        disabled={this.state.readOnly}
                                        checked={this.state.gender === "male"}
                                        onChange={this.onValueChange}
                                        name="gender"
                                        label="Male"
                                      
                                      />
                                      <Radio
                                        value="female"
                                        disabled={this.state.readOnly}
                                        checked={this.state.gender === "female"}
                                        onChange={this.onValueChange}
                                        name="female"
                                        label="Female"
                                       
                                      />
                                    </div>
                                    <p className="err-paragraph">
                                      {this.state.errgender}
                                    </p>
                                  </Col>
                                  <Col style={{ padding: "0" }} md="6">
                                    <Form.Group>
                                      <label className="form-label">
                                        Date Of Birth
                                      </label>
                                      <div className="form-dob-icon">
                                        {" "}
                                        {/* <DatePicker
                                          ref={(r) => {
                                            this.component = r;
                                          }}
                                          selected={this.state.date_of_birth}
                                          onChange={this.dateChange}
                                          className={`form-control form-dob ${input_class}`}
                                          placeholder="Date of Birth"
                                          maxDate={new Date()}
                                          readOnly={this.state.readOnly}
                                          placeholderText="Select Date Of Birth"
                                          showYearDropdown
                                          dateFormat="MM/dd/yyyy"
                                        /> */}
                                        <Input
                                          value={this.state.date_of_birth}
                                          readonly="true"
                                          placeholder="Date Of Birth"
                                        />
                                        {
                                          this.state.showCalendar && <Calendar
                                            ref={(r) => {
                                              this.component = r;
                                            }}
                                            defaultValue={this.state.date_of_birth ? new Date(this.state.date_of_birth) : new Date()}
                                            selected={this.state.date_of_birth ? new Date(this.state.date_of_birth) : new Date()}
                                            onChange={this.dateChange}
                                            className="form-control form-dob"
                                            placeholder="Date of Birth"
                                            maxDate={new Date()}
                                            placeholderText="Select Date Of Birth"
                                            showYearDropdown
                                            onClick={this.dateChange}
                                            onClickDay={this.dateChange}
                                            // value={new Date(this.state.date_of_birth)}
                                            calendarType={'US'}
                                          />
                                        }
                                        <span
                                          onClick={(e) => this.state.savebtn ? this.toggle() : e.preventDefault() }
                                          className="claendaricon"
                                        >
                                          <img src={calender} alt="calendar" />
                                        </span>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Form.Group>
                                    <label className="form-label">Rugby Role</label>
                                    <br></br>
                                    <select
                                      value={this.state.role}
                                      className={`form-control ${input_class}`}
                                      readOnly={this.state.readOnly}
                                      disabled={this.state.selectdisable}
                                      onChange={(event) => {
                                        this.setState({
                                          role: parseInt(event.target.value),
                                        });
                                      }}
                                    >
                                      {this.state.roles ? (
                                        this.state.roles.map((r, i) => {
                                          return (
                                            <option
                                              selected={r.id == 1}
                                              value={r.id}
                                            >
                                              {r.role}
                                            </option>
                                          );
                                        })
                                      ) : (
                                        <option value={null}>
                                          Roles not fetched
                                        </option>
                                      )}
                                    </select>
                                  </Form.Group>
                                </Row>
                                <Row></Row>
                                <Row>
                                  <Form.Group style={{ marginBottom: "11px" }}>
                                    <Input
                                      label="Phone Number (Optional)"
                                      placeholder="Enter Phone Number"
                                      className={input_class}
                                      value={this.state.phoneNumber}
                                      readOnly={this.state.readOnly}
                                      pattern="[1-14]{1}[0-14]{14}"
                                      type="text"
                                      maxlength="14"
                                      onChange={this.handlephonenumber}
                                    />
                                    <Form.Text
                                      style={{ marginBottom: "10px" }}
                                      className="text-muted"
                                    >
                                      <div className="error">
                                        {this.state.errphoneNumber}
                                      </div>
                                    </Form.Text>
                                  </Form.Group>
                                </Row>
                                <Row>
                                  <Col style={{ paddingLeft: "0" }}>
                                    <Form.Group>
                                      <label className="form-label">Country</label>
                                      <select
                                        readOnly={this.state.readOnly}
                                        value={this.state.countryId}
                                        className={`form-control ${input_class}`}
                                        disabled={this.state.selectdisable}
                                        id="country"
                                        onChange={(event) => {
                                          this.setState({
                                            countryId: parseInt(
                                              event.target.value
                                            ),
                                          });
                                        }}
                                      >
                                        {displayCountries}
                                      </select>
                                    </Form.Group>
                                  </Col>
                                  <Col style={{ padding: "0" }}>
                                    <Form.Group>
                                      {(this.state.countryId === 224 ||
                                        this.state.countryId === null) && (
                                          <div>
                                            {" "}
                                            <label className="form-label">
                                              State
                                            </label>
                                            <select
                                              readOnly={this.state.readOnly}
                                              value={this.state.stateId}
                                              className={`form-control ${input_class}`}
                                              disabled={this.state.selectdisable}
                                              id="State"
                                              onChange={(event) => {
                                                this.setState({
                                                  stateId: parseInt(
                                                    event.target.value
                                                  ),
                                                });
                                              }}
                                            >
                                              <option value={null}>
                                                Select state{" "}
                                              </option>
                                              {this.state.states ? (
                                                this.state.states.map((s, i) => {
                                                  return (
                                                    <option value={s.id}>
                                                      {s.name}
                                                    </option>
                                                  );
                                                })
                                              ) : (
                                                <option value={null}>
                                                  States not fetched
                                                </option>
                                              )}
                                            </select>
                                          </div>
                                        )}
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row></Row>
                                <Row>
                                  <Col style={{ padding: "0" }}>
                                    <Form.Text
                                      style={{ marginBottom: "10px" }}
                                      className="text-muted"
                                    >
                                      {this.state.errorEditMessage && (
                                        <div
                                          style={{ color: "red" }}
                                          className="error"
                                        >
                                          {this.state.errorEditMessage}
                                        </div>
                                      )}
                                      {this.state.succesEditMessage && (
                                        <div
                                          style={{ color: "green" }}
                                          className="succesmsg"
                                        >
                                          {this.state.succesEditMessage}
                                        </div>
                                      )}
                                    </Form.Text>
                                    <div className="frombtn">
                                      {this.state.savebtn ? (
                                        <Bluebtn
                                          value="Save"
                                          type="button"
                                          onClick={this.Editbtn}
                                          spinner={
                                            this.state.showSpinner && (
                                              <i className="fa fa-circle-o-notch fa-spin"></i>
                                            )
                                          }
                                        />
                                      ) : null}
                                    </div>
                                  </Col>
                                </Row>




                                
                              
                              </div>
                            </Tab>
                            <Tab
                              eventKey="ChangePassword"
                              title="CHANGE PASSWORD"
                            >
                              <ChangePassword />
                            </Tab>
                          </Tabs>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Placeaddsm />
              </Col>
            </Row>
          </Form>
        </div>
      </Aux>
    );
  }
}
export default Profile;
