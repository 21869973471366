import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Menu from "../screens/includes/Navbar";
import Aux from "../hoc/_Aux";
import { Container, Row, Col, Form } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { globalVariable } from "../utils/global";
import { consts } from "../utils/validation";
import { useNavigate } from "react-router";
import Input from "../components/Input";
import Bluebtn from "../components/Bluebtn";

const eyeopen =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye.png";
const eyeclose =  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/eye-close.png";
//import { ReactComponent as Checkmark } =  "https://d7le4ielk3ho1.cloudfront.net/src_images/checkmark.svg";

function ResetPassword() {
  function handlepassword(e) {
    setPassword(e.target.value);
    setErrPassword("");
  }
  function handleconfirmpassword(e) {
    setConfirmPassword(e.target.value);
    setErrConfirmPassword("");
  }
  useEffect(() => {
    document.title = "Reset Password";
  }, []);

  const [password, setPassword] = useState();

  const [confirmPassword, setConfirmPassword] = useState();
  const [eyestate, setEyestate] = useState(false);
  const [inputconfirmpasswordtype, setInputconfirmpasswordtype] =
    useState(false);
  const [eyeconfirmstate, setEyeconfirmstate] = useState(false);
  const [inputpasswordtype, setInputpasswordtype] = useState(false);
  const [errPassword, setErrPassword] = useState();
  const [errConfirmPassword, setErrConfirmPassword] = useState();
  const [showSpinner, setshowSpinner] = useState(null);

  let [responseData, setResponseData] = React.useState("");
  let [responseDataB, setResponseDataB] = React.useState("");

  let { forgottoken } = useParams();
  console.warn(forgottoken);
  const navigate = useNavigate();

  function passwordshowhide() {
    if (!eyestate) setEyestate(true);
    else setEyestate(false);
    if (!inputpasswordtype) setInputpasswordtype(true);
    else setInputpasswordtype(false);
  }
  function confirmpasswordshowhide() {
    if (!eyeconfirmstate) setEyeconfirmstate(true);
    else setEyeconfirmstate(false);
    if (!inputconfirmpasswordtype) setInputconfirmpasswordtype(true);
    else setInputconfirmpasswordtype(false);
  }

  function validation() {
    let formIsValid = true;

    if (password == "" || password == null) {
      setErrPassword("Please eanter password");
      formIsValid = false;
    }
    if (confirmPassword == "" || confirmPassword == null) {
      setErrConfirmPassword("Please enter confirm password");
      formIsValid = false;
    }
    if (password !== confirmPassword) {
      setErrConfirmPassword("Password and Confirm Password must match");
      formIsValid = false;
    }
    if (
      password == null ||
      (password == "" && confirmPassword == null) ||
      confirmPassword == ""
    ) {
      setErrPassword("Please enter password");
      setErrConfirmPassword("plese enter confirm password");
      formIsValid = false;
    }
    return formIsValid;
  }

  function resetPass() {
    if (validation()) {
      setErrPassword(null);
      setErrConfirmPassword(null);
      console.warn(password, confirmPassword);
      let item = { password, confirmPassword };

      let BaseUrl =
        globalVariable.baseURL + "manage-user/resetPassword/" + forgottoken;

      fetch(BaseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(item),
      }).then((response) => {
        response.json().then((result) => {
          console.warn(result);
          if (result.code === 200) {
            // alert(result.message);
            setResponseData(result);
            setResponseDataB(false);
            setErrConfirmPassword(null);
            setErrPassword(null);
            setTimeout(() => {
             navigate({
                pathname: "/",
              });
            }, 2500);
          } else {
            setErrConfirmPassword(null);
            setErrPassword(null);
            // alert(result.message);
            setResponseDataB(result);
            console.log(result);
            setResponseData(false);
            // setTimeout(() => {
            //   history.push({
            //     pathname: "/",
            //   });
            // }, 1500);
          }
        });
      });
    }
  }

  return (
    <div>
      <Menu />
      <div className="Maincontent">
        <div className="reset-pass-container">
          <Container>
            <Row>
              <div className="col-12">
                <div className="reset-outer-wrap">
                  {responseData && (
                    <p
                      style={{
                        color: "green",
                        textAlign: "center",
                        marginTop: "1rem",
                        marginBottom: "0",
                        fontFamily: "industry-bold",
                      }}
                    >
                      "{responseData && responseData.message}"
                      {responseData &&
                        responseData.data &&
                        responseData.message}
                    </p>
                  )}
                  {responseDataB && (
                    <p
                      style={{
                        color: "red",
                        textAlign: "center",
                        marginTop: "1rem",
                        marginBottom: "0",
                        fontFamily: "industry-bold",
                      }}
                    >
                      "{responseDataB && responseDataB.message}"
                      {responseDataB &&
                        responseDataB.data &&
                        responseDataB.message}
                    </p>
                  )}
                  <div className="reset-inner-wrap">
                    <h1>Reset Password</h1>
                    <Form.Group>
                      <div className="password_b">
                        <Input
                          id="password"
                          onChange={handlepassword}
                          label="New Password"
                          placeholder="Enter New Password"
                          type={inputconfirmpasswordtype ? "text" : "password"}
                        />
                        <span
                          className="password__show"
                          onClick={confirmpasswordshowhide}
                        >
                          {eyeconfirmstate ? (
                            <img src={eyeopen} default="open" />
                          ) : (
                            <img src={eyeclose} default="close" />
                          )}{" "}
                        </span>
                      </div>

                      <Form.Text
                        style={{ marginBottom: "10px" }}
                        className="text-muted"
                      >
                        <div className="error">{errPassword}</div>
                      </Form.Text>
                    </Form.Group>
                    <Form.Group>
                      <div className="password_b">
                        <Input
                          id="confirmPassword"
                          onChange={handleconfirmpassword}
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          type={inputpasswordtype ? "text" : "password"}
                        />
                        <span
                          className="password__show"
                          onClick={passwordshowhide}
                        >
                          {eyestate ? (
                            <img src={eyeopen} default="open" />
                          ) : (
                            <img src={eyeclose} default="close" />
                          )}{" "}
                        </span>
                      </div>
                      <Form.Text
                        style={{ marginBottom: "10px" }}
                        className="text-muted"
                      >
                        <div className="error">{errConfirmPassword}</div>
                      </Form.Text>
                    </Form.Group>
                    <div className="frombtn">
                      <Bluebtn
                        onClick={resetPass}
                        value="Reset Password"
                        type="submit"
                        spinner={
                          showSpinner && (
                            <i className="fa fa-circle-o-notch fa-spin"></i>
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
