import React from "react";
import Aux from "../hoc/_Aux";
import Menu from "../screens/includes/Navbar";
import { withRouter } from "../components/withRouter";
import Placeadd from "../components/Placeadd";
import { Row, Col, Container } from "react-bootstrap";
import Placebigadd from "../components/Placeyouraddbigbox";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Placeaddsm from "../components/Placeaddsm";
import ProfileBaner from "../components/Profile-hero";
import Faqaccordian from "../components/Faqs";
import FaqInvite from "../components/accordion-invite";

const profileheromobile =
  "https://d7le4ielk3ho1.cloudfront.net/src_images/mobileview/terms-banner-mobile.jpg";
const profilehero =
  "https://d7le4ielk3ho1.cloudfront.net/src_images/Images_Assets/term-banner.jpg";

class TermsCondition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      type: "input",
    };
    this.showHide = () => this.showHide.bind(this);
  }

  closelogin = () => {
    this.setState({ importModal: false });
  };
  componentDidMount() {
    document.title = "NCR Rugby | Terms & Condition";
  }
  render() {
    return (
      <Aux>
        <Menu />
        <div className="Maincontent">
          <Row>
            <Col md="12">
              <div className="leaderord headerAdvt">
                <Placeadd />
              </div>
            </Col>
          </Row>
          <Row>
            <ProfileBaner
              imgmobile={profileheromobile}
              img={profilehero}
              text="TERMS & CONDITIONS"
              alt="deafult"
            />
          </Row>
          <Row className="deatilstext" style={{ backgroundColor: "#eeeeee" }}>
            <Col className="container">
              <div className="textandadd">
                <div className="Aboutus">
                  <p className="blackpad">
                    To enter this Promotion, you must agree to and abide by
                    these official rules (<strong>"Official Rules"</strong>).
                  </p>

                  <p className="blackpad">
                    <strong>
                      NO PURCHASE NECESSARY TO ENTER, WIN OR CLAIM A PRIZE.
                    </strong>{" "}
                    THE FOLLOWING PROMOTION IS INTENDED FOR VIEWING IN THE
                    UNITED STATES, INCLUDING THE DISTRICT OF COLUMBIA, AND
                    EVALUATED ACCORDING TO UNITED STATES LAW. DO NOT PROCEED
                    WITH THIS PROMOTION IF YOU ARE NOT LOCATED IN THE UNITED
                    STATES, DISTRICT OF COLUMBIA OR IF YOU ARE LOCATED IN RHODE
                    ISLAND. THIS PROMOTION IS IN NO WAY SPONSORED, ENDORSED OR
                    ADMINISTERED BY FACEBOOK, INSTAGRAM OR TWITTER.
                  </p>

                  <p className="blackpad">
                    THE OBJECTIVE OF THE PROMOTION IS TO ACCUMULATE THE MOST
                    POINTS BY CORRECTLY SELECTING THE "WINNING TEAM" FOR EACH OF
                    THE SIXTY-FOUR (64) MEN'S AND THIRTY TWO (32) WOMEN’S
                    COLLEGE RUGBY GAMES ASSOCIATED WITH THE COLLEGIATE RUGBY
                    CHAMPIONSHIP.
                  </p>
                  <p className="blackpad">
                    <strong className="p-green-bold">1. ADMINISTRATOR</strong>{" "}
                    <br />
                    This promotion will be administered by National Collegiate
                    Rugby Championships&nbsp;
                    <strong>
                      Delaware Limited Liability Corporation (the
                      "Administrator"), 24A Trolley Square #1365 Wilmington, DE
                      19806-3334.
                    </strong>{" "}
                  </p>
                  <p className="blackpad">
                    <strong className="p-green-bold">2. ELIGIBILITY</strong>{" "}
                    <br />
                    The 2024 Men’s and Women’s Bracket Challenge (the{" "}
                    <strong>"Promotion"</strong>) is open only to legal
                    residents of the United States, with the exception of legal
                    residents of Rhode Island. The Promotion is open to those
                    who are at least eighteen (18) years or older (or the age of
                    majority in their jurisdiction of residence, whichever is
                    older) as of the date of entry. The following are not
                    eligible to participate in the Promotion or win the prizes:
                    (i) employees and agents of Administrator, Promoter,
                    Administrator, each of their respective parent, subsidiary
                    and affiliated companies, and each of their respective
                    advertising/promotion agencies; and (ii) the&nbsp;"
                    <strong>immediate family members</strong>" and/or "household
                    members" of any of the persons set forth in (i) above.
                    "Immediate family members" shall mean parents, step-parents,
                    children, step-children, siblings or spouses. "
                    <strong>Household members</strong>" shall mean people who
                    share the same residence at least three (3) months a year.
                    In addition, persons who are currently involved in any
                    capacity in any collegiate rugby program (including but not
                    limited to college rugby players, coaches and their staffs)
                    are not eligible to participate in the Promotion or win the
                    Prizes.
                  </p>
                  <p className="blackpad">
                    Participation in the Promotion constitutes entrant's full
                    and unconditional agreement to these Official Rules and to
                    the Administrators decisions and interpretations. The
                    decisions of the Administrators regarding all matters
                    relating to the Promotion are final and binding. Winning the
                    <strong>Prize</strong> is contingent upon fulfilling all
                    requirements set forth herein. From all eligible entries
                    received, the top highest scoring entry that have
                    accumulated points in this Promotion during the 2024
                    Collegiate Rugby Championship Tournament taking place in
                    April 26-28 (the "<strong>Tournament</strong>") This
                    Promotion is void in all U.S. Territories (e.g., Puerto
                    Rico, Rhode Island and Guam), foreign territories not
                    specifically included herein, and where prohibited or
                    restricted by law and subject to all applicable federal,
                    state, local and municipal laws and regulations.
                  </p>
                  <p className="blackpad">
                    <strong className="p-green-bold">3. TIMING</strong>
                    <br />
                    Entry in the Promotion begins on Saturday, April 22, 2023 at
                    8:00pm EST and ends on April 28, 2023 immediately preceding
                    the kick-off of the first game of the first round game of
                    the Tournament ("
                    <strong>Entry Period</strong>"). All entries must be
                    received during the Entry Period. The first round of the
                    Tournament is expected to begin on Sunday, April 30, 2023
                    and the championship game is expected to be played on
                    Monday, May 30, 2022 (or upon the completion of the final
                    game of the Tournament) (the "
                    <strong>Tournament Period</strong>"). For purposes of these
                    Official Rules, all times and days are Eastern Time.
                    Administrator computer is the official time-keeping device
                    for the Promotion.
                  </p>
                  <p className="blackpad">
                    <strong className="p-green-bold">
                      4. REGISTRATION & ENTRY
                    </strong>{" "}
                    <br />
                    You must be a registered Bracket Challenge member ("
                    <strong>Member</strong>") to enter the Promotion.
                    Registration is free and no purchase is needed to enter, win
                    or claim a Prize. To enter, visit either: (1)
                    <a href="https://www.collegiaterugbychampionship.com/">
                      https://www.collegiaterugbychampionship.com
                    </a>{" "}
                    ("<strong>Website</strong>"); The Website, referred to
                    herein as, the "<strong>Entry Methods</strong>". To enter
                    via one of the Entry Methods, select the "Enter" button. You
                    will need to log in with your Member username or e-mail
                    address and password; or, if you are not a registered
                    Member, you will need to create an account. If you are not a
                    Member, you may become a Member for free by visiting the
                    Website, clicking on the profile icon, selecting the "LogIn"
                    link and selecting the "Sign Up" link and providing the
                    requested information on the registration form to create an
                    account (such as his/her first and complete last name (no
                    initials), valid email address, phone number, street
                    address, city, state, and zip code).
                  </p>
                  <p className="blackpad">
                    If you become a Member but are not eligible for the
                    Promotion as per Rule 2 above (including persons who are
                    under 18 or the legal age of majority in their jurisdiction
                    of residence; persons who are legal residents of Rhode
                    Island Puerto Rico and other United States territories and
                    foreign territories or Quebec) may register for and take
                    part in the Promotion "just for fun" (i.e., for
                    entertainment purposes) but cannot win any prize.
                  </p>
                  <p className="blackpad">
                    You may be asked to consent to receive promotional emails or
                    offers. This is optional and does not affect your chances of
                    winning the prizes in this Promotion.
                  </p>
                  <p className="blackpad">
                    You are NOT required to give consent to receive such emails
                    or offers in order to participate in the Promotion or win
                    any prize.
                  </p>
                  <p className="blackpad">
                    Once you are logged in, you will be automatically sent back
                    to the entry page on the applicable Entry Method.
                  </p>
                  <p className="blackpad">
                    Limit of one (10) entry per person/Member during the Entry
                    Period, regardless of Entry Methods used and regardless of
                    the number of email addresses or registered Member accounts.
                    <strong>
                      Whether you enter via the Website, there is a limit of 10
                      (10) total entry per person for each men’s and women’s
                      bracket for this Promotion.
                    </strong>{" "}
                    Multiple entries in violation of the preceding limit and/or
                    mass entry attempts, as determined by the Administrator in
                    its sole discretion, will result in disqualification of all
                    entries by such person/Member and perhaps legal and/or other
                    sanctions. Entries must be manually key-stroked by the
                    entrant; use of automated entry devices or programs, or
                    entries by third parties are prohibited. Entries that do not
                    conform to or that violate these Official Rules or are
                    submitted in any manner other than stated in these Official
                    Rules will be disqualified. By entering this Promotion,
                    entrants agree to these Official Rules and the decisions of
                    the Administrator, which are binding and final in all
                    matters relating to this Promotion.
                  </p>
                  <p className="blackpad">
                    <strong>*IMPORTANT:</strong> Data rates may apply if
                    Promotion entry is submitted via your mobile device
                    according to the terms and conditions of your service
                    agreement with your wireless carrier. Other charges may
                    apply (such as normal airtime and carrier charges as well as
                    charges for wireless Internet access) and may appear on your
                    mobile phone bill or be deducted from your prepaid account
                    balance. Wireless carriers' rate plans may vary, and you
                    should contact your wireless carrier for more information on
                    messaging/data rate plans and charges relating to your
                    participation in this Promotion. Mobile device service may
                    not be available in all areas. Check your device's
                    capabilities for specific instructions. Mobile entry is not
                    required to enter to win a Prize.
                  </p>
                  <p className="p-green-bold">5. HOW TO PLAY</p>
                  <p className="blackpad">
                    <a href="/how-to-play">How To Play</a>
                  </p>
                  {/* <p className="blackpad">
                    b. How to make your selections. You may enter your
                    selections by completing a Tournament bracket following the
                    posted instructions.
                  </p>
                  <p className="blackpad">
                    c. Scoring. From all eligible brackets received, the Prize
                    winner will be determined based on their ability to
                    accumulate the most points during the Tournament as a whole.
                    You may change your selections up the Lock Time as described
                    below. In order to maximize the opportunity to accrue the
                    most points, register and submit your bracket no later than
                    the Lock Time. If no selections are submitted for a game
                    prior to{" "}
                  </p>
                  <p className="blackpad">
                    the Lock Time, no points will be received relative to such
                    corresponding game. Points for a correct pick in a given
                    round in the Tournament is shown below:
                  </p>

                  <div className="leaderboard-leftinner">
                    <div className="table-scroll-points">
                      <table className="table">
                        <thead>
                          <tr>
                            <th
                              style={{
                                textAlign: "left",
                                border: "none",
                                background: "white",
                              }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span className="grey">Men's Tournament</span>
                            </th>
                          </tr>
                          <tr>
                            <th>Round</th>
                            <th>TEAMS</th>
                            <th>GAMES</th>
                            <th>POINTS(each)</th>
                            <th>POINTS(total)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- First point table --> 
                          <tr>
                            <th colSpan="2" className="greybg">
                              <span className="blue">Premier Cup Bracket</span>
                            </th>
                          </tr>
                          <tr>
                            <td>First Round</td>
                            <td>32</td>
                            <td>16</td>
                            <td>15</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Second Round</td>
                            <td>16</td>
                            <td>8</td>
                            <td>30</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Quarterfinals </td>
                            <td>30</td>
                            <td>30</td>
                            <td>30</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Semi Finals </td>
                            <td>4</td>
                            <td>2</td>
                            <td>120</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>National Championship </td>
                            <td>2</td>
                            <td>1</td>
                            <td>240</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>3rd Place</td>
                            <td>2</td>
                            <td>1</td>
                            <td>120</td>
                            <td>120</td>
                          </tr>
                          <tr>
                            <td className="points-total">Total</td>
                            <td className="points-total">---</td>
                            <td className="points-total">32</td>
                            <td className="points-total">---</td>
                            <td className="points-total">1320</td>
                          </tr>

                           <!--second point table -->
                          <tr>
                            <td colSpan="2" className="greybg">
                              <span className="blue">
                                Survivors Shield Bracket
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>First Round</td>
                            <td>32</td>
                            <td>16</td>
                            <td>15</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Second Round</td>
                            <td>16</td>
                            <td>8</td>
                            <td>30</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Quarterfinals </td>
                            <td>8</td>
                            <td>4</td>
                            <td>60</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Semi Finals </td>
                            <td>4</td>
                            <td>2</td>
                            <td>120</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>National Championship </td>
                            <td>2</td>
                            <td>1</td>
                            <td>240</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>3rd Place</td>
                            <td>2</td>
                            <td>1</td>
                            <td>120</td>
                            <td>120</td>
                          </tr>
                          <tr>
                            <td className="points-total">Total</td>
                            <td className="points-total">---</td>
                            <td className="points-total">16</td>
                            <td className="points-total">---</td>
                            <td className="points-total">1080</td>
                          </tr>

                          <!-- Third point table -->
                          <tr>
                            <td colSpan="2" className="greybg">
                              <span className="blue">
                                CHALLENGE  SHIELD
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>First Round</td>
                            <td>8</td>
                            <td>4</td>
                            <td>60</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Second Round</td>
                            <td>4</td>
                            <td>2</td>
                            <td>120</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Quarterfinals </td>
                            <td>2</td>
                            <td>1</td>
                            <td>240</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Semi Finals </td>
                            <td>4</td>
                            <td>2</td>
                            <td>120</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Challenge Bowl </td>
                            <td>2</td>
                            <td>1</td>
                            <td>240</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>3rd Place</td>
                            <td>2</td>
                            <td>1</td>
                            <td>120</td>
                            <td>120</td>
                          </tr>
                          <tr>
                            <td className="points-total">Total</td>
                            <td className="points-total">---</td>
                            <td className="points-total">8</td>
                            <td className="points-total">---</td>
                            <td className="points-total">840</td>
                          </tr>
                          <!-- fourth point table -->
                          <tr>
                            <td colSpan="2" className="greybg">
                              <span className="blue">
                                Champions Plate BracketCup
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ textAlign: "left" }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>2ND Round Losers</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ textAlign: "left" }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>Premier Cup</span>
                            </td>
                          </tr>
                          <tr>
                            <td>First Round</td>
                            <td>8</td>
                            <td>4</td>
                            <td>60</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Second Round</td>
                            <td>4</td>
                            <td>2</td>
                            <td>120</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Quarterfinals </td>
                            <td>2</td>
                            <td>1</td>
                            <td>240</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Semi Finals </td>
                            <td>4</td>
                            <td>2</td>
                            <td>120</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Champions Plate </td>
                            <td>2</td>
                            <td>1</td>
                            <td>240</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>3rd Place</td>
                            <td>2</td>
                            <td>1</td>
                            <td>120</td>
                            <td>120</td>
                          </tr>
                          <tr>
                            <td className="points-total">Total</td>
                            <td className="points-total">---</td>
                            <td className="points-total">8</td>
                            <td className="points-total">---</td>
                            <td className="points-total">840</td>
                          </tr>
                          <tr>
                            <td className="points-total">Total</td>
                            <td className="points-total">---</td>
                            <td className="points-total">32</td>
                            <td className="points-total">---</td>
                            <td className="points-total">1320</td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "white",
                              }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>Best Score Possible:</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "white",
                              }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>Premier Cup - 1320</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "white",
                              }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>Survivors Shield - 1080</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "white",
                              }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>Champions Plate - 840</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "white",
                              }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>Challenge Bowl - 840</span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              backgroundColor: "white",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "white",
                              }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>TOTAL - 4080</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="leaderboard-leftinner">
                    <div className="table-scroll-points">
                      <table className="table">
                        <thead>
                          <tr>
                            <th
                              style={{
                                textAlign: "left",
                                border: "none",
                                background: "white",
                              }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span className="grey">Women's Tournament</span>
                            </th>
                          </tr>
                          <tr>
                            <th colSpan="2" className="greybg">
                              <span className="blue">Premier Cup Bracket</span>
                            </th>
                          </tr>
                          <tr>
                            <th>Round</th>
                            <th>TEAMS</th>
                            <th>GAMES</th>
                            <th>POINTS(each)</th>
                            <th>POINTS(total)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- First point table -->
                          <tr>
                            <td>First Round</td>
                            <td>16</td>
                            <td>8</td>
                            <td>30</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Second Round</td>
                            <td>8</td>
                            <td>4</td>
                            <td>60</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Quarterfinals </td>
                            <td>4</td>
                            <td>2</td>
                            <td>120</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Semi Finals </td>
                            <td>4</td>
                            <td>2</td>
                            <td>120</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Survivors Shield</td>
                            <td>2</td>
                            <td>1</td>
                            <td>240</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>3rd Place</td>
                            <td>2</td>
                            <td>1</td>
                            <td>120</td>
                            <td>120</td>
                          </tr>
                          <tr>
                            <td className="points-total">Total</td>
                            <td className="points-total">---</td>
                            <td className="points-total">16</td>
                            <td className="points-total">---</td>
                            <td className="points-total">1080</td>
                          </tr>

                          <!-- second point table -->
                          <tr>
                            <td colSpan="2" className="greybg">
                              <span className="blue">
                                Survivors Shield Bracket
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ textAlign: "left", background: "white" }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>1ST Round Losers</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ textAlign: "left", background: "white" }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>Premier Cup</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ textAlign: "left", background: "white" }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>ROUND</span>
                            </td>
                          </tr>
                          <tr>
                            <td>First Round</td>
                            <td>32</td>
                            <td>16</td>
                            <td>15</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Second Round</td>
                            <td>16</td>
                            <td>8</td>
                            <td>30</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Quarterfinals </td>
                            <td>30</td>
                            <td>30</td>
                            <td>30</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Semi Finals </td>
                            <td>4</td>
                            <td>2</td>
                            <td>120</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Champions Plate </td>
                            <td>2</td>
                            <td>1</td>
                            <td>240</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>3rd Place</td>
                            <td>2</td>
                            <td>1</td>
                            <td>120</td>
                            <td>120</td>
                          </tr>
                          <tr>
                            <td className="points-total">Total</td>
                            <td className="points-total">---</td>
                            <td className="points-total">8</td>
                            <td className="points-total">---</td>
                            <td className="points-total">840</td>
                          </tr>

                          <!-- Third point table -->
                          <tr>
                            <td colSpan="2" className="greybg">
                              <span className="blue">
                                CHALLENGE  SHIELD
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ textAlign: "left", background: "white" }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>1ST Round Losers</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ textAlign: "left", background: "white" }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>Survivors Cup</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ textAlign: "left", background: "white" }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>ROUND</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Semi Finals </td>
                            <td>4</td>
                            <td>2</td>
                            <td>120</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Challenge Bowl </td>
                            <td>2</td>
                            <td>1</td>
                            <td>240</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>3rd Place</td>
                            <td>2</td>
                            <td>1</td>
                            <td>120</td>
                            <td>240</td>
                          </tr>

                          <tr>
                            <td className="points-total">Total</td>
                            <td className="points-total">---</td>
                            <td className="points-total">4</td>
                            <td className="points-total">---</td>
                            <td className="points-total">600</td>
                          </tr>
                          <!-- fourth point table -->
                          <tr>
                            <td colSpan="2" className="greybg">
                              <span className="blue">
                                Champions Plate BracketCup
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ textAlign: "left", background: "white" }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>2ND Round Losers</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ textAlign: "left", background: "white" }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>Premier Cup</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ textAlign: "left", background: "white" }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>ROUND</span>
                            </td>
                          </tr>
                          <tr>
                            <td>Semi Finals</td>
                            <td>4</td>
                            <td>2</td>
                            <td>120</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Second Round</td>
                            <td>2</td>
                            <td>1</td>
                            <td>240</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>Challenge Bowl </td>
                            <td>2</td>
                            <td>1</td>
                            <td>120</td>
                            <td>240</td>
                          </tr>
                          <tr>
                            <td>3rd Place</td>
                            <td>4</td>
                            <td>2</td>
                            <td>120</td>
                            <td>240</td>
                          </tr>

                          <tr>
                            <td className="points-total">Total</td>
                            <td className="points-total">---</td>
                            <td className="points-total">4</td>
                            <td className="points-total">---</td>
                            <td className="points-total">600</td>
                          </tr>

                          <tr>
                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "white",
                              }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>Best Score Possible:</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "white",
                              }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>Premier Cup - 1080</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "white",
                              }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>Survivors Shield - 840</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "white",
                              }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>Champions Plate - 600</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "white",
                              }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>Challenge Bowl - 600</span>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                textAlign: "left",
                                backgroundColor: "white",
                              }}
                              colSpan="2"
                              className="greybg"
                            >
                              <span>TOTAL - 3120</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <p className="blackpad">
                    In the event a Tournament rugby game does not occur as
                    scheduled (e.g., due to cancellation) no points will be
                    awarded for that game.
                  </p>
                  <p className="blackpad">
                    d. Lock-time. All picks will lock at the scheduled kick off
                    time of the first game of the first round of the Tournament
                    currently scheduled for Saturday, May 28, 2022 (the "
                    <strong>Lock Time</strong>"). Your picks for the Tournament
                    rugby games must be submitted, received and recorded by the
                    Administrators computer by the scheduled Lock Time.
                    Selections submitted after the Lock Time are considered
                    invalid. the Administrator’s computer is the official
                    time-keeping device for this Promotion.
                  </p>
                  <p className="blackpad">
                    e. Championship Game Score Prediction. Entrants may, but are
                    not required to, submit a predicted score for the
                    championship game in order to determine the entrant's "
                    <strong>Tiebreaker Game Score</strong>" as explained below.
                    If no predicted score is submitted for an entry, said entry
                    will not be included in the Tiebreaker Game Score.
                  </p>
                  <p className="blackpad">
                    <strong>*NOTE</strong> - TIEBREAKER GAME SCORES WILL BE USED
                    FOR LEADERBOARDS AND ARE SOLELY FOR ENTERTAINMENT PURPOSES.
                    LEADERBOARD RANKINGS DO NOT AFFECT YOUR CHANCE OF WINNING
                    THE PRIZE.
                  </p>
                  <p className="blackpad">f. TIEBREAKERS:</p>
                  <p className="blackpad">
                    • Tiebreaker #1 - An entrant's predicted score for the
                    championship game is compared to the actual score of the
                    championship game. The difference between the total amount
                    of points scored in the Tournament's championship game and
                    the total amount of points in the entrant's predicted score
                    determines an entrant's Tiebreaker Game Score. The entry
                    with the smallest difference is considered the highest
                    ranked entry.
                  </p>
                  <p className="blackpad">
                    EXAMPLE: Entrant A submits a prediction of 28-25 (i.e., 53
                    combined points scored), and entrant B submits a prediction
                    of 30-28 (i.e., 58 combined points scored). The actual
                    championship game's total score is 35-30 (i.e., 65 combined
                    points). In this example, entrant A would have a Tiebreaker
                    Game Score of 12 (because the difference between entrant A's
                    predicted total points and the actual total points scored is
                    12), and entrant B's Tiebreaker Game Score would be 7
                    (because the difference between entrant B's predicted total
                    points and the actual total points scored is 7). In this
                    scenario, entrant B wins the tie, as entrant B has the
                    smaller absolute value difference between their predicted
                    total score and the actual total score.
                  </p>
                  <p className="blackpad">
                    • Tiebreaker #2 - Highest number of points scored in Round
                    1.
                  </p>
                  <p className="blackpad">
                    • Tiebreaker #3 - Highest number of points scored in Round
                    2.
                  </p>
                  <p className="blackpad">
                    • Tiebreaker #4 - Highest number of points scored in Round
                    3.
                  </p>
                  <p className="blackpad">
                    • Tiebreaker #5 - Highest number of points scored in Round
                    4.
                  </p>
                  <p className="blackpad">
                    • Tiebreaker #6 - Highest number of points scored in Round
                    5.
                  </p>
                  <p className="blackpad">
                    • Tiebreaker #7 - The entrant whose date of corresponding
                    entry creation was recorded in the system the earliest.
                  </p>
                  <p className="blackpad">
                    <strong>
                      *NOTE: TIEBREAKERS WILL BE USED FOR LEADERBOARDS AND ARE
                      SOLELY FOR ENTERTAINMENT PURPOSES. LEADERBOARD RANKINGS DO
                      NOT AFFECT YOUR CHANCE OF WINNING THE PRIZE.
                    </strong>
                  </p> */}
                  {/* <p className="blackpad">
                    <strong className="p-green-bold">
                      6. INTERRUPTION/CANCELLATION OF 2022 MEN'S COLLEGE RUGBY
                      TOURNAMENT DUE TO COVID-19/CORONAVIRUS
                    </strong>{" "}
                    <p className="blackpad">
                      ENTRANTS UNDERSTAND AND AGREE THAT, DUE TO
                      COVID-19/CORONAVIRUS, THE TOURNAMENT MAY NOT BE CONDUCTED
                      AS ORIGINALLY SCHEDULED.
                    </p>
                  </p>

                  <p className="blackpad">
                    IF THE TOURNAMENT IS CANCELLED AFTER THE FULL CONCLUSION OF
                    AT LEAST THE FIRST ROUND (I.E., THE ROUND OF 32), THE
                    PROMOTION WILL CONSIST OF ONLY THOSE ROUND(S) COMPLETED AND
                    AWARD OF THE PRIZE WILL BE DETERMINED BASED ONLY ON
                    COMPLETED ROUND(S) OF THE TOURNAMENT. IN SUCH EVENT, THE
                    ENTRANTS WHO HAVE ACCUMULATED THE TOP SCORES FOR THE OVERALL
                    TOURNAMENT UP TO THE TIME OF SUCH{" "}
                  </p>
                  <p className="blackpad">
                    CANCELLATION WILL BE ENTERED INTO THE RANDOM DRAWING FOR THE
                    PRIZE. IF THE TOURNAMENT IS CANCELLED DURING A GIVEN ROUND
                    (WITH NOT ALL SCHEDULED GAMES FOR THAT ROUND BEING PLAYED TO
                    CONCLUSION), ONLY THOSE GAMES PLAYED TO CONCLUSION FOR SUCH
                    ROUND WILL BE SCORED WITH ALL OTHER GAMES BEING DISCOUNTED.
                  </p>
                  <p className="blackpad">
                    IF THE TOURNAMENT IS MODIFIED OR POSTPONED, THE
                    ADMINISTRATOR WILL ENDEAVOR TO CONDUCT THE PROMOTION AS
                    ORIGINALLY INTENDED BY SCORING COMPLETED GAMES AND MODIFYING
                    THE ROUNDS FOR WHICH SUCH SCORES ARE COUNTED AS
                    ADMINISTRATOR DEEMS APPROPRIATE IN ITS SOLE DISCRETION.
                    WITHOUT LIMITING THE FOREGOING, ADMINISTRATOR RESERVES THE
                    RIGHT, IN ITS SOLE DISCRETION, TO CONTRACT OR EXTEND THE
                    TIMING OF ONE OR MORE ROUNDS AND/OR THE PROMOTION OVERALL,
                    DUE TO THE TOURNAMENT BEING SUSPENDED OR PAUSED.
                  </p>
                  <p className="blackpad">
                    NOTICE OF SUCH ACTIONS BY ADMINISTRATOR WILL BE POSTED ON
                    THE WEBSITE.
                  </p> */}
                  <p className="blackpad">
                    <strong className="p-green-bold">
                      6. PRIZE WINNER DETERMINATION/NOTIFICATION
                    </strong>{" "}
                    <br />
                    Administrator will review all eligible entries received
                    during the Entry Period overall to determine the top highest
                    scoring entry from among the entire entry pool. There is no
                    entry fee required to participate in the Promotion. Only
                    entries received online in accordance with these Official
                    Rules will be accepted. No other forms of entry - fax, mail,
                    email, phone or other - will be accepted. Entry
                    materials/data that have been tampered with or altered are
                    void. The Prize winner will be determined on or about
                    Wednesday, May 1, 2024.
                  </p>
                  <p className="blackpad">
                    <strong className="p-green-bold">7. PRIZE</strong>
                    <p className="blackpad">
                      <strong>1st PLACE PRIZE HIGHEST SCORE</strong>{" "}
                    </p>
                    <p className="blackpad">
                      <strong> 2nd PLACE PRIZE SECOND HIGHEST SCORE</strong>
                    </p>
                  </p>

                  <p className="blackpad">
                    1st PLACE PRIZE (FOUR (4) WEEKEND VIP passes to the 2024 CRC
                    $2,200 value ) will be awarded to the participant that
                    scores the highest score in either the men’s or women’s
                    bracket challenge. 2nd PLACE PRIZE (TWO (2) VIP passes to
                    the CRC 2024 $1,100.00 value) All prizes are
                    non-transferable.
                  </p>
                  <p className="blackpad">
                    <strong className="p-green-bold">
                      {" "}
                      8. ENTRY ERRORS/ NO RETURN OF ENTRIES; DISPUTES
                    </strong>
                    <br />
                    Neither Administrator nor Promoters are responsible for
                    lost, late, incomplete, damaged, stolen, invalid,
                    unintelligible or misdirected entries, which will be
                    disqualified. Neither Administrator nor Promoters are
                    responsible for any unavailability of or interruptions to
                    any service or equipment used in connection with the
                    Promotion, including, without limitation, (1) interruptions
                    to any network, server, Internet, website, telephone,
                    satellite, computer or other connections (2) failures of any
                    telephone, satellite, hardware, software or other equipment,
                    (3) garbled, misdirected or jumbled transmissions, or
                    traffic congestion, or (4) other errors of any kind, whether
                    human, technical, mechanical or electronic, or (5) the
                    incorrect or inaccurate capture of entry or other
                    information or the failure to capture any such information.
                    Once submitted, entries become the sole property of the
                    Administrator and will not be acknowledged or returned. In
                    the event of a dispute as to any entry, the authorized
                    account holder at the time of entry will be deemed to be the
                    entrant. Potential winners may be required to show proof of
                    identification, and Administrator may require that the
                    potential winner provide proof that his/her identification
                    matches the User Information associated with the winning
                    entry (both at the time of entry and at the time of
                    verification of a potential winner). ADMINISTRATOR RESERVES
                    THE RIGHT TO DISQUALIFY ANY POTENTIAL WINNER IF IT
                    DETERMINES, IN ITS SOLE DISCRETION, THAT ANY USER
                    INFORMATION WAS CHANGED OR FALSIFIED IN ORDER TO MEET
                    ELIGIBILITY REQUIREMENTS. The decisions of the professional
                    referees officiating the games shall be final and binding
                    regarding the outcome and scoring of the game.
                  </p>
                  <p className="blackpad">
                    <strong className="p-green-bold">
                      9. POTENTIAL WINNER. ALL POTENTIAL WINNING ENTRIES ARE
                      SUBJECT TO VERIFICATION BY ADMINISTRATOR, WHOSE DECISIONS
                      ARE FINAL. ADMINISTRATOR SHALL SOLELY DETERMINE ANY FORM
                      OF VERIFICATION. AN ENTRANT IS NOT A WINNER OF ANY PRIZE
                      UNLESS AND UNTIL ENTRANT'S ELIGIBILITY AND THE POTENTIAL
                      WINNING ENTRY HAS BEEN VERIFIED AND ENTRANT HAS BEEN
                      NOTIFIED THAT VERIFICATION IS COMPLETE. ADMINISTRATOR WILL
                      NOT ACCEPT SCREENSHOTS, AFFIDAVITS OR OTHER EVIDENCE OF
                      WINNING IN LIEU OF ITS VALIDATION PROCESS. ANY ENTRY THAT
                      OCCURS AFTER THE SYSTEM HAS FAILED FOR ANY REASON MAY BE
                      DEEMED A DEFECTIVE ENTRY AND VOID.
                    </strong>{" "}
                    <br />
                    Potential winners will be notified via email (or overnight
                    mail) sent within thirty (30) business days following the
                    end of the Tournament Period. It is the sole responsibility
                    of the entrant to notify the Administrator if his or her
                    email address has changed. To do so, click on the{" "}
                    <a href="https://www.collegiaterugbychampionship.com/">
                      www.collegiaterugbychampionship.com
                    </a>
                    , then click on the Account Information link to update your
                    email address. A potential winner will be disqualified and
                    an alternate potential winner may be selected (i.e., another
                    randomly selected Entrant from the top 1%) if (i) he/she
                    does not comply with these rules (or is otherwise determined
                    to be ineligible), (ii) he/she does not respond to the prize
                    notice within five (5) days of its transmission (or receipt,
                    if mailed), or if the emailed prize notice, after three (3)
                    attempts, is returned as undeliverable, or (iii) the prize
                    is undeliverable for any reason. If the Administrator
                    notifies, or seeks to deliver the Prize to or otherwise
                    attempts to contact an alternate potential winner, and such
                    alternate potential winner is subsequently disqualified then
                    the Administrator shall use its reasonable business judgment
                    to determine, in its sole discretion, the disposition of the
                    prize. Winning the Prize is contingent upon fulfilling all
                    requirements set forth herein. Winner is solely responsible
                    for any and all expenses, relative to participation in the
                    Promotion (including acceptance of any prize) not specified
                    herein.
                  </p>
                  <p className="blackpad">
                    <strong className="p-green-bold">
                      10. CONDITIONS OF PARTICIPATION
                    </strong>
                    <br />
                    Participation constitutes entrant's full and unconditional
                    agreement to these Official Rules and to the Administrators
                    decisions. The decisions of the Administrator in regard to
                    all matters of this Promotion are final and binding. The
                    Administrator reserves the right in its sole discretion, to
                    modify, cancel, terminate, and/or suspend the Promotion and
                    to disqualify any individual who tampers with the entry
                    process, violates these Official Rules, or acts in a
                    disruptive or unsportsmanlike manner. Without limiting the
                    foregoing, Administrator may lock out an entrant who, in the
                    Administrators sole judgment, has been disqualified, has
                    questionable eligibility or is otherwise ineligible to
                    enter. CAUTION: A PERSON WHO ATTEMPTS DELIBERATELY TO
                    UNDERMINE THE LEGITIMATE OPERATION OF THIS PROMOTION OR TO
                    ALTER OR DAMAGE A WEBSITE MAY BE SUBJECT TO CIVIL AND/OR
                    CRIMINAL PENALTIES AND FINES; AND ADMINISTRATOR RESERVES THE
                    RIGHT TO SEEK DAMAGES FROM ANY SUCH PERSON TO THE FULLEST
                    EXTENT PERMITTED BY LAW. Without limiting the foregoing,
                    Administrator may modify, cancel, terminate, and/or suspend
                    the Promotion if, in the Administrator’s sole opinion, an
                    incident of any kind occurs to corrupt or impair the
                    administration, security, integrity, fairness or play (as
                    intended) of the Promotion, including without limitation:
                    (a) viruses, bugs, unauthorized human intervention, fraud or
                    technical failure; or (b) earthquake, flood, fire, storm or
                    other natural disaster, act of God, pandemic/epidemic; or
                    (c) labor controversy or threat thereof, civil disturbance
                    or commotion, disruption of the public markets, war or armed
                    conflict (whether or not officially declared), or (d)
                    cancellation, suspension or postponement of any type of game
                    or league. In the event that the Tournament is postponed,
                    terminated early, or cancelled for any reason (such as a
                    strike or lockout), Administrator reserves the right to
                    award the Prize based on scores at the time the Tournament
                    is disrupted, or to cancel, modify or suspend the Promotion
                    at its discretion. In the event of a cancellation or
                    termination by the Administrator the Prize will not be
                    awarded.{" "}
                  </p>
                  <p className="blackpad">
                    <strong className="p-green-bold">
                      11. RELEASE AND INDEMNITY
                    </strong>{" "}
                    <br />
                    By participating in this Promotion or receiving the Prize,
                    entrants (and in particular, winner) agree to release and to
                    indemnify and hold harmless Administrator, each of their
                    respective related companies, affiliates, subsidiaries,
                    representatives, and all of their respective officers,
                    directors, employees, consultants, contractors, legal
                    counsel, advertising, public relations, promotional,
                    marketing agencies, and agents (collectively, the "
                    <strong>Released Parties</strong>") for any liability,
                    injury, death, loss or damages to entrant or any person or
                    entity, including without limitation damage to personal or
                    real property, caused in whole or in part, directly or
                    indirectly, by participation in this Promotion (or related
                    activities) or the acceptance, possession or use/misuse of
                    the Prize.{" "}
                  </p>
                  <p className="blackpad">
                    By entering, entrants also authorize the Administrator and
                    its designees to use their name, voice, likeness,
                    photograph, prize information (if applicable), biographical
                    data and city and state of residence in programming,
                    advertising and/or promotional material or on a Winners'
                    List without compensation to the extent permitted by law.
                    Administrator is not obligated to use any of the
                    above-mentioned information or materials but may do so and
                    may edit such information or materials, at Administrator’s
                    sole discretion, without obligation or compensation. All
                    federal, state and local laws and regulations apply. All
                    entries become the property of the Administrator.
                  </p>
                  <p className="blackpad">
                    <strong className="p-green-bold">
                      12. GAMBLING PROHIBITION
                    </strong>{" "}
                    <br />
                    This Promotion is strictly for entertainment purposes and
                    may not be used in connection with any form of gambling.
                    Men's/Women’s College Rugby has adopted specific legislation
                    prohibiting athletic department staff members, conference
                    office staff, national office staff and student-athletes
                    from engaging in sports gambling activities as they relate
                    to intercollegiate or professional sporting events.
                  </p>
                  <p className="blackpad">
                    <strong className="p-green-bold">13. PARTICIPATION</strong>{" "}
                    <br />
                    By entering the Promotion, each entrant agrees: (i) to be
                    bound by these Official Rules, including entry requirements;
                    (ii) to waive any rights to claim ambiguity with respect to
                    these Official Rules; (iii) to waive all of his/her rights
                    to bring any claim, action or proceeding against any of the
                    Released Parties in connection with the Promotion; and (iii)
                    to forever and irrevocably agree to release and hold
                    harmless each of the Released Parties in the terms of the
                    section 13 of these Official Rules.
                  </p>
                  <p className="blackpad">
                    <strong className="p-green-bold">
                      14. LIMITATIONS OF LIABILITY
                    </strong>{" "}
                    <br />
                    The Released Parties do not make any representation,
                    warranty or guarantee, express or implied, relating to the
                    Promotion or the Prize, and are not responsible for: (1) any
                    incorrect or inaccurate information, whether caused by
                    entrants, printing errors or by any of the equipment,
                    hardware, software or programming associated or used with
                    the Promotion; (2) technical errors, defects, delays or
                    failures of any kind, including without limitation
                    malfunctions, interruptions or disconnections in
                    communications lines, Internet or website access, hardware
                    or software; (3) digital or electronic disruptions, e.g.,
                    viruses; (4) unauthorized human intervention, e.g., hacking;
                    (5) technical or human error which may occur in connection
                    with any aspect of the Promotion; (6) any injury or damage
                    to persons or property which may be caused, directly or
                    indirectly, in whole or in part, from entrant's
                    participation in the Promotion, access to, copying or
                    downloading materials from MayMadness7’s.com or any other
                    website, or receipt or use of any prize; or (7) for any
                    cancellation, suspension or postponement of any type of game
                    or league, or event of force majeure.
                  </p>
                  <p className="blackpad">
                    ADMINISTRATOR MAKES NO WARRANTY, REPRESENTATION, OR
                    GUARANTEE, EXPRESS OR IMPLIED, IN FACT OR IN LAW, RELATIVE
                    TO THE USE OF ANY PRIZE, INCLUDING, WITHOUT LIMITATION, ITS
                    QUALITY, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE
                  </p>
                  <p className="blackpad">
                    <strong className="p-green-bold">
                      15. DISPUTES/CHOICE OF LAW
                    </strong>{" "}
                    <br />
                    To the fullest extent permitted by law, entrant agrees that:
                    (1) any and all disputes, claims and causes of action
                    arising out of or connected with this Promotion or the
                    awarding of the Prize shall be resolved individually,
                    without resort to any form of class action, and exclusively
                    by the laws of the State of Delaware. Any dispute arising
                    under or in connection with the TOURNAMENT or related to any
                    matter which is the subject of the TOURNAMENT shall be
                    subject to the exclusive jurisdiction of the federal or
                    state courts of the State of Delaware without regard to its
                    laws regarding conflicts of law. The Parties consent to
                    personal jurisdiction and venue solely within these forums
                    and waive all possible objections thereto; (2) any and all
                    claims, judgments and awards shall be limited to actual
                    out-of-pocket costs incurred, including costs associated
                    with entering this Promotion, but in no event attorneys'
                    fees; and (3) under no circumstances will entrant be
                    permitted to obtain awards for, and entrant hereby waives
                    all rights to claim, indirect, punitive, incidental and
                    consequential damages and any other damages, other than for
                    actual out-of-pocket expenses, and any and all rights to
                    have damages multiplied or otherwise increased. All issues
                    and questions concerning the construction, validity,
                    interpretation and enforceability of these Official Rules,
                    or the rights and obligations of the entrant and
                    Administrator in connection with the Promotion shall be
                    governed by, and construed in accordance with, the laws of
                    the State of Delaware without giving effect to any choice of
                    law or conflict of law rules (whether of the State of
                    Delaware or any other jurisdiction), which would cause the
                    application of the laws of any jurisdiction other than the
                    State of Delaware.
                  </p>
                  <p className="blackpad">
                    <strong className="p-green-bold">16. PRIVACY POLICY</strong>{" "}
                    <br />
                    Personal information provided in conjunction with Promotion
                    entry is subject to Administrator’s privacy policy,
                    available at NCR.rugby
                  </p>
                  <p className="blackpad">
                    <strong className="p-green-bold">17. OFFICIAL RULES</strong>{" "}
                    <br />
                    WINNER LIST. For the Official Rules and name of the Prize
                    winner, with Men's Bracket Challenge 2023 Winner" in the
                    subject line, for receipt no later than 5/1/2024 at 5:00 pm
                    ET. Each winner shall be solely responsible for payment of
                    any and all applicable federal, state, and local taxes for
                    his/her Prize won. The Administrator will issue an IRS Form
                    1099-MISC for each winner. All other costs and expenses not
                    expressly set forth herein shall be solely each winner’s
                    responsibility. A Prize will only be awarded to a verified
                    winner.
                  </p>
                  <p className="blackpad">
                    This Promotion is in no way sponsored, endorsed or
                    administered by Facebook, Instagram or Twitter.
                  </p>
                </div>

                <div className="img-box1">
                  <div className="img-box" style={{ display: "none" }}>
                    <div className="img"></div>
                  </div>
                  {/* <Faqaccordian
                    heading1="How do I create an entry ?"
                    content1="A: From the game’s frontpage click on the “Create A Bracket” button. You will next be asked to enter your game settings such as entry name. After you are done editing these options, click the Create A Bracket button at the bottom of the page. You have now created an Entry. From your entry page, you now can make your selections in the Men’s Collegiate Rugby Championship tournament bracket and create or join a group."
                    heading2="What is the deadline for entries ?"
                    content2="A: From the game’s frontpage click on the “Create A Bracket” button. You will next be asked to enter your game settings such as entry name. After you are done editing these options, click the Create A Bracket button at the bottom of the page. You have now created an Entry. From your entry page, you now can make your selections in the Men’s Collegiate Rugby Championship tournament bracket and create or join a group."
                  /> */}
                  <Placebigadd />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Placeaddsm />
            </Col>
          </Row>
        </div>
      </Aux>
    );
  }
}
export default withRouter(TermsCondition);
